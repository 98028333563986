import { useMutation, useQueries } from '@tanstack/react-query'
import axios, { AxiosResponse } from 'axios'
import { IdType } from 'otto-vis-timeline'
import { useLocation, useParams } from 'react-router-dom'
import { ProjectValues } from '../../constants'
import { useDriveTrialContext, useTimelineContext } from '../../details'
import { getDriveTrials } from '../redirectionData'
import { urls } from '../urls'

export interface Tag {
  id?: number
  startTimestamp: number
  endTimestamp?: number
  description: string
  note?: string
  summary?: string
  issueType?: string
  team?: string
  jiraID?: string | undefined
}

export interface TagsRequest {
  dtid: number
  entities: ProjectValues[]
  enabled?: boolean
}

export interface TagsResponse {
  tags: Tag[]
}

export const TagTypes: Record<ProjectValues, number> = {
  Signs: 0,
  Lights: 1,
  AdjacentLeft: 2,
  AdjacentRight: 3,
  EGOLeft: 4,
  EGORight: 5,
  Lanes: 6,
} as const

export type TagType = keyof typeof TagTypes

export const getNameFromTagType = (id: IdType) =>
  Object.entries(TagTypes).find(([, value]) => value === id)?.[0] as
    | ProjectValues
    | undefined

interface CreateTagRequest {
  dtid: number
  tag: Tag
}

export interface EditTagRequest {
  tagId: number
  note: string
  summary: string
  issueType?: string
  team?: string
  jiraID?: string | undefined
}

const createUrl = (url: string, dtid: number) =>
  url.replace(':id', dtid.toString())

const checkTimes = (tag: Tag) => {
  if (
    tag.endTimestamp !== undefined &&
    tag.startTimestamp > tag.endTimestamp!
  ) {
    return {
      startTimestamp: tag.endTimestamp,
      endTimestamp: tag.startTimestamp,
      description: tag.description,
    }
  }
  return tag
}

export const useCreateTagMutation = () => {
  const {
    mutateAsync: createTagMutation,
    data,
    isPending,
  } = useMutation({
    mutationKey: ['createTag'],
    mutationFn: ({
      dtid,
      tag,
    }: CreateTagRequest): Promise<AxiosResponse<Tag>> => {
      const checkStartEndTime = checkTimes(tag)
      return axios.post(
        createUrl(urls.getTimelineTags, dtid),
        checkStartEndTime
      )
    },
  })

  return { createTagMutation, data, isPending }
}

export const useEditTagMutation = () => {
  const {
    mutateAsync: editTagMutation,
    data,
    isPending,
  } = useMutation({
    mutationKey: ['editTag'],
    mutationFn: ({
      tagId,
      note,
      summary,
      issueType,
      team,
      jiraID,
    }: EditTagRequest): Promise<AxiosResponse> =>
      axios.patch(createUrl(urls.deleteTimelineTags, tagId), {
        note,
        summary,
        issueType,
        team,
        jiraID,
      }),
  })

  return { editTagMutation, data, isPending }
}

export const useRemoveTagMutation = () => {
  const {
    mutateAsync: removeTagMutation,
    data,
    isPending,
  } = useMutation({
    mutationKey: ['removeTag'],
    mutationFn: (tagId: number): Promise<AxiosResponse> =>
      axios.delete(createUrl(urls.deleteTimelineTags, tagId)),
  })

  return { removeTagMutation, data, isPending }
}

export const useTagsQueries = () => {
  const { pathname } = useLocation()
  const { reportType } = useParams()
  const { groups } = useTimelineContext()
  const { redirectData } = useDriveTrialContext()
  const dtids = getDriveTrials(redirectData)
  const tagsPayload = pathname.includes('kpi')
    ? groups
        .map((x) => x)
        .filter((y) => !y.nestedGroups)
        .map((z) => `${reportType}|${z.value.replace('-', '|')}`)
    : groups.map((x) => x.value.replace('-', ''))

  return useQueries({
    queries: dtids.map((dtid) => ({
      queryKey: ['useGetTags', dtid, tagsPayload],
      queryFn: (): Promise<TagsResponse> =>
        axios
          .get(createUrl(urls.getTimelineTags, +dtid), {
            params: { columns: tagsPayload },
          })
          .then(({ data }) => data),
    })),
    combine: (queries) =>
      queries.map(({ data }, index) => ({
        ...data,
        dtid: +dtids[index],
      })),
  })
}
