import PackageJSON from '../../../package.json'
import generatedGitInfo from '../../generatedGitInfo.json'
import './style.scss'

export const About = () => {
  const buildNumber = process.env.REACT_APP_BITBUCKET_BUILD_NUMBER
  const version = buildNumber
    ? `${PackageJSON.version}.${buildNumber}`
    : PackageJSON.version

  return (
    <div className='version-info'>
      <div className='version-data'>
        <code>Version:</code>
        <code className='data'>{version}</code>
      </div>
      <div className='version-data'>
        <code>Build:</code>
        <code className='data'>{generatedGitInfo.commitHash}</code>
      </div>
    </div>
  )
}
