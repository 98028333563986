import { match } from 'ts-pattern'
import { ViewportContent } from './types'
import { ReportType } from '../pages/Report/types'

export enum CAMERA_POSITION {
  FRONT = 1,
  LEFT,
  RIGHT,
  REAR,
}

export const CLIENT_ID = {
  DEMO: 101,
  VALEO: 102,
  MAGNA: 100,
} as const

export enum PROJECT_IDS {
  MF1 = 1009,
  MB1 = 1010,
  VOLVO = 1011,
}

const viewportToCameraPosition = (content: ViewportContent) =>
  match(content)
    .with(ViewportContent.FRONT_CAMERA, () => CAMERA_POSITION.FRONT)
    .with(ViewportContent.LEFT_CAMERA, () => CAMERA_POSITION.LEFT)
    .with(ViewportContent.RIGHT_CAMERA, () => CAMERA_POSITION.RIGHT)
    .with(ViewportContent.REAR_CAMERA, () => CAMERA_POSITION.REAR)
    .otherwise(() => CAMERA_POSITION.FRONT)

export const getCameraURL = (camPosition: ViewportContent) => {
  const cameraPosition = viewportToCameraPosition(camPosition)

  const videoUrl = match(cameraPosition)
    .with(CAMERA_POSITION.FRONT, () => 'undestorted_raw_white.mp4')
    .with(CAMERA_POSITION.LEFT, () => 'basler-1-960.mp4')
    .with(CAMERA_POSITION.RIGHT, () => 'basler-0-960.mp4')
    .otherwise(() => `undestorted_raw_white.mp4`)

  return videoUrl
}

export const getCanvasLayers = (project: ReportType) =>
  match(project)
    .with('lanes', () => [
      'VEHICLELAYERS',
      'OTTOLAYERS',
      'Car',
      'Bus',
      'Truck',
      'Motorcycle',
      'Pedestrian',
      'Cycle',
      'EgoLane',
      'EgoClass&Color',
      'AdjacentLane',
      'AdjacentClass&Color',
      'TTD',
    ])
    .with('lights', () => [
      'VEHICLELAYERS',
      'OTTOLAYERS',
      'Car',
      'Bus',
      'Truck',
      'Motorcycle',
      'Pedestrian',
      'Cycle',
      'TrafficLight',
      'TTD',
    ])
    .with('signs', () => [
      'VEHICLELAYERS',
      'OTTOLAYERS',
      'Car',
      'Bus',
      'Truck',
      'Motorcycle',
      'Pedestrian',
      'Cycle',
      'Sign',
      'TTD',
    ])
    .otherwise(() => [
      'VEHICLELAYERS',
      'OTTOLAYERS',
      'Car',
      'Bus',
      'Truck',
      'Motorcycle',
      'Sign',
      'TrafficLight',
      'Pedestrian',
      'Cycle',
      'EgoLane',
      'EgoClass&Color',
      'AdjacentLane',
      'AdjacentClass&Color',
      'TTD',
    ])
