import { useContext, useEffect, useState } from 'react'
import { uniqWith, isEqual } from 'lodash'
import { parseDrawingsCSV } from './utils'
import { useObjectsQuery } from '../../api'
import useCanvasStreamQuery from '../../api/canvasStream'
import { useDriveTrialContext, useTimelineContext } from '../../details'
import { DriveTrial } from '../../details/contextProviders/types'
import {
  LineType,
  MediaSyncContext,
  ObjectType,
  SourceType,
} from '../../details/types'
import { ObjectInfo } from '../ObjectsTable/types'
import { useProjectData } from '../../api/table/projects'
import { useActiveTrial } from '../../store/hooks/useActiveTrial'

const SHORT_VIDEO_DURATION = 35
const FETCH_RANGE = 30
const FETCH_OFFSET = 5

export type TimedData<T> = Record<number, T>

interface FetchTimes {
  startTime: number
  endTime: number
}

interface CircleBufferProps {
  id: number
  dtid: number
  objectType: ObjectType
  sourceType: SourceType
  camera: number
  lineType?: LineType
  fetchCallback: (data: TimedData<unknown>) => void
  timeChangeCallback: (time: number) => void
  handleFetching: (isLoading: boolean) => void
  setIsPending: React.Dispatch<React.SetStateAction<boolean>>
  setIsPendingStream: React.Dispatch<React.SetStateAction<boolean>>
}

/*
  If its the start of the video, don't count the offset. 
  If its not the start but its the first video offset is 0
  IF FETCH_OFFSET = 5, fetch 5 seconds in the past and 25 in the future to allow going back
*/
const getOffsetTime = (time: number, offset: number) =>
  Math.floor(time ? time - offset - FETCH_OFFSET : time)

export const getTargetTime = (
  startTime: number,
  endTime: number,
  durationPercentage: number = 0.7
) => {
  const totalDuration = endTime - startTime
  const desiredDuration = totalDuration * durationPercentage
  return startTime + desiredDuration
}

const calculateStartEndTime = (
  offsetTime: number,
  frameRate: number,
  originalStartTime: number
): FetchTimes => ({
  startTime: Math.round((offsetTime + originalStartTime) * frameRate),
  endTime: Math.round(offsetTime + originalStartTime + FETCH_RANGE) * frameRate,
})

const getInitialTimeRange = (
  frameRate: number,
  activeVideoId?: number,
  video?: DriveTrial
) => {
  if (activeVideoId === undefined || !video)
    return { startTime: 0, endTime: 35 }

  const { previousDuration: offset, duration, originalStartTime } = video

  const skewInFrames = Math.round((originalStartTime - offset) * frameRate)
  const durationInFrames = Math.round(duration * frameRate)

  if (duration <= SHORT_VIDEO_DURATION) {
    return {
      startTime: skewInFrames,
      endTime: skewInFrames + durationInFrames,
    }
  }

  return {
    startTime: skewInFrames,
    endTime: skewInFrames + FETCH_RANGE * frameRate,
  }
}

const isInTimeRange = (
  timeRange: FetchTimes,
  frameRate: number,
  time: number,
  video?: DriveTrial
) => {
  if (!video) return
  const {
    previousDuration: offset,
    startTime: videoStartTime,
    originalStartTime,
  } = video
  const { startTime, endTime } = timeRange

  const clockTime =
    time && originalStartTime ? Math.round(time - videoStartTime) : time

  const currentTime = Math.floor(
    clockTime ? clockTime - offset + originalStartTime : clockTime
  )

  const frames = currentTime * frameRate
  return frames >= startTime && frames <= endTime
}

function CanvasBuffer({
  id,
  dtid,
  objectType,
  sourceType,
  camera,
  lineType,
  fetchCallback,
  timeChangeCallback,
  handleFetching,
  setIsPending,
  setIsPendingStream,
}: CircleBufferProps) {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { driveTrials, getDriveTrialById, getCurrentDriveTrial } =
    useDriveTrialContext()
  const { setObjectsData } = useTimelineContext()
  const { frameRate } = useProjectData()
  const initTime = getInitialTimeRange(
    frameRate,
    mediaSyncContext.activeVideoId,
    getDriveTrialById(id)
  )
  const [oldTimeRange, setOldTimeRange] = useState<FetchTimes>(initTime)
  const [timeRange, setTimeRange] = useState<FetchTimes>(initTime)

  const isVideoActive = () => mediaSyncContext.activeVideoId === id

  const shouldLoadBuffer = () => {
    if (isVideoActive() || driveTrials) return true

    const video = getDriveTrialById(id)
    return video ? video.duration < 15 : true
  }

  const [loadNextBuffer, setLoadNextBuffer] = useState(shouldLoadBuffer())

  const {
    data: ttd,
    isLoading: isLoadingStream,
    isPending: isPendingStream,
  } = useCanvasStreamQuery(
    objectType === 'ttd' && (isVideoActive() || loadNextBuffer),
    dtid,
    timeRange?.startTime,
    timeRange?.endTime,
    `${dtid}/visualisation-ttd.csv`
  )

  const { data, isLoading, isPending } = useObjectsQuery(
    objectType !== 'ttd' && (isVideoActive() || loadNextBuffer),
    dtid,
    objectType,
    sourceType,
    camera,
    timeRange?.startTime,
    timeRange?.endTime,
    lineType
  )

  useEffect(() => {
    if (objectType === 'ttd' && ttd && ttd.length > 47) {
      parseDrawingsCSV(ttd, fetchCallback)
    }

    if (!data || !Object.keys(data).length) return

    fetchCallback(data)

    if (
      objectType !== 'car' &&
      objectType !== 'pedestrian' &&
      objectType !== 'bicycle' &&
      objectType !== 'motorcycle' &&
      objectType !== 'bus' &&
      objectType !== 'truck'
    ) {
      return
    }

    setObjectsData((prevState) => {
      return Object.keys(data).reduce(
        (acc, key) => {
          const k = +key
          const updatedData = (
            data as {
              [x: number]: {
                data: ObjectInfo[]
              }
            }
          )[k].data.map((item: ObjectInfo) => ({
            ...item,
            sourceType: sourceType,
          }))

          if (prevState[k]) {
            const mergedData = [...prevState[k].data, ...updatedData]
            const uniqueData = uniqWith(mergedData, isEqual)

            acc[k] = {
              ...prevState[k],
              data: uniqueData as ObjectInfo[],
            }
          } else {
            acc[k] = {
              ...(
                data as {
                  [x: number]: {
                    data: ObjectInfo[]
                  }
                }
              )[k],
              data: updatedData,
            }
          }
          return acc
        },
        { ...prevState }
      )
    })
  }, [data, id, fetchCallback, ttd])

  useEffect(() => {
    if (objectType === 'ttd') return

    const t = setTimeout(() => {
      if (isPending) {
        setIsPending(true)
      }
    }, 5000)
    if (!isPending) {
      setIsPending(false)
    }
    return () => clearTimeout(t)
  }, [isPending])

  useEffect(() => {
    if (objectType !== 'ttd') return

    const t = setTimeout(() => {
      if (isPendingStream) {
        setIsPendingStream(true)
      }
    }, 5000)

    if (!isPendingStream) {
      setIsPendingStream(false)
    }
    return () => clearTimeout(t)
  }, [isPendingStream])

  useEffect(() => {
    if (objectType === 'ttd') return

    if (
      isVideoActive() &&
      (!oldTimeRange ||
        !isInTimeRange(
          oldTimeRange,
          frameRate,
          mediaSyncContext.timingObj?.pos,
          getDriveTrialById(id)
        ))
    ) {
      handleFetching(isLoading)
    }

    // Should only trigger when isLoading changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading])

  useEffect(() => {
    if (objectType !== 'ttd') return

    if (
      isVideoActive() &&
      (!oldTimeRange ||
        !isInTimeRange(
          oldTimeRange,
          frameRate,
          mediaSyncContext.timingObj?.pos,
          getDriveTrialById(id)
        ))
    ) {
      handleFetching(isLoadingStream)
    }

    // Should only trigger when isLoading changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingStream])

  const handleTimeChange = (time: number) => {
    const activeVideo = getCurrentDriveTrial(time)
    if (!driveTrials.length || !activeVideo || !isVideoActive()) return

    const {
      previousDuration: offset,
      startTime,
      duration,
      originalStartTime,
    } = activeVideo
    // We will initially load all data for any video with duration less than SHORT_VIDEO_DURATION
    if (duration <= SHORT_VIDEO_DURATION) return
    const currentTime = originalStartTime ? Math.round(time - startTime) : time

    const frames = Math.round(
      (currentTime - offset + originalStartTime) * frameRate
    )
    const target = getTargetTime(timeRange?.startTime, timeRange?.endTime)

    const isAlmostUsed = frames > target
    const isEarly = timeRange.startTime > frames

    if ((!oldTimeRange || isAlmostUsed || isEarly) && !isLoading) {
      const offsetTime = getOffsetTime(currentTime, offset)
      setOldTimeRange(timeRange)
      setTimeRange(
        calculateStartEndTime(offsetTime, frameRate, originalStartTime)
      )
    }
  }

  const handleSampler = (time: number) => {
    if (time !== undefined) {
      handleTimeChange(time)
      timeChangeCallback(time)
    }
    if (!isVideoActive() && driveTrials) {
      const video = getCurrentDriveTrial(time)
      if (!video) return
      const {
        previousDuration: offset,
        startDate,
        endDate,
        duration,
        originalStartTime,
      } = video

      const currentTime = Math.floor(
        startDate + time - offset + originalStartTime
      )
      const expectedPercentage = duration < 60 ? 0.5 : 0.8
      const targetTime = getTargetTime(startDate, endDate, expectedPercentage)
      if (duration < 15 || currentTime > targetTime) {
        setLoadNextBuffer(true)
      }
    }
  }

  useActiveTrial(handleSampler)

  return <></>
}

export default CanvasBuffer
