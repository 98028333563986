import { useContext, useEffect, useState } from 'react'
import {
  DriveTrial,
  SamplerReference,
  useDriveTrialContext,
} from '../../details'
import { MediaSyncContext } from '../../details/types'
import usePrevious from './usePrevious'

export function useActiveTrial(onTimeChange?: (time: number) => void) {
  const { getCurrentDriveTrial, driveTrials, highlightMode } =
    useDriveTrialContext()
  const { sampler, isPlaying, isSeeking } = useContext(MediaSyncContext)
  const prevHighlightModeId = usePrevious(highlightMode?.id)

  const [activeTrial, setActiveTrial] = useState<DriveTrial>(driveTrials?.[0])
  const [playing, setPlaying] = useState(isPlaying)
  const [seeking, setSeeking] = useState(isSeeking)

  const isDetailsPage = location.pathname.includes('details')

  useEffect(() => {
    if (!isDetailsPage) return

    let samplerEvent: SamplerReference | undefined = undefined

    samplerEvent = sampler?.on('change', (time) => {
      const newActiveTrial = getCurrentDriveTrial(time)

      setPlaying(isPlaying)
      setSeeking(isSeeking)

      if (onTimeChange) {
        onTimeChange(time)
      }

      if (
        !activeTrial ||
        activeTrial.id !== newActiveTrial?.id ||
        highlightMode.id !== prevHighlightModeId
      ) {
        if (newActiveTrial) {
          setActiveTrial(newActiveTrial)
        }
      }
    })

    return () => {
      samplerEvent?.terminate()
    }
  }, [
    activeTrial,
    getCurrentDriveTrial,
    isPlaying,
    sampler,
    onTimeChange,
    isSeeking,
    highlightMode?.id,
    prevHighlightModeId,
    isDetailsPage,
  ])

  return {
    activeTrial,
    playing,
    seeking,
  }
}
