import '../Gallery/styles.scss'
import {
  NLGalleryItem,
  useNLGalleryQuery,
} from '../../api/nlGallery/nl-gallery'
import { PROJECT_IDS } from '../../details/config'
import { Routes } from '../../constants'
import { Navigate, useSearchParams } from 'react-router-dom'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import { useProjectData } from '../../api/table/projects'
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  Fade,
  Modal,
  Typography,
} from '@mui/material'
import { Fragment, useCallback } from 'react'
import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder'
import { StyledPagination } from '../../pages/Report/components/StyledComponentsTable'
import ImageBox from '../Gallery/ImageBox'
import { SearchField } from './SearchField'

const SUGGESTED_SEARCHES = [
  'Driving under overpass',
  'Blue traffic signs',
  'Curve on the road',
  'Railway near the road',
]

export function NlGallery() {
  const [searchParams, setSearchParams] = useSearchParams()
  const { data, isLoading, page, count } = useNLGalleryQuery()
  const { projectID } = useProjectData()

  const nlQuery = searchParams.get('nlQuery') || ''
  const isImageBoxOpen = searchParams.get('selectedIndex') !== null

  const handleClose = useCallback(() => {
    setSearchParams((sp) => {
      sp.delete('selectedIndex')
      return sp
    })
  }, [setSearchParams])

  const handleImageClick = useCallback(
    (item: NLGalleryItem) => {
      const selectedIndex = data.findIndex(
        (i) => i.dtid === item.dtid && i.frameId === item.frameId
      )

      setSearchParams((sp) => {
        sp.set('selectedIndex', String(selectedIndex))
        return sp
      })
      return
    },
    [data, setSearchParams]
  )

  const handlePaginationClick = (
    _: React.ChangeEvent<unknown>,
    currentPage: number
  ) => {
    setSearchParams((sp) => {
      sp.set('page', String(currentPage))
      return sp
    })
  }

  const handleSuggestionClick = useCallback(
    (value: string) => {
      setSearchParams((sp) => {
        sp.set('nlQuery', value)
        return sp
      })
    },
    [setSearchParams]
  )

  if (projectID && projectID !== PROJECT_IDS.VOLVO) {
    return <Navigate to={Routes.INDEX} replace={true} />
  }

  if (isLoading) {
    return <Loader text='Natural Language Gallery' center />
  }

  return (
    <div className='gallery-container'>
      {!nlQuery ? (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              flexDirection: 'column',
            }}
          >
            <Typography
              sx={{ color: 'white', lineHeight: 1, mb: 4 }}
              variant='h3'
            >
              Search
            </Typography>

            <Box
              sx={{
                display: 'flex',
                gap: 2,
                mb: 5,
              }}
            >
              {SUGGESTED_SEARCHES.map((e) => (
                <Card
                  sx={{
                    color: 'white',
                    maxWidth: '150px',
                    boxShadow: 'none',
                    borderRadius: '10px',
                    border: '1px solid white',
                    opacity: 0.8,
                    cursor: 'pointer',
                    transition: 'all 0.1s ease-in',

                    ':hover': {
                      background: '#7683a1',
                    },
                  }}
                  key={e}
                >
                  <CardActionArea
                    onClick={() => handleSuggestionClick(e.toLowerCase())}
                  >
                    <CardContent
                      sx={{
                        paddingBottom: '16px !important',
                        fontSize: '1rem',
                      }}
                    >
                      {e}
                    </CardContent>
                  </CardActionArea>
                </Card>
              ))}
            </Box>

            <SearchField />
          </Box>
        </Box>
      ) : data?.length ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
              p: 1,
              pb: 0,
              minHeight: '44px',
            }}
          >
            <Box textAlign={'center'}>
              <SearchField isSmall />
            </Box>

            <StyledPagination
              sx={{ ml: 'auto', alignSelf: 'auto' }}
              onChange={handlePaginationClick}
              count={count}
              page={page}
              size='small'
              shape='rounded'
            />
          </Box>
          <div className='gallery-container--right'>
            {data?.map((item) => (
              <Fragment key={item.dtid + item.frameId}>
                <div
                  className='image-container'
                  onClick={() => handleImageClick(item)}
                >
                  <ImageWithPlaceholder
                    className='image'
                    src={item.url}
                    alt={item.dtid.toString()}
                    placeholder={'/gallery-placeholder.png'}
                  />
                  <div className='image-overlay'></div>
                </div>
              </Fragment>
            ))}
          </div>
        </Box>
      ) : (
        <Box
          sx={{
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Box textAlign={'center'}>
            <Typography sx={{ color: 'white', mb: 3 }} variant='h4'>
              No results found
            </Typography>

            <SearchField placeholder='Search for something else...' />
          </Box>
        </Box>
      )}
      <Modal
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)',
            },
          },
        }}
        open={isImageBoxOpen}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade style={{ outline: 'none' }} in={isImageBoxOpen}>
          <Box>
            <ImageBox data={data} onClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
