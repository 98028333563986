import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from './urls'
import { GeoJSONObject } from '../components/TopViewMap/types'
import { useActiveTrial } from '../store/hooks/useActiveTrial'

export const useTopViewMapQuery = () => {
  const { activeTrial } = useActiveTrial()

  return useQuery({
    queryKey: ['topViewMapJSON', activeTrial.DTID],
    staleTime: Infinity,
    queryFn: (): Promise<GeoJSONObject> =>
      axios
        .get(
          `${urls.files.replace('url', btoa(`${activeTrial.DTID}/lanes_gps.json`))}`
        )
        .then((resp) =>
          fetch(resp.data.url)
            .then((response) => response.json())
            .then((data) => data)
        ),
  })
}
