import {
  Box,
  Breadcrumbs,
  Button,
  Fade,
  Modal,
  Typography,
} from '@mui/material'
import React, { Fragment, useCallback, useMemo } from 'react'
import '../Gallery/styles.scss'

import { StyledPagination } from '../../pages/Report/components/StyledComponentsTable'
import { SprLeftSide } from './SprLeftSide'
import { SprItem, useSprGalleryQuery } from '../../api/sprGallery/spr-gallery'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import { Navigate, useSearchParams } from 'react-router-dom'
import { Filter } from '@mui/icons-material'
import { ImageWithPlaceholder } from '../ImageWithPlaceholder/ImageWithPlaceholder'
import { Routes } from '../../constants'
import { useProjectData } from '../../api/table/projects'
import { PROJECT_IDS } from '../../details/config'
import { ViewType } from './types'
import ImageBox from '../Gallery/ImageBox'

export function SprGallery() {
  const { data, isLoading, page, count, frameId, dtid, delegateType, day } =
    useSprGalleryQuery()
  const { projectID } = useProjectData()
  const [searchParams, setSearchParams] = useSearchParams()
  const isDelegateView = !frameId || !dtid
  const isImageBoxOpen = searchParams.get('selectedIndex') !== null

  const handleClose = useCallback(() => {
    setSearchParams((sp) => {
      sp.delete('selectedIndex')
      return sp
    })
  }, [setSearchParams])

  const goBackToDelegates = useCallback(() => {
    if (isDelegateView) {
      return
    }

    setSearchParams((sp) => {
      sp.delete('frameId')
      sp.delete('dtid')

      const prevDelegatePage = sp.get('prevDelegatePage')

      if (prevDelegatePage) {
        sp.set('page', prevDelegatePage)
      }

      sp.delete('prevDelegatePage')
      return sp
    })
  }, [isDelegateView, setSearchParams])

  const handleImageClick = useCallback(
    (item: SprItem) => {
      const selectedIndex = data.findIndex(
        (i) => i.dtid === item.dtid && i.frameId === item.frameId
      )

      if (!item.isDelegate) {
        setSearchParams((sp) => {
          sp.set('selectedIndex', String(selectedIndex))
          return sp
        })
        return
      } else {
        setSearchParams((sp) => {
          sp.set('dtid', String(item.dtid))
          sp.set('frameId', String(item.frameId))

          const page = sp.get('page')

          if (page && isDelegateView) {
            sp.set('prevDelegatePage', page)
          }

          sp.set('page', String(1))
          return sp
        })
      }
    },
    [data, isDelegateView, setSearchParams]
  )

  const handlePaginationClick = (
    _: React.ChangeEvent<unknown>,
    currentPage: number
  ) => {
    setSearchParams((sp) => {
      sp.set('page', String(currentPage))
      return sp
    })
  }

  const breadCrumbItems = useMemo(() => {
    const items = [
      <Button
        sx={{
          textTransform: 'none',
          cursor: isDelegateView ? 'initial' : 'pointer',
        }}
        onClick={goBackToDelegates}
        variant='text'
        key={ViewType.DELEGATES}
      >
        <Typography color={'white'}>{ViewType.DELEGATES}</Typography>
      </Button>,
    ]

    if (!isDelegateView) {
      items.push(
        <Button
          sx={{ textTransform: 'none', cursor: 'initial' }}
          variant='text'
          key={ViewType.CONSTITUENTS}
        >
          <Typography color={'white'}>{ViewType.CONSTITUENTS}</Typography>
        </Button>
      )
    }

    return items
  }, [goBackToDelegates, isDelegateView])

  if (projectID && projectID !== PROJECT_IDS.VOLVO) {
    return <Navigate to={Routes.INDEX} replace={true} />
  }

  if (isLoading) {
    return <Loader text='SPR' center />
  }

  return (
    <div className='gallery-container'>
      <SprLeftSide
        day={day}
        frameId={frameId}
        delegateType={delegateType}
        dtid={dtid}
      />
      {data?.length ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
              position: 'relative',
              p: 1,
              pb: 0,
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', lineHeight: 1 }}>
              <Breadcrumbs
                className='gallery-breadcrumbs'
                sx={{ color: 'white' }}
                separator='›'
              >
                {breadCrumbItems}
              </Breadcrumbs>
            </Box>

            <Typography
              sx={{
                transform: 'translate(-50%, -50%)',
                position: 'absolute',
                fontSize: '14px',
                color: 'white',
                left: '50%',
                top: '60%',
              }}
            >
              SPR - 5%
            </Typography>

            <StyledPagination
              sx={{ ml: 'auto', alignSelf: 'auto' }}
              onChange={handlePaginationClick}
              count={count}
              page={page}
              size='small'
              shape='rounded'
            />
          </Box>

          <div className='gallery-container--right'>
            {data?.map((spr) => (
              <Fragment key={spr.dtid + spr.frameId}>
                <div
                  className='image-container'
                  onClick={() => handleImageClick(spr)}
                >
                  <ImageWithPlaceholder
                    className='image'
                    src={spr.url}
                    alt={spr.dtid.toString()}
                    placeholder={'/gallery-placeholder.png'}
                  />
                  <div className='image-overlay'>
                    {spr.isDelegate && spr.totalConstituents !== undefined ? (
                      <div
                        title={`${spr.totalConstituents} constutuents for this delegate`}
                        className='image-overlay-content'
                      >
                        <Filter sx={{ fontSize: '14px' }} />
                        <Typography fontSize={'14px'} variant='body1'>
                          {spr.totalConstituents}
                        </Typography>
                      </div>
                    ) : null}
                  </div>
                </div>
              </Fragment>
            ))}
          </div>
        </Box>
      ) : (
        <Box p={3}>
          <Typography sx={{ opacity: 0.8 }} variant='body1' color={'white'}>
            No results found
          </Typography>
        </Box>
      )}
      <Modal
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
            },
          },
        }}
        open={isImageBoxOpen}
        onClose={handleClose}
        closeAfterTransition
      >
        <Fade style={{ outline: 'none' }} in={isImageBoxOpen}>
          <Box>
            <ImageBox data={data} onClose={handleClose} />
          </Box>
        </Fade>
      </Modal>
    </div>
  )
}
