import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useIdleTimerContext } from 'react-idle-timer'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { LoadingContext } from '../../details'
import { MediaSyncContext } from '../../details/types'
import MCorp from '../../timing/mcorp'
import NoData from '../NoData/NoData'
import './videoPlayerReact.scss'
import { toggleClasses } from '../../utils'
import { useActiveTrial } from '../../store/hooks/useActiveTrial'
import { Box } from '@mui/material'

interface IProps {
  playerId: string
  isFullscreen: boolean
  viewportId: number
  synchronizer?: ISynchronizer | undefined
  setCanvasSize: React.Dispatch<
    React.SetStateAction<{
      width: number
      height: number
    }>
  >

  videoId: number
  url?: string
}

interface MCorpApi {
  setSkew: () => void
  getSkew: () => void
  setOption: () => void
  getMethod: () => void
  setMotion: () => void
  stop: () => void
  pause: (elem: HTMLVideoElement) => void
  on: () => void
  off: () => void
  init: () => void
}

const fullScreenOff = 'video-init'
const fullScreenOn = 'video-fsc-horizontal-stretch'

const VideoPlayerReact = ({
  playerId,
  isFullscreen,
  synchronizer,
  setCanvasSize,
  viewportId,
  videoId,
  url,
}: IProps) => {
  const { isVideoLoaded, setIsVideoLoaded, setIsBuffering } =
    useContext(LoadingContext)
  const [hasFailed, setHasFailed] = useState(false)
  const mediaSync = useContext(MediaSyncContext)
  const msyncRef = useRef<MCorpApi | null>(null)
  const selfRef = useRef<HTMLVideoElement>(null)
  const { reset } = useIdleTimerContext()
  const { activeTrial } = useActiveTrial()

  const handleWaiting = useCallback(() => {
    synchronizer?.updateStatus(viewportId, false)
    setIsBuffering(true)
  }, [setIsBuffering, synchronizer, viewportId])

  const handleStalled = useCallback(() => {
    synchronizer?.updateStatus(viewportId, false)
    setIsBuffering(true)
  }, [setIsBuffering, synchronizer, viewportId])

  const handlePlaying = useCallback(() => {
    mediaSync.isSeeking = false
  }, [mediaSync])

  const handleCanvasSize = useCallback(() => {
    const player = selfRef.current

    if (!player) {
      return
    }

    setCanvasSize({
      width: player.clientWidth,
      height: player.clientHeight,
    })
  }, [setCanvasSize])

  const onResize = useCallback(() => {
    handleCanvasSize()
  }, [handleCanvasSize])

  const handleLoadedMetaData = useCallback(() => {
    const newTime = mediaSync.timingObj?.pos - activeTrial.previousDuration
    const player = selfRef.current

    if (newTime && player) {
      player.currentTime = newTime
    }
  }, [mediaSync.timingObj?.pos, activeTrial.previousDuration])

  const handleLoadStart = useCallback(() => {
    setIsVideoLoaded(false)
  }, [setIsVideoLoaded])

  useEffect(() => {
    const player = selfRef.current

    if (!player) {
      return
    }

    const subscribeToMCorp = () => {
      if (!player) return

      if (msyncRef.current === null) {
        msyncRef.current = MCorp.mediaSync(player, mediaSync.delay)
      }
    }

    const handleCanPlayThrough = () => {
      if (player.readyState === 4) {
        synchronizer?.updateStatus(viewportId, true)
        setIsBuffering(false)
        mediaSync.isSeeking = false
        if (mediaSync?.timingObj?.vel !== 0) return
        const time: number = mediaSync.timingObj?.pos
        synchronizer?.triggerDraw(time, viewportId, videoId)
      } else {
        synchronizer?.updateStatus(viewportId, false)
        setIsBuffering(true)
      }

      subscribeToMCorp()
    }

    if (isVideoLoaded && player) {
      player.addEventListener('canplaythrough', handleCanPlayThrough)
      player.addEventListener('waiting', handleWaiting)
      player.addEventListener('stalled', handleStalled)
      player.addEventListener('playing', handlePlaying)
      player.addEventListener('loadedmetadata', handleLoadedMetaData)
      player.addEventListener('loadstart', handleLoadStart)

      subscribeToMCorp()
    }

    return () => {
      player?.removeEventListener('canplaythrough', handleCanPlayThrough)
      player?.removeEventListener('waiting', handleWaiting)
      player?.removeEventListener('stalled', handleStalled)
      player?.removeEventListener('playing', handlePlaying)
      player?.removeEventListener('loadedmetadata', handleLoadedMetaData)
      player?.removeEventListener('loadstart', handleLoadStart)

      msyncRef?.current?.pause(player)
      msyncRef.current = null
    }
  }, [
    isVideoLoaded,
    videoId,
    url,
    mediaSync.delay,
    synchronizer,
    viewportId,
    setIsBuffering,
    handleWaiting,
    handleStalled,
    handlePlaying,
    onResize,
    handleLoadedMetaData,
    handleLoadStart,
  ])

  const handleFullScreenToggle = useCallback(() => {
    if (!selfRef.current) {
      return
    }

    const internalPlayer = selfRef.current

    if (!internalPlayer) {
      return
    }

    if (isFullscreen) {
      internalPlayer.style.all = 'revert'
      toggleClasses(internalPlayer, [fullScreenOff], [fullScreenOn])
    } else {
      internalPlayer.style.all = 'revert'
      toggleClasses(internalPlayer, [fullScreenOn], [fullScreenOff])
    }

    handleCanvasSize()
  }, [handleCanvasSize, isFullscreen])

  useEffect(() => {
    handleFullScreenToggle()
  }, [handleFullScreenToggle])

  useEffect(() => {
    const player = selfRef.current

    const resizeObserver = new ResizeObserver(() => {
      onResize()
    })

    if (player) {
      resizeObserver.observe(player)
    }

    return () => {
      resizeObserver.disconnect()
    }
  }, [onResize])

  if (hasFailed) return <NoData languageCode='enUS' />

  return (
    <Box pr={'40px'} className={'player-container'}>
      <video
        ref={selfRef}
        src={url}
        onProgress={reset}
        onError={(err) => {
          console.error('Error loading video ' + playerId + ': ', err)
          setHasFailed(true)
          synchronizer?.updateStatus(viewportId, true)
        }}
        onLoadedData={() => {
          if (isVideoLoaded) {
            return
          }

          setIsVideoLoaded(true)
          handleFullScreenToggle()
          handleCanvasSize()
        }}
      ></video>
    </Box>
  )
}

export default VideoPlayerReact
