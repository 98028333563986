import { useCallback, useContext, useRef } from 'react'
import { JUMP_FRAME_TIME_OFFSET } from './utils'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { useDriveTrialContext } from '../../details'
import {
  MediaSyncContext,
  PlayControls as PlayControlsRef,
} from '../../details/types'
import { getClientID } from '../../storage/clientIdStorage'
import { TimingProgress } from '../../timing/timing'
import { secondsToTimeString } from '../../tslib/secondsToTimeString'
import { ControlsSlider } from './ControlsSlider/ControlsSlider'
import { DisplayElapsedTime } from '../../ui_toolkit/VideoControls/DisplayElapsedTime/DisplayElapsedTime'
import { JogWheel } from '../../ui_toolkit/VideoControls/JogWheel/JogWheel'
import { PlayControls } from '../../ui_toolkit/VideoControls/PlayControls/PlayControls'
import { TimelineMarkControls } from '../../ui_toolkit/VideoControls/TimelineMarkControls/TimelineMarkControls'
import { toSeconds } from '../../utils'
import { Shortcuts } from '../Shortcuts/Shortcuts'
import './transportControls.scss'
import { useProjectData } from '../../api/table/projects'
import { useActiveTrial } from '../../store/hooks/useActiveTrial'

export interface HandleMouseDownChangeProps {
  setIsMouseDown: (value: React.SetStateAction<boolean>) => void
  isMouseDown: boolean
}

export const TransportControls = ({
  synchronizer,
}: {
  synchronizer?: ISynchronizer | undefined
}) => {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { getCurrentDriveTrial, driveTrials, highlightMode } =
    useDriveTrialContext()

  const activeTrackRef = useRef<HTMLDivElement | null>(null)
  const durationRef = useRef<HTMLSpanElement>(null)
  const playRef = useRef<PlayControlsRef>(null)
  const frameRef = useRef<HTMLInputElement>(null)
  const sliderRef = useRef<HTMLInputElement | null>(null)
  const playbackRate = useRef<number>(1)
  const { frameRate } = useProjectData()
  const clientID = getClientID()
  const handleMouseDownRef = useRef<HandleMouseDownChangeProps>()

  const prepareTimingData = (sliderRef: HTMLInputElement) => {
    if (!sliderRef) {
      return
    }

    const p = new TimingProgress(mediaSyncContext.timingObj, sliderRef, {
      sampler: mediaSyncContext.sampler,
      range: [0, mediaSyncContext.totalDuration ?? 0],
    })

    mediaSyncContext.progress = p
  }

  const setDurationVal = (currentTime: number) => {
    if (
      durationRef.current &&
      mediaSyncContext !== undefined &&
      mediaSyncContext.activeVideo
    ) {
      durationRef.current.innerText = secondsToTimeString(currentTime)
    }
  }

  const handleTimeChange = (time: number) => {
    if (mediaSyncContext.totalDuration) {
      if (sliderRef.current && !handleMouseDownRef.current?.isMouseDown) {
        sliderRef.current.value = TimingProgress.position2percent(time, [
          0,
          mediaSyncContext.totalDuration,
        ]).toString()
      }

      if (activeTrackRef.current?.style) {
        activeTrackRef.current.style.width = `${
          (time / mediaSyncContext.totalDuration) * 100
        }%`
      }

      if (frameRef.current) {
        const currentVideo = getCurrentDriveTrial(time)

        if (
          !mediaSyncContext ||
          !mediaSyncContext.activeVideo ||
          !currentVideo
        ) {
          return
        }
        let frame = 0
        const frameOffset = Math.floor(
          currentVideo.previousDuration * frameRate
        )
        if (highlightMode.id === -1) {
          frame = Math.floor(
            (time + currentVideo.originalStartTime) * frameRate
          )
        } else {
          highlightMode.items
            .get({ returnType: 'Array' })
            .filter(
              (i) =>
                i.className !== 'exclusion-background' &&
                !i.id.toString().includes('tag') &&
                i.group === highlightMode.id &&
                i.originalStart !== undefined
            )
            .forEach((item) => {
              const start = toSeconds(new Date(item.start).getTime())
              const end = toSeconds(new Date(item.end!).getTime())
              const originalStart = toSeconds(
                new Date(item.originalStart!).getTime()
              )
              if (start <= time && end >= time) {
                frame = Math.floor((time - start + originalStart) * frameRate)
              }
            })
        }
        frameRef.current.value = Math.floor(frame - frameOffset).toString()
      }
      if (time === mediaSyncContext.totalDuration && playRef.current) {
        playRef.current?.setPlaying(false)
        mediaSyncContext.isPlaying = false
      }

      if (!mediaSyncContext.isPlaying) {
        playRef.current?.setPlaying(false)
      } else {
        playRef.current?.setPlaying(true)
      }

      setDurationVal(time)
    }
  }

  useActiveTrial(handleTimeChange)

  const onPlayClick = useCallback(
    (isPlaying: boolean) => {
      if (!mediaSyncContext?.timingObj || !mediaSyncContext?.totalDuration) {
        return
      }

      const hasEnded =
        mediaSyncContext.timingObj.pos >= mediaSyncContext.totalDuration
      if (isPlaying && hasEnded) {
        mediaSyncContext.timingObj.update({ position: 0 })
      }

      const anyStillLoading = synchronizer?.anyLoadingViewports() ?? false
      mediaSyncContext.timingObj.update({
        velocity:
          isPlaying && !anyStillLoading ? mediaSyncContext.playbackSpeed : 0,
      })
      frameRef.current?.blur()
      mediaSyncContext.isPlaying = isPlaying
    },
    [mediaSyncContext, synchronizer]
  )

  const onChangePlaybackRate = (velocity: number) => {
    mediaSyncContext.timingObj?.update({
      velocity,
    })
    playbackRate.current = velocity
    mediaSyncContext.playbackSpeed = velocity
    mediaSyncContext.isPlaying = true
  }

  const onJogMovement = (velocity: number) => {
    if (velocity !== 0) {
      mediaSyncContext.timingObj?.update({
        velocity,
      })
      mediaSyncContext.playbackSpeed = velocity
      mediaSyncContext.isPlaying = true
      playRef.current?.setPlaying(true)
      return
    }

    mediaSyncContext.timingObj?.update({
      velocity: 0,
    })
    mediaSyncContext.playbackSpeed = playbackRate.current
    mediaSyncContext.isPlaying = false
    playRef.current?.setPlaying(false)
  }

  const handleFocus = () => {
    playRef.current?.setFrameInputFocused(true)
    mediaSyncContext.timingObj?.update({
      velocity: 0,
    })
    mediaSyncContext.isPlaying = false
    playRef.current?.setPlaying(false)
  }

  const updateTime = useCallback(
    (
      e:
        | React.FocusEvent<HTMLInputElement, Element>
        | React.KeyboardEvent<HTMLInputElement>
    ) => {
      mediaSyncContext.isSeeking = true
      const time = +e.currentTarget.value / frameRate
      mediaSyncContext.timingObj?.update({
        position: time + JUMP_FRAME_TIME_OFFSET,
      })
    },
    [frameRate, mediaSyncContext.timingObj]
  )

  return (
    <div className='controls-container'>
      <PlayControls
        onPlayPause={onPlayClick}
        onChangePlaybackRate={onChangePlaybackRate}
        ref={playRef}
        frameRef={frameRef}
      />
      <TimelineMarkControls />
      {clientID && (
        <div className='frame-wrapper'>
          <span>Frame: </span>
          <input
            className='frame-input'
            disabled={highlightMode.id !== -1 || driveTrials.length > 1}
            type='number'
            ref={frameRef}
            onFocus={handleFocus}
            onKeyUp={(e) => {
              if (e.key === 'Enter') {
                updateTime(e)
              }
            }}
            onBlur={(e) => {
              updateTime(e)
              playRef.current?.setFrameInputFocused(false)
            }}
          />
        </div>
      )}
      <DisplayElapsedTime
        duration={mediaSyncContext.totalDuration}
        ref={durationRef}
      />
      <JogWheel min={-8} max={8} onJog={onJogMovement} />
      {mediaSyncContext.totalDuration && (
        <ControlsSlider
          min={0}
          max={100}
          activeTrackRef={activeTrackRef}
          duration={mediaSyncContext.totalDuration}
          sliderRef={sliderRef}
          handleMouseDownRef={handleMouseDownRef}
          playRef={playRef}
          onRender={prepareTimingData}
        />
      )}

      <Shortcuts />
    </div>
  )
}
