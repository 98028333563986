import React, { useEffect, useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import { RoadType, DashboardTableSource } from './DummyData'
import { Select } from '../../ui_toolkit/Inputs/Select/Select'
import DashboardTableComponent from './DashboardTableComponent'

const DashboardTable: React.FC = () => {
  const [dashboardTableSource, setDashboardTableSource] = useState<string>(
    DashboardTableSource.RAW_HOURS
  )

  const [, setChartDimensions] = useState({
    width: (window.innerWidth / 3) * 0.9,
    height: (window.innerHeight / 2) * 0.8,
  })

  const updateDimensions = () => {
    setChartDimensions({
      width: (window.innerWidth / 3) * 0.9,
      height: (window.innerHeight / 2) * 0.8,
    })
  }

  useEffect(() => {
    window.addEventListener('resize', updateDimensions)
    return () => window.removeEventListener('resize', updateDimensions)
  }, [])

  const selectTableSourceOptions = Object.entries(DashboardTableSource).map(
    ([_, value]) => ({
      text: value,
      value: value,
    })
  )

  const handleTableSourceSelectChange = (event: SelectChangeEvent) => {
    setDashboardTableSource(event.target.value)
  }

  return (
    <div className='dashboard-charts'>
      <div className='dashboard-charts-select'>
        <Select
          title='Table Source'
          options={selectTableSourceOptions}
          variant='outlined'
          size='small'
          value={dashboardTableSource}
          minWidth={142}
          onChange={handleTableSourceSelectChange}
        />
      </div>
      <div className='dashboard-table-component'>
        <DashboardTableComponent
          roadType={RoadType.CITY}
          tableSource={dashboardTableSource}
        />
        <DashboardTableComponent
          roadType={RoadType.HIGHWAY}
          tableSource={dashboardTableSource}
        />
        <DashboardTableComponent
          roadType={RoadType.RURAL}
          tableSource={dashboardTableSource}
        />
      </div>
    </div>
  )
}

export default DashboardTable
