import { useState } from 'react'
import { Alert } from '@mui/material'
import { Column } from '@tanstack/react-table'
import { OnCheckNode } from 'react-checkbox-tree'
import { useHeadersQuery } from '../../api/headers'
import { enUS } from '../../constants'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { ReportType } from '../../pages/Report/types'
import {
  CheckboxNodeData,
  CheckboxTreeBase,
} from '../../ui_toolkit/CheckboxTreeBase/CheckboxTreeBase'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import 'react-checkbox-tree/src/scss/react-checkbox-tree.scss'

interface HideShowPickerProps {
  reportType: ReportType
}

function HideShowPicker({ reportType }: HideShowPickerProps) {
  const { allColumns, preserveState, setPreserveState } = useReportContext()
  const { isLoading, isFetching } = useHeadersQuery()
  const [expandedNodes, setExpanded] = useState<string[]>([])

  const transformCheckboxNodes = (columns: Column<any, any>[]) => {
    return columns
      .filter((column) => column.id !== 'select')
      .map((column) => {
        const children: any =
          column.columns.length > 0
            ? transformCheckboxNodes(column.columns)
            : null

        return {
          value: column.id,
          label: column.columnDef.header,
          checked: column.getIsVisible(),
          children,
        }
      })
  }

  const checkboxNodes = transformCheckboxNodes(allColumns) as CheckboxNodeData[]

  const onCheck = (node: OnCheckNode) => {
    if (!node.label) {
      return
    }

    if (!node.children) {
      setPreserveState({
        ...preserveState,
        [reportType]: {
          ...preserveState[reportType],
          columnVisibility: {
            ...preserveState[reportType].columnVisibility,
            [node.value]: node.checked,
          },
        },
      })

      return
    }

    const childrenNodes: any = {}
    node.children.forEach((x) => (childrenNodes[x.value] = node.checked))

    setPreserveState({
      ...preserveState,
      [reportType]: {
        ...preserveState[reportType],
        columnVisibility: {
          ...preserveState[reportType].columnVisibility,
          ...childrenNodes,
        },
      },
    })
  }

  const onExpand = (expanded: string[]) => {
    setExpanded(expanded)
  }

  if (isLoading || isFetching) {
    return <Loader text='Loading columns' />
  }

  if (
    reportType.includes('Kpi') &&
    Object.keys(preserveState[reportType].filterKpiToSql!).length === 0
  ) {
    return (
      <Alert variant='standard' severity='warning' style={{ width: '100%' }}>
        {enUS.NO_COLUMNS_SHOWN}
      </Alert>
    )
  }

  return (
    <CheckboxTreeBase
      expandedNodes={expandedNodes}
      onExpand={onExpand}
      onCheck={onCheck}
      checkboxes={checkboxNodes}
      reportType={reportType}
    />
  )
}

export default HideShowPicker
