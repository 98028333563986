import {
  Ref,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import omit from 'lodash/omit'
import { TimelineGroup } from 'otto-vis-timeline'
import { Timeline as VisTimelineCtor } from 'otto-vis-timeline/standalone'
import { Timeline as VisTimeline } from 'otto-vis-timeline/types'
import { DataSet } from 'vis-data/esnext'
import { SelectionOptions, TimelineProps, events } from './model'
import { mouseDownHandler, mouseUpHandler } from './utils/interaction'
import {
  TimelineEventData,
  TimelineItemData,
} from '../../components/VideoTimeline/types'
import { useDriveTrialContext, useTimelineContext } from '../../details'

export interface ITimeline extends VisTimeline {
  items: DataSet<TimelineItemData>
  groups: DataSet<TimelineGroup>
}

/**
 * TimelineControlled exposes its underlying Timeline-Vis object, which allows visual data manipulation.
 * Each component that renders this one needs to have its own logic for timeline interaction.
 * For Timeline-Vis documentation visit this link: https://github.com/visjs/vis-timeline
 */
export const TimelineControlled = forwardRef(function TimelineControlled(
  props: TimelineProps,
  ref: Ref<ITimeline | null>
) {
  const timelineContext = useTimelineContext()
  const { highlightMode } = useDriveTrialContext()
  const [timeline, setTimeline] = useState<VisTimeline | null>(null)
  const clickPosition = useRef<number | undefined>(undefined)
  const timelineRef = useRef<HTMLElement | null>(null)

  const init = (tl: VisTimeline) => {
    const { options, selection, selectionOptions = {}, animate = true } = props

    let timelineOptions = options

    if (timeline) {
      if (animate && options && options.start && options.end) {
        // If animate option is set, we should animate the timeline to any new
        // start/end values instead of jumping straight to them
        timelineOptions = omit(options, 'start', 'end')

        tl.setWindow(options.start, options.end, {
          animation: animate,
        })
      }

      if (timelineOptions) {
        tl.setOptions(timelineOptions)
      }
    }

    if (selection) {
      tl.setSelection(selection, selectionOptions as Required<SelectionOptions>)
    }
  }

  const onDivRender = (node: HTMLElement | null) => {
    timelineRef.current = node

    if (!node || timeline !== null) {
      return
    }

    const tl = new VisTimelineCtor(
      node,
      props.initialItems ?? [],
      props.initialGroups ?? [],
      props.options
    )

    events.forEach((e) => {
      const handler = props[`${e}Handler`]
      if (handler && tl) {
        tl.on(e, handler)
      }
    })

    if (props.controlledTime) {
      tl.addCustomTime(props.controlledTime.datetime, props.controlledTime.id)
    }

    init(tl)

    if (props.onTimelineInit) {
      props.onTimelineInit(tl)
    }

    setTimeline(tl)
  }

  useEffect(() => {
    return () => {
      if (timeline) {
        timeline.destroy()
        setTimeline(null)
      }
    }
    // Run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (highlightMode.id !== -1) {
      timelineRef.current?.classList.add('highlight-mode')
    } else {
      timelineRef.current?.classList.remove('highlight-mode')
    }
  }, [highlightMode.id])

  useEffect(() => {
    const mouseDown = (data: TimelineEventData) =>
      mouseDownHandler(data, timeline, clickPosition)
    const mouseUp = (data: TimelineEventData) =>
      mouseUpHandler(data, clickPosition, props.onTimeClick, timelineContext)

    if (timeline) {
      timeline.on('mouseDown', mouseDown)
      timeline.on('mouseUp', mouseUp)
    }

    return () => {
      timeline?.off('mouseDown', mouseDown)
      timeline?.off('mouseUp', mouseUp)
    }
  }, [props.initialGroups, props.onTimeClick, props.onGroupClick, timeline])

  useImperativeHandle(
    ref,
    () => {
      if (timeline) {
        const c: ITimeline = Object.assign(timeline, {
          items: props.initialItems ?? new DataSet<TimelineItemData>(),
          groups: props.initialGroups ?? new DataSet<TimelineGroup>(),
        })

        return c
      }

      return null
    },
    // On timeline change update items and groups
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [timeline]
  )

  return <div style={{ width: '100%', height: '100%' }} ref={onDivRender} />
})
