import React, { useEffect, useRef } from 'react'
import { Alert, Checkbox, Stack } from '@mui/material'
import { getCoreRowModel, useReactTable } from '@tanstack/react-table'
import { Pagination } from './Pagination'
import { StyledAlert, StyledTableContainer } from './StyledComponentsTable'
import { Table } from './Table'
import { TopTableToolbar } from './TopTableToolbar'
import { groupHeaders } from '../../../api/headers'
import { enUS } from '../../../constants'
import Breadcrumbs from '../../../ui_toolkit/Breadcrumbs/Breadcrumbs'
import { useReportContext } from '../providers/ReportContextProvider'
import { TABLE_TYPE_HEADERS, ReportType } from '../types'
import { columnHelper, createColumns, createData } from '../utils'

interface TableCreatorProps {
  tableData: any
  reportType: ReportType
}

function TableCreator({ tableData, reportType }: TableCreatorProps) {
  const tableContainerRef = useRef<HTMLDivElement>(null)
  const {
    preserveState,
    columnPinning,
    setAllColumns,
    limit,
    setColumnPinning,
    breadcrumbs,
    handleBreadcrumbClick,
    selectedRowIds,
    setSelectedRowIds,
    headers,
    projectID,
  } = useReportContext()

  const displayMode = preserveState[reportType].displayModeColumns
  const tableId = TABLE_TYPE_HEADERS[reportType]
  const groupedHeaders = headers
    ? groupHeaders(headers[tableId], reportType)
    : []

  const columns = React.useMemo(() => {
    const selectColumn = columnHelper.group({
      id: 'select',
      cell: ({ row }) => {
        const dtid = row.original['100000']
        const isChecked = Array.from(selectedRowIds).some(
          (selectedRow) => selectedRow.DTID === dtid
        )

        return (
          <div
            data-testid={`checkbox-${dtid}`}
            style={{ padding: 0, paddingLeft: `${row.depth * 1.5}rem` }}
            className='checkbox-container'
          >
            {dtid === 'Total' ||
              (row.depth < 2 && (
                <Checkbox
                  checked={isChecked}
                  size='small'
                  onChange={() => {
                    if (isChecked) {
                      // eslint-disable-next-line no-extra-semi
                      ;[...selectedRowIds].forEach((x) => {
                        if (x.DTID === dtid) {
                          selectedRowIds.delete(x)
                        }
                      })
                    } else {
                      selectedRowIds.add({
                        DTID: row.original['100000'],
                        parentDTID: row.original['100001'],
                        version: row.original['100003'],
                      })
                    }

                    setSelectedRowIds(new Set([...selectedRowIds]))
                  }}
                  sx={{
                    cursor: 'pointer',
                    padding: 0,
                    [isChecked ? 'visibility' : '']: isChecked ? 'visible' : '',
                    '&.Mui-checked': {
                      color: '#102D92',
                    },
                  }}
                  className='select-checkbox'
                />
              ))}
          </div>
        )
      },
      enableSorting: false,
      enablePinning: false,
    })
    const filteredGroupedHeaders = groupedHeaders.filter((header) =>
      +projectID === 1010
        ? header.name.includes('TTD') ||
          header.name === 'Drive Trial Identification'
        : !header.name.includes('TTD')
    )
    return [
      selectColumn,
      ...createColumns(
        reportType === 'lanes' ? filteredGroupedHeaders : groupedHeaders,
        displayMode
      ),
    ]
    // On table data change, memorize new columns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData, displayMode, breadcrumbs, groupHeaders, selectedRowIds])

  const bodyData = React.useMemo(
    () => createData(tableData, groupedHeaders, breadcrumbs),
    [tableData, groupHeaders, breadcrumbs]
  )

  const numberOfRows = Number(tableData?.data.paging?.total)
  const pageCount =
    (tableData && tableData.data && Math.ceil(numberOfRows / limit)) || 0

  const {
    getLeftHeaderGroups,
    getRightHeaderGroups,
    getCenterHeaderGroups,
    getAllColumns,
    getRowModel,
    getVisibleFlatColumns,
    getVisibleLeafColumns,
  } = useReactTable<any>({
    data: bodyData,
    columns,
    state: {
      columnVisibility: preserveState[reportType].columnVisibility,
      columnPinning,
      sorting:
        breadcrumbs.length === 1
          ? preserveState[reportType].sorting
          : breadcrumbs.length === 2
            ? preserveState[reportType].sections?.sorting
            : preserveState[reportType].details?.sorting,
    },
    getRowId: (row) => row[100000],
    getCoreRowModel: getCoreRowModel(),
    onColumnPinningChange: setColumnPinning,
    manualPagination: true,
    manualSorting: true,
  })

  const { rows, flatRows } = getRowModel()
  const visibleColumns = getVisibleFlatColumns()
  const visibleLeafs = getVisibleLeafColumns()

  useEffect(() => {
    setAllColumns(getAllColumns())
  }, [getAllColumns, setAllColumns, columns])

  if (Object.keys(tableData.data).length === 0) {
    return (
      <Stack width={'100%'}>
        {breadcrumbs.length > 1 && (
          <Breadcrumbs
            items={breadcrumbs.map((x) => x.name)}
            handleClick={handleBreadcrumbClick}
          />
        )}
        <Alert variant='standard' severity='info' style={{ marginTop: '20px' }}>
          {enUS.NO_DATA_TO_DISPLAY}
        </Alert>
      </Stack>
    )
  }

  return visibleLeafs.length === 1 ? (
    <StyledAlert variant='standard' severity='info'>
      All columns have been hidden.
    </StyledAlert>
  ) : (
    <Stack direction='column' spacing={1} width='auto' maxWidth='100%'>
      {breadcrumbs.length > 1 && (
        <Breadcrumbs
          items={breadcrumbs.map((x) => x.name)}
          handleClick={handleBreadcrumbClick}
        />
      )}
      <TopTableToolbar
        selectedRowIds={selectedRowIds}
        visibleColumns={visibleColumns}
        bodyRows={flatRows}
        bodyData={bodyData}
      />
      <StyledTableContainer
        ref={tableContainerRef}
        style={{
          maxHeight: `calc(100vh - ${
            breadcrumbs.length > 1 ? '216px' : '152px'
          })`,
        }}
      >
        {getLeftHeaderGroups()[0].headers.length > 0 && (
          <Table
            rows={rows}
            headerGroups={getLeftHeaderGroups()}
            position='left'
            showHeaders
          />
        )}

        {getCenterHeaderGroups()[0].headers.length > 0 && (
          <Table
            rows={rows}
            headerGroups={getCenterHeaderGroups()}
            position='center'
            showHeaders
          />
        )}

        {getRightHeaderGroups()[0].headers.length > 0 && (
          <Table
            rows={rows}
            headerGroups={getRightHeaderGroups()}
            position='right'
            showHeaders
          />
        )}
      </StyledTableContainer>

      {numberOfRows > limit && <Pagination pageCount={pageCount} />}
    </Stack>
  )
}

export default TableCreator
