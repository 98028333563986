import Papa from 'papaparse'
import { DrawingObj, Point } from './objects/singleDrawing'

interface ParseCSVResult {
  header: string[]
  headerMap: { [key: string]: number }
  rows: string[][]
}

const parseCSV = (csv: string): ParseCSVResult => {
  let result: ParseCSVResult = { header: [], headerMap: {}, rows: [] }

  Papa.parse(csv, {
    header: true,
    skipEmptyLines: true,
    complete: function (results: any) {
      const rowsArray: string[][] = []
      const valuesArray: string[][] = []
      results.data.forEach((d: any) => {
        rowsArray.push(Object.keys(d))
        valuesArray.push(Object.values(d))
      })
      const headerMap: { [key: string]: number } = {}
      if (rowsArray.length > 0) {
        for (let i = 0; i < rowsArray[0].length; i++) {
          headerMap[rowsArray[0][i]] = i
        }
      }
      result = {
        header: rowsArray[0] || [],
        headerMap: headerMap,
        rows: valuesArray,
      }
    },
  })

  return result
}

export const parseDrawingsCSV = (
  data: string,
  fetchCallback: (data: any) => void
) => {
  const frames = new Map<number, DrawingObj[]>()
  const output = parseCSV(data)
  const headerMap = output.headerMap

  output.rows.forEach((element: string[]) => {
    const frameCount = parseInt(element[headerMap['FrameCount']])
    const el: DrawingObj = {
      type: element[headerMap['ObjectType']],
      color: element[headerMap['Color']],
      points: [],
      attributes: element[headerMap['Attributes']],
    }
    const points = element[headerMap['Points']].split(' ')
    const xy: Point[] = []

    for (let i = 0; i < points.length; i += 2) {
      const x = parseFloat(points[i])
      const y = parseFloat(points[i + 1])
      xy.push({ x, y })
    }
    el.points = xy

    if (!frames.has(frameCount)) {
      frames.set(frameCount, [])
    }
    frames.get(frameCount)!.push(el)
  })

  fetchCallback(frames)
}
