import { MouseEvent } from 'react'
import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import dayjs from 'dayjs'
import { IdType, TimelineAnimationOptions } from 'otto-vis-timeline'
import { DataSet } from 'vis-data'
import {
  GroupAddPosition,
  NewGroupData,
  AdditionalGroupData,
  TimelineDataGroup,
  TimelineItemData,
  JiraStatus,
  JiraIssueStatusMap,
} from './types'
import { EditTagRequest } from '../../api'
import { DTExclusions } from '../../api/exclusions'
import { urls } from '../../api/urls'
import { DriveTrial, HighlightMode, ITimelineContext } from '../../details'
import { ObjectHighlight, TimelineBackground } from '../../details/types'
import { toMilliseconds } from '../../utils'
import { ChartData, TimelineRangeChangeEvent } from './TimelineChartElements'
import { TimingProgress } from '../../timing/timing'

export const controlledTimeMarkerId = 'custom-marker'
export const animation: TimelineAnimationOptions = {
  animation: {
    duration: 250,
    easingFunction: 'easeInOutQuart',
  },
  zoom: true,
}

export const createTimelineLoader = (
  loaderItemId: IdType,
  group: IdType | undefined,
  totalDuration: number
): TimelineItemData => {
  const start = dayjs(0).toDate()
  const end = dayjs(0).add(totalDuration, 'second').toDate()

  return {
    content: `
        <span>
            <p>Loading data</p>
            <div></div>
        </span>
        `,
    id: loaderItemId,
    selectable: false,
    className: 'spinner',
    isItem: false,
    style: 'height: 18px; position: relative; z-index: 100;',
    start,
    end,
    group,
  }
}
export const createTimelineError = (
  errorItemId: IdType,
  totalDuration: number,
  group?: IdType
): TimelineItemData => {
  const start = dayjs(0).toDate()
  const end = dayjs(0).add(totalDuration, 'second').toDate()

  return {
    content: `
        <span>
            <p>Error fetching data</p>
        </span>
        `,
    id: errorItemId,
    className: 'spinner error',
    isItem: false,
    style: 'z-index: 100',
    start,
    end,
    group,
  }
}

export const createBackground = (
  tli: TimelineItemData[],
  driveTrials: DriveTrial[]
) => {
  driveTrials.forEach((item: DriveTrial, index: number) => {
    const key = item.parentDTID
    if (index % 2) {
      tli.push({
        start: toMilliseconds(item.previousDuration),
        end: toMilliseconds(item.cumulativeDuration),
        content: '',
        id: `background-${key}`,
        isItem: false,
        type: 'background',
        className: 'background',
      })
    }
  })
}

export const createExclusionBackground = (
  tli: TimelineItemData[],
  driveTrials: DriveTrial[],
  exclusionData: DTExclusions,
  frameRate: number,
  groups: number[]
) => {
  const timelineStart = dayjs(0)
  driveTrials.forEach(({ parentDTID, previousDuration }) => {
    const exclusions = exclusionData[parentDTID]
    if (exclusions) {
      exclusions.map((exc, index) => {
        const startFrame = exc.startFrame / frameRate + previousDuration
        const endFrame = exc.endFrame / frameRate + previousDuration

        groups.forEach((groupId) => {
          tli.push({
            start: timelineStart.add(startFrame, 'second').toDate(),
            end: timelineStart.add(endFrame, 'second').toDate(),
            title: exc.reason,
            content: '',
            id: `exclusion-${parentDTID}-${index}-${groupId}`,
            style: 'height: 18px',
            isItem: false,
            type: 'range',
            group: groupId,
            className: 'exclusion-background',
          })
        })
      })
    }
  })
}

export const onGroupAdd = (
  data: NewGroupData,
  timelineContext: ITimelineContext,
  ctxMenuGroup?: IdType
) => {
  if (!timelineContext.groups) {
    timelineContext.groups = new DataSet()
  }

  let maxId = -1
  timelineContext.groups.forEach((x) => {
    if (+x.id > maxId) {
      maxId = +x.id
    }
  })

  const newGroup: TimelineDataGroup = {
    order: data.order,
    content: data.name.length > 15 ? `${data.name.slice(0, 15)}...` : data.name,
    value: data.value,
    id: maxId + 1,
    title: `${data.name}: ${data.type}`,
    hasFocus: data.name === 'Signs' || data.name === 'Lights',
    className: `group-position-${maxId + 1}`,
    visible: true,
  }

  if (data.additionalData) {
    newGroup.nestedGroups = data.additionalData.nestedGroups
  }

  const groupArray = timelineContext.groups.map((x) => x)
  const clickedGroup = groupArray.find((x) => x.id === ctxMenuGroup)
  if (!clickedGroup) {
    timelineContext.groups.update([
      ...timelineContext.groups.map((x) => x),
      newGroup,
    ])
    return
  }

  const groupIndex = groupArray.findIndex((x) => x.id === ctxMenuGroup)
  if (groupIndex >= 0) {
    const groups = timelineContext.groups.map((x) => x)
    // data.position is GroupAddPosition enum. Above = 0, Under = 1.
    groups.splice(groupIndex + data.position, 0, newGroup)
    timelineContext.groups.update(groups)
  } else {
    timelineContext.groups.update([
      ...timelineContext.groups.map((x) => x),
      newGroup,
    ])
  }
}

export const onGroupSelect = (
  id: number,
  activeGroup: number | undefined,
  setActiveGroup: (g: number | undefined) => void
) => {
  const selectedRow = Array.from(
    document.getElementsByClassName(`group-position-${id}`)
  ).find((x) => x.parentElement?.classList.contains('vis-foreground'))

  const otherRows = Array.from(document.querySelectorAll('.vis-foreground'))
    .filter((x) => x.parentElement?.classList.contains('vis-itemset'))
    .map((row) => [...row.children])[0]

  if (!selectedRow) {
    return
  }

  if (id === activeGroup) {
    selectedRow.classList.remove('focused-row')
    setActiveGroup(undefined)
  } else {
    otherRows.forEach((el) => el.classList.remove('focused-row'))
    selectedRow.classList.add('focused-row')
    setActiveGroup(id)
  }
}

export const removeSelected = () => {
  const el = document.getElementsByClassName(`focused-row`)[0]
  if (el) {
    el.classList.remove('focused-row')
  }
}

export const onGroupRemove = (
  id: number,
  timelineContext: ITimelineContext,
  activeGroup: number | undefined,
  setActiveGroup: (g: number | undefined) => void
) => {
  if (!timelineContext.groups) {
    return
  }

  timelineContext.groups.remove(id)

  if (id === activeGroup) {
    setActiveGroup(undefined)
  }

  timelineContext.groups.update([...timelineContext.groups.map((x) => x)])
}

export const addInitialGroup = (
  order: number,
  name: string,
  timelineContext: ITimelineContext,
  additionalData?: AdditionalGroupData,
  parentName: string | undefined = ''
) => {
  const key = `${parentName}-${name}`
  const groupFound = timelineContext.groups.get({
    filter: (item) => item.value.toLowerCase().includes(key.toLowerCase()),
  })

  if (!groupFound.length) {
    onGroupAdd(
      {
        order,
        name,
        value: key,
        position: GroupAddPosition.UNDER,
        relativeToGroup: undefined,
        type: '',
        additionalData,
      },
      timelineContext
    )
  }
}

export const calculateStartEndTime = (
  driveTrials: DriveTrial[],
  activeDTID: number,
  timeData: ObjectHighlight | TimelineBackground
) => {
  const activeVideo = driveTrials.find((x) => x.parentDTID === activeDTID)
  if (!activeVideo)
    return {
      end: 0,
      start: 0,
    }

  const prevVideo = driveTrials.find((x) => x.id === activeVideo?.id - 1)

  const prevDuration = !prevVideo
    ? 0
    : toMilliseconds(prevVideo.cumulativeDuration)

  const startTime = timeData.timelineStartTime + prevDuration
  const endTime = timeData.timelineEndTime + prevDuration
  return {
    end: endTime,
    start: startTime,
  }
}

export const getTimelineDuration = (items: TimelineItemData[]) => {
  const maxItem = items
    .filter((item) => item.end)
    .reduce((prev, current) => {
      return prev.end! > current.end! ? prev : current
    })
  return maxItem.end
}

export const calculateOriginalTime = (
  time: number,
  highlightMode: HighlightMode
) => {
  if (highlightMode.id === -1) {
    return time
  }

  let calculatedTime = -1
  highlightMode.items.map((item: TimelineItemData) => {
    if (
      item.end &&
      new Date(item.start).getTime() <= time &&
      new Date(item.end).getTime() >= time
    ) {
      calculatedTime =
        item.originalStart! + (time - new Date(item.start).getTime())
    }
  })
  return calculatedTime
}
export const getStatusColor = (status: JiraStatus) => {
  const statusColors = {
    'To Do': '#D3D3D3',
    'On Hold': '#D3D3D3',
    'Ready 4 QA': '#D3D3D3',
    deleted: '#D3D3D3',
    Reopened: '#D3D3D3',
    'In Progress': '#0052CC',
    Review: '#0052CC',
    QA: '#0052CC',
    Done: '#00875A',
    Closed: '#00875A',
  }
  return statusColors[status] || '#FFFFFF'
}

export const createUrl = (url: string, issueID: string) =>
  url.replace(':issueID', issueID)

export const jiraStatusUpdate = (
  issueID: string,
  timelineContext: ITimelineContext,
  activeTag: number | undefined,
  editTagMutation: UseMutateAsyncFunction<
    AxiosResponse<any, any>,
    Error,
    EditTagRequest,
    unknown
  >
) => {
  return new Promise<JiraIssueStatusMap>((resolve, reject) => {
    const eventSource = new EventSource(
      process.env.REACT_APP_URL_BACKEND_WEB +
        createUrl(urls.jiraSubscribe, issueID)
    )

    eventSource.onmessage = async (event) => {
      const data = JSON.parse(event.data)
      const foundItem = timelineContext.items.get(`tag-${activeTag}`)
      const status = data[issueID]
      if (foundItem) {
        const statusColor = getStatusColor(status)
        foundItem.style = `background-color: ${statusColor}`
        foundItem.status = status
        if (status === 'deleted') {
          editTagMutation({
            tagId: activeTag!,
            note: foundItem.title || '',
            summary: foundItem.summary || '',
            issueType: foundItem.issueType || 'Task',
            team: foundItem.team || '10022',
            jiraID: status,
          })
          foundItem.jiraID = undefined
        }
        timelineContext.items.update(foundItem)
      }
      resolve(data)
    }

    eventSource.onerror = (err) => {
      reject(err)
      eventSource.close()
    }
  })
}

export const transformDataToChartPoints = (
  timelineItems: TimelineItemData[],
  maxScale: number
): (ChartData | null)[] => {
  const maxMetric = Math.max(...timelineItems.map((item) => +item.metric! || 0))
  let previousDtid: number | null = null
  const result: (ChartData | null)[] = []

  timelineItems
    .filter(
      (item) =>
        !(
          item.id.toString().includes('tag') ||
          item.id.toString().includes('exclusion') ||
          item.id.toString().includes('background')
        )
    )
    .forEach((timelineItem) => {
      if (previousDtid !== null && previousDtid !== timelineItem.dtid) {
        result.push(null)
      }

      result.push({
        start: +timelineItem.start,
        end: +timelineItem.end!,
        value: ((+timelineItem.metric! || 0) / maxMetric) * maxScale,
        label: +timelineItem.metric! || 0,
      })

      previousDtid = timelineItem.dtid!
    })

  return result
}

export const calculateZoomLevel = (
  originalEnd: number,
  event: TimelineRangeChangeEvent
): number => {
  const newRange = event.end.getTime() - event.start.getTime()
  return +(originalEnd / newRange).toFixed(2)
}

export const calcSliderTime = (e: MouseEvent, duration: number) => {
  const { left, width } = e.currentTarget.getBoundingClientRect()
  const xPosition = e.clientX - left
  const percent = (xPosition / width) * 100
  const position = TimingProgress.percent2position(percent, [0, duration])
  return { percent, position }
}

const isSameRange = (start: number, end: number, ranges: DriveTrial[]) => {
  let startRangeIndex = -1
  let endRangeIndex = -1

  for (let i = 0; i < ranges.length; i++) {
    if (
      ranges[i].previousDuration <= start &&
      (i === ranges.length - 1 || ranges[i + 1].previousDuration > start)
    ) {
      startRangeIndex = i
    }
    if (
      ranges[i].previousDuration <= end &&
      (i === ranges.length - 1 || ranges[i + 1].previousDuration > end)
    ) {
      endRangeIndex = i
    }
  }

  return startRangeIndex === endRangeIndex
}

const isSameRangeHighlight = (
  start: number,
  end: number,
  highlightMode: HighlightMode
) => {
  let startRangeIndex = -1
  let endRangeIndex = -1
  const ranges = highlightMode.items.get()

  for (let i = 0; i < ranges.length; i++) {
    if (ranges[i].isItem) {
      if (+ranges[i].start <= start && +ranges[i].end! >= start) {
        startRangeIndex = i
      }
      if (+ranges[i].start <= end && +ranges[i].end! >= end) {
        endRangeIndex = i
      }
    }
  }

  return startRangeIndex === endRangeIndex
}

export const isNotationValid = (
  start: number,
  end: number,
  driveTrials: DriveTrial[],
  highlightMode: HighlightMode
) => {
  if (highlightMode.id === -1) {
    return isSameRange(start / 1000, end / 1000, driveTrials)
  } else {
    return isSameRangeHighlight(start, end, highlightMode)
  }
}
