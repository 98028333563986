import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { DriveTrialsResp } from '../../details/types'
import { getBaseAuthData } from '../base'
import { urls } from '../urls'

type GetDriveTrialParams = {
  queryData: string
  enabled?: boolean
}

const getDriveTrialsParams = (selectedRows: string) => ({
  selectedRows,
  ...getBaseAuthData(),
})

export const useDriveTrialsQuery = ({
  queryData,
  enabled = true,
}: GetDriveTrialParams) =>
  useQuery({
    enabled,
    queryKey: ['driveTrials', queryData],
    queryFn: (): Promise<DriveTrialsResp> =>
      axios
        .get<DriveTrialsResp>(urls.driveTrials, {
          params: getDriveTrialsParams(queryData),
          withCredentials: true,
        })
        .then(({ data }) => data),
  })
