import { PropsWithChildren } from 'react'
import { Grid } from '@mui/material'
import { GridOption } from '../../ui_toolkit/GridSelectors/GridSelectors'

interface ICopyProps {
  option: GridOption
}

export const GridLayoutCopyChildren = ({
  option,
  children,
}: PropsWithChildren<ICopyProps>) => (
  <Grid container spacing={1.25} height='100vh'>
    {option.value.map((x) => {
      return Array(x)
        .fill('item')
        .map((i) => (
          <Grid item xs={12 / x} key={i}>
            {children}
          </Grid>
        ))
    })}
  </Grid>
)

interface IProps extends ICopyProps {
  children: React.ReactNode[]
}

export const GridLayout = ({ option, children }: IProps) => {
  const items: React.ReactNode[] = []
  const gridDef = option.value
  const totalItemsPossible = gridDef[0] + gridDef[1]

  const getDimensions = (index: number, isFullscreen: boolean) => {
    if (isFullscreen) return {}

    return !option.customDimensions
      ? { height: '50%' }
      : option.customDimensions[index]
  }

  if (children) {
    let childNo = 0
    gridDef.forEach((col) => {
      const itemSize = 12 / col
      for (let i = 0; i < col; i++) {
        if (totalItemsPossible > childNo) {
          items.push(
            <Grid
              item
              xs={itemSize}
              key={`${col}-${i}-${childNo}`}
              sx={getDimensions(childNo, totalItemsPossible === 1)}
            >
              {children[items.length]}
            </Grid>
          )
          childNo++
        } else {
          return
        }
      }
    })
  }

  return (
    <Grid
      container
      position={'relative'}
      spacing={1.25}
      height='calc(100vh - 83px)'
      padding={1.25}
    >
      {items}
    </Grid>
  )
}
