import { useContext } from 'react'
import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { TimedData } from '../../components/Canvas/CanvasBuffer'
import { useDriveTrialContext } from '../../details'
import { DataFrame, LineType, MediaSyncContext } from '../../details/types'
import { toMilliseconds } from '../../utils'
import { urls } from '../urls'

const FETCH_OFFSET = 5
const FETCH_RANGE = 35

const calculateStartEndTime = (
  startDate: number,
  offset: number,
  time?: number
) => {
  const startTime = time
    ? toMilliseconds(startDate + Math.floor(time - offset - FETCH_OFFSET))
    : toMilliseconds(startDate)
  const endTime = time
    ? toMilliseconds(startDate + Math.floor(time - offset) + FETCH_RANGE)
    : toMilliseconds(startDate + FETCH_RANGE)

  return {
    startTime,
    endTime,
  }
}

export const useDriveTrialEgoQuery = (lineType: LineType, time?: number) => {
  // we will always have this data since this call is in the `View3DBuffer`
  // component which is conditionally rendered and depends on this data
  const { getCurrentDriveTrial } = useDriveTrialContext()
  const { timingObj } = useContext(MediaSyncContext)
  const currentTime = timingObj?.pos
  const activeVideo = getCurrentDriveTrial(currentTime)

  return useQuery({
    staleTime: Infinity,
    retryOnMount: false,
    queryKey: ['driveTrialEgo', activeVideo, lineType, time],
    queryFn: async (): Promise<TimedData<DataFrame>> => {
      const startDate = activeVideo!.startDate
      const offset = activeVideo!.previousDuration

      const { data } = await axios.get<TimedData<DataFrame>>(
        urls.driveTrialEgo.replace(':id', activeVideo!.DTID.toString()),
        {
          params: {
            ...calculateStartEndTime(startDate, offset, time),
            lineType,
          },
        }
      )

      return data
    },
  })
}
