import { Property } from 'csstype'
import { match } from 'ts-pattern'
import { ColorKeys } from '../../constants'
import { LanePoint, ObjectColor, Point, SourceType } from '../types'

export const getLayerAlias = (objType: string) => {
  switch (objType) {
    case 'egoLane':
    case 'egoLabel':
      return 'ActiveEgoLineColor'
    case 'adjacentLane':
    case 'adjacentLabel':
      return 'ActiveAdjacentLaneColor'
    case 'sign':
      return 'ActiveSignColor'
    case 'car':
      return 'ActiveCarColor'
    case 'bus':
      return 'ActiveBusColor'
    case 'pedestrian':
      return 'ActivePedColor'
    case 'bicycle':
      return 'ActiveCycleColor'
    case 'truck':
      return 'ActiveTruckColor'
    case 'motorcycle':
      return 'ActiveMotorcycleColor'
    case 'trafficLight':
      return 'ActiveTrafficLightColor'
    case 'ttd':
      return 'ActiveTtdColor'
    default:
      return 'ActiveCarColor'
  }
}

export const findColor = (
  key: ColorKeys,
  colors: ObjectColor[],
  sourceType: SourceType
): Property.Color => {
  if (!colors) {
    return 'black'
  }

  const source = match(sourceType)
    .with('me', () => 'Mobileye')
    .with('otto', () => 'Ottometric')
    .exhaustive()

  const color = colors.find(
    (x) => x.name === key && x.source === source
  )?.layerColor

  if (!color) {
    return '#000000'
  }

  return `#${color}`
}

const commonRectRadius = 1

export const renderText = (
  ctx: OffscreenCanvasRenderingContext2D,
  rectX: number,
  rectY: number,
  textX: number,
  textY: number,
  fillStyle: string,
  text: string,
  color: string,
  canvasHeight: number,
  canvasWidth: number,
  isFullscreen?: boolean
) => {
  const fontSize = Math.max(
    Math.floor(canvasHeight * (isFullscreen ? 0.015 : 0.025)) | 0
  )

  ctx.font = `${fontSize}px Helvetica`

  const textWidth = ctx.measureText(text.toLowerCase()).width

  const rectWidth = textWidth + 6
  const rectHeight = fontSize + (isFullscreen ? fontSize / 2 : 4)

  let adjustedRectX = rectX
  let adjustedRectY = rectY
  let adjustedTextX = textX
  let adjustedTextY = textY - (isFullscreen ? fontSize / 2 : 0)

  if (adjustedRectX + rectWidth > canvasWidth) {
    adjustedRectX = canvasWidth - rectWidth
  }
  if (adjustedRectX < 0) {
    adjustedRectX = 0
  }
  if (adjustedRectY + rectHeight > canvasHeight) {
    adjustedRectY = canvasHeight - rectHeight
  }

  if (adjustedTextX + textWidth > canvasWidth) {
    adjustedTextX = canvasWidth - textWidth - 4
  }
  if (adjustedTextX < 0) {
    adjustedTextX = 2
  }
  if (adjustedTextY > canvasHeight) {
    adjustedTextY = canvasHeight
  }

  ctx.beginPath()
  ctx.fillStyle = color
  ctx.roundRect(
    adjustedRectX,
    adjustedRectY,
    rectWidth,
    rectHeight,
    commonRectRadius
  )
  ctx.fill()

  ctx.beginPath()
  ctx.fillStyle = fillStyle
  ctx.fillText(text.toLowerCase(), adjustedTextX, adjustedTextY)
}

const createPointWithConfidence = (
  point: LanePoint,
  width: number,
  height: number
): Point | undefined => {
  const x = Math.round(point[0] * width),
    y = Math.round(point[1] * height)

  return {
    confidence: point[2],
    x,
    y,
  }
}

export const fillPointsArray = (
  points: LanePoint[],
  width: number,
  height: number
): Point[] => {
  const path: Point[] = []

  points.forEach((p) => {
    const point = createPointWithConfidence(p, width, height)
    if (point) {
      path.push(point)
    }
  })

  return path
}
