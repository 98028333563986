import { ColorKeys, getColorName } from '../../constants'
import { ObjectColor, ObjectType, SourceType } from '../../details/types'
import { findColor, getLayerAlias } from '../../details/workers/utils'
import { ReportType } from '../../pages/Report/types'
import { isDisabledOption } from '../LayersPanel/utils'

interface CanvasData {
  name: ObjectType
}

export const laneCanvasClasses = [
  { class: 'ActiveEgoLineClass' },
  { class: 'ActiveAdjacentLaneClass' },
  { class: 'ActiveEgoLineColor' },
  { class: 'ActiveAdjacentLaneColor' },
]

export const getCanvasObjectType = (val: string): ObjectType | null => {
  switch (val) {
    case 'ActiveSignColor':
      return 'sign'
    case 'ActiveEgoLineColor':
      return 'egoLane'
    case 'ActiveEgoLineClass':
      return 'egoLabel'
    case 'ActiveCarColor':
      return 'car'
    case 'ActiveTruckColor':
      return 'truck'
    case 'ActiveBusColor':
      return 'bus'
    case 'ActivePedColor':
      return 'pedestrian'
    case 'ActiveCycleColor':
      return 'bicycle'
    case 'ActiveMotorcycleColor':
      return 'motorcycle'
    case 'ActiveAdjacentLaneColor':
      return 'adjacentLane'
    case 'ActiveAdjacentLaneClass':
      return 'adjacentLabel'
    case 'ActiveTrafficLightColor':
      return 'trafficLight'
    case 'ActiveTtdColor':
      return 'ttd'
    default:
      return null
  }
}

const addLaneCanvas = (laneCanvases: CanvasData[], lanes: string[]) => {
  if (lanes.length) {
    laneCanvases.push({
      name: getCanvasObjectType(lanes[0])!,
    })
  }
}

export const getVisibleCanvases = (
  objects: string[],
  colors: ObjectColor[],
  source: SourceType,
  reportType: ReportType
) => {
  const possibleLaneCanvases = [...laneCanvasClasses.map((x) => x.class)]

  const allViableCanvases = objects.filter((obj) => {
    const color = findColor(getLayerAlias(obj), colors, source)
    return (
      getCanvasObjectType(obj) &&
      !isDisabledOption(getColorName(obj as ColorKeys), color, reportType)
    )
  })

  const nonLaneCanvases: CanvasData[] = allViableCanvases
    .filter((obj) => !possibleLaneCanvases.includes(obj))
    .map((obj) => ({
      name: getCanvasObjectType(obj)!,
    }))

  const egoLanes = allViableCanvases.filter((x) =>
    x.toLocaleLowerCase().includes('activeegolinecolor')
  )

  const egoLabel = allViableCanvases.filter((x) =>
    x.toLocaleLowerCase().includes('activeadjacentlaneclass')
  )
  const adjLanes = allViableCanvases.filter((x) =>
    x.toLocaleLowerCase().includes('activeegolineclass')
  )

  const adjLabel = allViableCanvases.filter((x) =>
    x.toLocaleLowerCase().includes('activeadjacentlanecolor')
  )

  const laneCanvases: CanvasData[] = []
  addLaneCanvas(laneCanvases, egoLabel)
  addLaneCanvas(laneCanvases, adjLabel)
  addLaneCanvas(laneCanvases, egoLanes)
  addLaneCanvas(laneCanvases, adjLanes)

  return [...nonLaneCanvases, ...laneCanvases]
}
