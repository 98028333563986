import { useContext, useEffect, useRef, useState } from 'react'
import { IdType } from 'otto-vis-timeline'
import { createHighlightGainStyle } from './colors'
import { addNotationToGroup, addTagToGroup } from './TimelineTags/handlers'
import { TimelineItemData, VideoTimelineFetcherProps } from './types'
import {
  addInitialGroup,
  calculateStartEndTime,
  createBackground,
  createTimelineError,
} from './utils'
import {
  getNameFromTagType,
  useEditTagMutation,
  useHighlightsQueries,
  useTagsQueries,
} from '../../api'
import {
  trackHeightMap,
  useDriveTrialContext,
  useTimelineContext,
} from '../../details'
import { MediaSyncContext } from '../../details/types'
import { notEmpty } from '../../tslib/types'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import { TimedData } from '../Canvas/CanvasBuffer'

function VideoTimelineDataFetcher({
  synchronizer,
  viewportId,
  signsData,
  onDataReceived,
}: VideoTimelineFetcherProps) {
  const { totalDuration } = useContext(MediaSyncContext)
  const {
    getDriveTrialByKey,
    getDriveTrialByParentDTID,
    driveTrials,
    highlightMode,
    setHighlightMode,
    redirectData,
  } = useDriveTrialContext()
  const timelineContext = useTimelineContext()
  const { editTagMutation } = useEditTagMutation()
  const groupItemsFetched = useRef<Record<string, boolean>>({})
  const tags = useTagsQueries()
  const [dataReceived, setDataReceived] = useState<boolean>(false)
  const columns = redirectData?.columns
  const allColumns =
    columns?.flatMap((x) => x.items.map((y) => `${x.kpi}|${y}`)) || []
  const { highlights, isHighlightsFetched } = useHighlightsQueries(allColumns)

  useEffect(() => {
    if (isHighlightsFetched) {
      setDataReceived(true)
      onDataReceived()
    }
  }, [isHighlightsFetched])

  useEffect(() => {
    // this will make logic backward compatible in dev environment
    // because of the strict mode which will fire this effect twice
    if (columns) {
      columns.forEach(({ kpi, items }, index) => {
        items.forEach((item) => {
          addInitialGroup(index, item, timelineContext)
        })
        if (kpi) {
          addInitialGroup(index, kpi, timelineContext, {
            nestedGroups: items.map(
              (group) => allColumns.indexOf(`${kpi}|${group}`) + index
            ),
          })
        }

        // here we add one fake timeline item in each group
        // to prevent nested groups from losing height if they have no items.
        const timelinePlaceholders: TimelineItemData[] = []

        driveTrials?.length &&
          createBackground(timelinePlaceholders, driveTrials)

        timelineContext.groups.forEach((g) => {
          const height =
            timelineContext.trackParameters?.[g.id as number]?.height || 'small'
          timelinePlaceholders.push({
            isItem: false,
            start: 0,
            end: driveTrials[driveTrials.length - 1].cumulativeDuration * 1000,
            content: '',
            id: g.id + 'placeholder',
            selectable: false,
            group: g.id,
            style: `height: ${
              height ? trackHeightMap[height] : 24
            }px; visibility: hidden; pointer-events: none;`,
            type: 'range',
          })

          timelineContext.items.update(timelinePlaceholders)
          timelineContext.initialItems.update(timelinePlaceholders)
          timelineContext.originalItems.update(timelinePlaceholders)
        })
      })
    }
    // on first render set initial `timelineContext` state
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timelineContext.trackParameters])

  useEffect(() => {
    const timelineItems: TimelineItemData[] = []
    highlights
      .filter((highlight) => !highlight.isError)
      .forEach(
        ({ data, isFetched, isError, highlightType, DTID, statusCode }) => {
          const loaderKey = `${highlightType}-loader`
          if (
            !groupItemsFetched.current[`${DTID}-${highlightType}`] &&
            data?.length
          ) {
            data.forEach((highlight, index) => {
              const timeData = calculateStartEndTime(
                driveTrials,
                DTID,
                highlight
              )
              const groupId = timelineContext.groups
                .map((x) =>
                  highlightType.split('|')[1].toLowerCase() ===
                  x.value.substring(1).toLowerCase()
                    ? x.id
                    : undefined
                )
                .filter(notEmpty)[0]
              const highlightTitle = highlightType.split('|')[1]
              const title = `start: ${
                highlight.timelineStartTime / 1000
              }s <br> end: ${highlight.timelineEndTime / 1000}s <br> value: ${
                highlight.comment
              } ${
                highlightTitle === 'Altitude'
                  ? 'm'
                  : highlightTitle === 'Kph'
                    ? 'km/h'
                    : '%'
              }`

              timelineItems.push({
                ...timeData,
                isItem: true,
                content: '',
                metric: +highlight.comment,
                flag: highlight.flag,
                dataTestId: `timelineHighlightTooltip_${highlight.hlid}`,
                id: `${highlight.hlid}_${DTID}`,
                selectable: true,
                group: groupId,
                title: title,
                type: 'range',
                style:
                  createHighlightGainStyle(
                    highlight.gain,
                    timelineContext.trackParameters,
                    groupId
                  ) + `z-index: ${index}`,
                gain: highlight.gain ?? 0,
                dtid: DTID,
                className: highlightType.includes('SPR')
                  ? 'vertical-line'
                  : `duration-${Math.floor(
                      highlight.timelineEndTime - highlight.timelineStartTime
                    )}`,
              })
            })
            groupItemsFetched.current[`${DTID}-${highlightType}`] = true
            timelineContext.items.update(timelineItems)
            timelineContext.initialItems.update(timelineItems)
            timelineContext.originalItems.update(timelineItems)
          }

          if (isFetched && !isError && data?.length === 0) {
            groupItemsFetched.current[`${DTID}-${highlightType}`] = true
          }

          if (isFetched && isError && statusCode !== 404) {
            const errorItem = createTimelineError(
              loaderKey,
              totalDuration,
              timelineContext.items.get(loaderKey)?.group
            )
            timelineContext.items.update([errorItem])
          }
        }
      )

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlights])

  // add tags to the group
  useEffect(() => {
    const tagsData: Record<IdType, TimedData<boolean>> = {}
    tags.forEach(({ tags, dtid }) =>
      tags?.forEach((tag) => {
        const groupID = timelineContext.groups
          .map((group) => group)
          .find((grp) => grp.content === tag.description)!.id

        if (!tag.endTimestamp) {
          addTagToGroup(
            tag.startTimestamp,
            groupID,
            timelineContext,
            highlightMode,
            setHighlightMode,
            tag,
            dtid,
            getDriveTrialByParentDTID(dtid),
            signsData
          )
        } else {
          addNotationToGroup(
            tag.startTimestamp,
            tag.endTimestamp,
            groupID,
            timelineContext,
            highlightMode,
            tag,
            dtid,
            getDriveTrialByKey(dtid),
            editTagMutation
          )
        }
        const id = getNameFromTagType(groupID)

        const timelineRowId = timelineContext.groups
          .get({ returnType: 'Array' })
          .findIndex((x) => (x.content as string).includes(id!))

        if (!tagsData[timelineRowId]) tagsData[timelineRowId] = {}
        tagsData[timelineRowId][tag.startTimestamp] = true
      })
    )

    timelineContext.setTags(tagsData)
    // tags should be added to context only on fetching
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tags])

  useEffect(() => {
    dataReceived
      ? synchronizer?.updateStatus(viewportId, true)
      : synchronizer?.updateStatus(viewportId, false)
  }, [dataReceived])

  return !dataReceived ? (
    <div
      style={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
      }}
    >
      <Loader text='Receiving data...' scale={1.5} />
    </div>
  ) : null
}

export default VideoTimelineDataFetcher
