import CryptoJS from 'crypto-js'

const base64url = (source: CryptoJS.lib.WordArray) => {
  let encodedSource = CryptoJS.enc.Base64.stringify(source)
  encodedSource = encodedSource.replace(/=+$/, '')
  encodedSource = encodedSource.replace(/\+/g, '-')
  encodedSource = encodedSource.replace(/\//g, '_')
  return encodedSource
}

export const createToken = (
  userid: string,
  kid: string,
  secret: string,
  iss: string
) => {
  const header = {
    alg: 'HS256',
    typ: 'JWT',
    iss: iss,
    kid: kid,
  }
  const stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header))
  const encodedHeader = base64url(stringifiedHeader)
  const claimSet = {
    sub: userid,
    aud: 'tableau',
    nbf: Math.round(new Date().getTime() / 1000) - 100,
    jti: new Date().getTime().toString(),
    iss: iss,
    scp: ['tableau:views:embed'],
    exp: Math.round(new Date().getTime() / 1000) + 100,
  }
  const stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(claimSet))
  const encodedData = base64url(stringifiedData)
  const token = encodedHeader + '.' + encodedData
  const signature = CryptoJS.HmacSHA256(token, secret)
  const sig = base64url(signature)
  const signedToken = token + '.' + sig

  return signedToken
}
