/* eslint-disable react-hooks/exhaustive-deps */
import { useMemo, useState } from 'react'
import { getSearchHistory } from './storage'

const SUGGESTIONS_LIMIT = 7

type GetSuggestionsProps = {
  email: string
  isSelectingByArrows: boolean
  value: string
}

export function useGetSuggestions({
  email,
  isSelectingByArrows,
  value,
}: GetSuggestionsProps): string[] {
  const [prevSuggestions, setPrevSuggestions] = useState<string[]>([])
  const searchHistory = getSearchHistory(email)

  return useMemo(() => {
    // We don't want to update suggestions if user
    // is changing value by up/down arrow keys
    // In that case we just return previous suggestions until he types something
    if (isSelectingByArrows) {
      return prevSuggestions
    }

    const newSuggestions = searchHistory
      .filter((item: string) =>
        item.toLocaleLowerCase().includes(value.toLocaleLowerCase())
      )
      .slice(0, SUGGESTIONS_LIMIT)

    setPrevSuggestions(newSuggestions)

    return newSuggestions
  }, [
    JSON.stringify([
      isSelectingByArrows,
      searchHistory,
      prevSuggestions,
      value,
    ]),
  ])
}
