import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import {
  BufferGeometry,
  Color,
  Float32BufferAttribute,
  Line,
  LineBasicMaterial,
  Scene,
  WebGLRenderer,
} from 'three'
import { CSS2DRenderer } from 'three/examples/jsm/renderers/CSS2DRenderer.js'
import { DataTable3D } from './DataTable3D/DataTable3D'
import { SignData, SignDataResponse } from './types'
import {
  blueColor,
  meAdjacentColor,
  ottoAdjacentColor,
  yellowColor,
} from './utils/colors'
import { commonLineMaterial } from './utils/constants'
import { drawGridMarkers } from './utils/gridMarkers'
import { drawData } from './utils/GTLines'
import {
  initializeEngineAndScene,
  setupOrbitControls,
} from './utils/initialization'
import { calculateGeometry, modifyMeLine } from './utils/MELines'
import {
  createPointCloud,
  getPointCloudData,
  removeObjectsByNameFromScene,
  setLaneVisibility,
  updateScene,
} from './utils/utils'
import View3DBuffer from './View3DBuffer'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { TopViewContext, useDriveTrialContext } from '../../details'
import {
  EnabledLayers,
  MediaSyncContext,
  TimestampDataFrameMap,
  TopDownData,
} from '../../details/types'
import { selectViewportState } from '../../store/details/viewportData/selectors'
import { GridOption } from '../../ui_toolkit/GridSelectors/GridSelectors'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import { toMilliseconds } from '../../utils'
import NoData from '../NoData/NoData'
import { useProjectData } from '../../api/table/projects'
import { useActiveTrial } from '../../store/hooks/useActiveTrial'

interface View3DProps {
  viewportId: number
  isFullscreen: boolean
  synchronizer?: ISynchronizer
  grid: GridOption
  signsData?: SignDataResponse
}

export function View3D({
  isFullscreen,
  synchronizer,
  viewportId,
  grid,
  signsData,
}: View3DProps) {
  const { sampler, activeVideo: activeVideoDTID } = useContext(MediaSyncContext)
  const { cameras, setCamera, controls, setControls, isOrbitControlsLocked } =
    useContext(TopViewContext)
  const { getCurrentDriveTrial } = useDriveTrialContext()
  const canvasContainerRef = useRef<HTMLDivElement>()
  const [canContSize, setCanContSize] = useState<[number, number]>()
  const [tableFrame, setTableFrame] = useState<SignData[]>()
  const [scene, setScene] = useState<Scene>()
  const [webGLRenderer, setWebGLRenderer] = useState<WebGLRenderer>()

  const [leftMeLine, setLeftMeLine] = useState<Line>()
  const [rightMeLine, setRightMeLine] = useState<Line>()
  const [leftMeAdjacentLine, setLeftMeAdjacentLine] = useState<Line>()
  const [rightMeAdjacentLine, setRightMeAdjacentLine] = useState<Line>()

  const [egoData, setEgoData] = useState<TimestampDataFrameMap>()
  const [adjacentData, setAdjacentData] = useState<TimestampDataFrameMap>()
  const [time, setTime] = useState<number>()

  const [currentPLYFrame, setCurrentPLYFrame] = useState<number>()
  const [isFetching, setIsFetching] = useState(true)
  const [label, setLabel] = useState<CSS2DRenderer>(new CSS2DRenderer())
  const optionsData: TopDownData | EnabledLayers | undefined = useSelector(
    selectViewportState(viewportId)
  )
  const { frameRate } = useProjectData()
  const camera = cameras[viewportId]
  const control = controls[viewportId]

  // V2 Code
  useEffect(() => {
    const handleWheel = (event: WheelEvent) => {
      if (event.deltaY < 0 && camera && camera.fov > 15) {
        camera.fov -= 5
        camera.updateProjectionMatrix()
      }

      if (event.deltaY > 0 && camera && camera.fov < 100) {
        camera.fov += 5
        camera.updateProjectionMatrix()
      }
    }

    if (canvasContainerRef.current) {
      canvasContainerRef.current.addEventListener('wheel', handleWheel)
    }

    return () => {
      if (canvasContainerRef.current) {
        canvasContainerRef.current.removeEventListener('wheel', handleWheel)
      }
    }
  }, [camera])

  useEffect(() => {
    updateSize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grid, isFullscreen])

  const mainLoop = useCallback(
    (ts: number, time: number, offset: number) => {
      if (!activeVideoDTID) {
        return
      }

      if (scene && adjacentData) {
        for (const timestamp in adjacentData) {
          const tsData = adjacentData[timestamp]
          const deltaT = Math.abs(tsData.timestamp - ts)

          if (deltaT < 1000) {
            const PI_2 = Math.PI / 2
            const heading = tsData.heading

            if (leftMeAdjacentLine) {
              let start = tsData.leftStart
              let end = tsData.leftEnd
              const c0 = tsData.leftC0
              const c1 = tsData.leftC1
              const c2 = tsData.leftC2
              const c3 = tsData.leftC3
              const confidance = tsData.leftConfidence
              const availability = tsData.leftAvailability
              const show = confidance > 0.49 && availability > 1

              if (!show) {
                end = 0
                start = 0
              }
              leftMeAdjacentLine.geometry = calculateGeometry(
                start,
                end,
                c0,
                c1,
                c2,
                c3,
                heading - PI_2
              )
              leftMeAdjacentLine.computeLineDistances()
              leftMeAdjacentLine.position.set(0, 0, 0)
            }

            if (rightMeAdjacentLine) {
              let start = tsData.rightStart
              let end = tsData.rightEnd
              const c0 = tsData.rightC0
              const c1 = tsData.rightC1
              const c2 = tsData.rightC2
              const c3 = tsData.rightC3
              const confidance = tsData.rightConfidence
              const availability = tsData.rightAvailability
              const show = confidance > 0.49 && availability > 1

              if (!show) {
                end = 0
                start = 0
              }

              rightMeAdjacentLine.geometry = calculateGeometry(
                start,
                end,
                c0,
                c1,
                c2,
                c3,
                heading - PI_2
              )
              rightMeAdjacentLine.computeLineDistances()
              rightMeAdjacentLine.position.set(0, 0, 0)
            }
          }
        }
      }

      if (scene && egoData) {
        for (const timestamp in egoData) {
          const tsData = egoData[timestamp]
          const deltaT = Math.abs(tsData.timestamp - ts)

          if (deltaT < 1000) {
            const PI_2 = Math.PI / 2
            const X = tsData.x
            const Y = tsData.y
            const heading = tsData.heading

            if (leftMeLine) {
              let start = tsData.leftStart
              let end = tsData.leftEnd
              const c0 = tsData.leftC0
              const c1 = tsData.leftC1
              const c2 = tsData.leftC2
              const c3 = tsData.leftC3
              const confidance = tsData.leftConfidence
              const availability = tsData.leftAvailability
              const show = confidance > 0.49 && availability > 1

              if (!show) {
                end = 0
                start = 0
              }
              leftMeLine.geometry = calculateGeometry(
                start,
                end,
                c0,
                c1,
                c2,
                c3,
                heading - PI_2
              )
              leftMeLine.computeLineDistances()
              leftMeLine.position.set(0, 0, 0)
            }

            if (rightMeLine) {
              let start = tsData.rightStart
              let end = tsData.rightEnd
              const c0 = tsData.rightC0
              const c1 = tsData.rightC1
              const c2 = tsData.rightC2
              const c3 = tsData.rightC3
              const confidance = tsData.rightConfidence
              const availability = tsData.rightAvailability
              const show = confidance > 0.49 && availability > 1

              if (!show) {
                end = 0
                start = 0
              }

              rightMeLine.geometry = calculateGeometry(
                start,
                end,
                c0,
                c1,
                c2,
                c3,
                heading - PI_2
              )
              rightMeLine.computeLineDistances()
              rightMeLine.position.set(0, 0, 0)
            }

            drawGridMarkers(scene, 0, 0, heading)

            if (camera && control) {
              updateScene(
                scene,
                X,
                Y,
                heading,
                control,
                camera,
                isOrbitControlsLocked
              )
            }

            if (signsData) {
              const frame = Math.floor((time - offset) * frameRate)
              const pcData = getPointCloudData(
                activeVideoDTID,
                frame,
                signsData
              )

              if (!pcData) {
                if (scene.getObjectByName('point-cloud')) {
                  removeObjectsByNameFromScene(scene, ['point-cloud'])
                  setCurrentPLYFrame(undefined)
                  setTableFrame(undefined)
                }
              } else {
                setCurrentPLYFrame(pcData[0].GlobalFrameCount)
                setTableFrame(pcData)
              }
            }
          }
        }
      }
    },
    [
      activeVideoDTID,
      scene,
      adjacentData,
      egoData,
      leftMeAdjacentLine,
      rightMeAdjacentLine,
      leftMeLine,
      rightMeLine,
      camera,
      control,
      signsData,
      isOrbitControlsLocked,
      frameRate,
    ]
  )

  useEffect(() => {
    if (!currentPLYFrame || !activeVideoDTID || !scene) {
      return
    }

    if (scene.getObjectByName('point-cloud')) {
      removeObjectsByNameFromScene(scene, ['point-cloud'])
    }

    createPointCloud(activeVideoDTID, currentPLYFrame, (points) => {
      scene && scene.add(points)
      scene.traverse((object) => {
        if (object.name === 'el_vehicle') {
          points.rotateZ(object.rotation.z)
        }
      })
    })
    // When the frame is changed and there is data for the point cloud, create one.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPLYFrame])

  const handleTimeChange = (time: number) => {
    if (!activeVideoDTID || !scene || !egoData) {
      return
    }

    const currentVideo = getCurrentDriveTrial(time)
    if (!currentVideo) return

    const {
      previousDuration: offset,
      startDate,
      startTime,
      originalStartTime,
    } = currentVideo

    const currentTime =
      time + (originalStartTime ? originalStartTime - startTime : 0)

    const currentRoundTimestamp = toMilliseconds(
      Math.round(currentTime - offset) + startDate
    )
    const currentTimestamp = Math.round(
      toMilliseconds(startDate + currentTime - offset)
    )
    const isData = Object.keys(egoData)
      .slice(0, 400)
      .some((x) => +x === currentRoundTimestamp)

    if (!isData && !isFetching) {
      setIsFetching(true)
      setTime(currentTime)
    }

    mainLoop(currentTimestamp, currentTime, offset)
  }

  useActiveTrial(handleTimeChange)

  useEffect(() => {
    if (!scene || !optionsData) {
      return
    }

    scene.traverse((obj) => {
      if (obj instanceof Line) {
        if (obj.name.includes('otto') || obj.name.includes('me')) {
          const { lane, source } = optionsData as TopDownData
          setLaneVisibility(obj, source, lane)
        }
      }
    })
  }, [optionsData, scene])

  const updateSize = useCallback(() => {
    if (!canvasContainerRef.current || !camera || !webGLRenderer || !label) {
      return
    }

    const width = canvasContainerRef.current.clientWidth
    const height = canvasContainerRef.current.clientHeight

    webGLRenderer.setSize(width, height)
    label.setSize(width, height)
    camera.aspect = width / height

    setCanContSize([width, height])
    setLabel(label)

    camera.updateProjectionMatrix()
  }, [camera, label, webGLRenderer])

  useEffect(() => {
    if (canvasContainerRef !== null && canvasContainerRef.current) {
      setCanContSize([
        canvasContainerRef.current.clientHeight,
        canvasContainerRef.current.clientWidth,
      ])
    }

    window.addEventListener('resize', updateSize)
    return () => {
      window.removeEventListener('resize', updateSize)
    }
  }, [updateSize])

  const drawGroundTruth = (position: number[], name: string) => {
    if (position.length === 0 || !scene) {
      return
    }

    const geometry = new BufferGeometry()
    geometry.setAttribute('position', new Float32BufferAttribute(position, 3))
    const material = new LineBasicMaterial({
      ...commonLineMaterial,
      color: new Color(yellowColor),
    })
    const lane = new Line(geometry, material)

    const laneName = `otto-${name}`
    lane.name = laneName

    removeObjectsByNameFromScene(scene, [`otto-${name}`])
    scene.add(lane)
  }

  const drawAdjacentGroundTruth = (position: number[], name: string) => {
    if (position.length === 0 || !scene) {
      return
    }

    const geometry = new BufferGeometry()
    geometry.setAttribute('position', new Float32BufferAttribute(position, 3))
    const material = new LineBasicMaterial({
      ...commonLineMaterial,
      color: new Color(ottoAdjacentColor),
    })
    const lane = new Line(geometry, material)

    const laneName = `otto-adjacent-${name}`
    lane.name = laneName

    removeObjectsByNameFromScene(scene, [`otto-adjacent-${name}`])
    scene.add(lane)
  }

  const initializeMeLines = (scena: Scene) => {
    if (scena && !leftMeLine && !rightMeLine) {
      modifyMeLine(
        [5, 1, 2, 3],
        new Color(blueColor),
        'me-left',
        scena,
        setLeftMeLine,
        leftMeLine
      )
      modifyMeLine(
        [-5, 1, 2, 3],
        new Color(blueColor),
        'me-right',
        scena,
        setRightMeLine,
        rightMeLine
      )
    }
    if (scena && !leftMeAdjacentLine && !rightMeAdjacentLine) {
      modifyMeLine(
        [0, 1, 2, 3],
        new Color(meAdjacentColor),
        'me-leftAdjacent',
        scena,
        setLeftMeAdjacentLine,
        leftMeAdjacentLine
      )
      modifyMeLine(
        [0, 1, 2, 3],
        new Color(meAdjacentColor),
        'me-rightAdjacent',
        scena,
        setRightMeAdjacentLine,
        rightMeAdjacentLine
      )
    }
  }

  useEffect(() => {
    if (!scene) {
      return
    }

    if (egoData) {
      drawData(egoData, drawGroundTruth)
    }

    if (adjacentData) {
      drawData(adjacentData, drawAdjacentGroundTruth)
    }
    // When we get egoData and adjacentData, we start drawing otto lines
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [egoData, adjacentData, scene])

  useEffect(() => {
    if (!camera || !webGLRenderer || !scene) {
      return
    }

    const ctrl = setupOrbitControls(camera, webGLRenderer)
    setControls(ctrl, viewportId)

    // LIGHTS! ACTION! CAMERA! ROLL!
    webGLRenderer.setAnimationLoop(() => {
      if (ctrl.enabled) {
        ctrl.update()
      }
      webGLRenderer.render(scene, camera)
      label.render(scene, camera)

      const canvasContainer = canvasContainerRef.current
      if (canvasContainer) {
        canvasContainerRef.current!.appendChild(label.domElement)
      }
    })

    // When there is camera and engine, create Orbit Controls
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [camera, webGLRenderer, scene, label])

  const handleLoadScene = (node: HTMLCanvasElement) => {
    if (node && canContSize && !webGLRenderer && !scene && egoData) {
      const {
        camera,
        glEngine,
        scene: scena,
      } = initializeEngineAndScene(node, canContSize, label)

      setWebGLRenderer(glEngine)
      setScene(scena)
      initializeMeLines(scena)
      setCamera(camera, viewportId)
    }
  }

  return (
    <div
      className='canvas-3d'
      ref={(node) => {
        if (!canContSize && node !== null) {
          setCanContSize([node.clientHeight, node.clientWidth])
          canvasContainerRef.current = node
        }
      }}
    >
      {activeVideoDTID && sampler && (
        <View3DBuffer
          time={time}
          scene={scene}
          setEgoData={setEgoData}
          setAdjacentData={setAdjacentData}
          setIsFetching={setIsFetching}
          synchronizer={synchronizer}
          viewportId={viewportId}
        />
      )}
      {!scene && !egoData && !adjacentData && (
        <>
          {!isFetching ? (
            <NoData languageCode='enUS' />
          ) : (
            <div className='loader-container'>
              <Loader text='Loading 3D component' />
            </div>
          )}
        </>
      )}

      <>
        {tableFrame !== undefined &&
          tableFrame.map((x) => (
            <DataTable3D key={x.TagID} isFullscreen={isFullscreen} data={x} />
          ))}
        <canvas
          ref={(node) => node && handleLoadScene(node)}
          style={{
            width: '100%',
            height: '100%',
          }}
        />
      </>
    </div>
  )
}
