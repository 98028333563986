import { useEffect, useImperativeHandle, useState } from 'react'
import { Stack } from '@mui/material'
import { Field, RuleGroupType } from 'react-querybuilder'
import { getDropdownOptions } from './queryBuilderUtils'
import StyledQueryStringWrapper from './StyledDiv'
import { sortBetween } from './utils'
import {
  INIT_FILTER_STATE,
  INIT_QUERY_BUILDER_STATE,
} from '../../pages/Report/initialStates'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { ReportType } from '../../pages/Report/types'
import QueryBuilder from '../QueryBuilder/QueryBuilder'
import {
  StyledApplyBtn,
  StyledBtnContainer,
  StyledResetBtn,
} from '../StyledComponents/buttons'
import { formatQuerySql } from '../Toolbox/utils'
import { PROJECT_IDS } from '../../details/config'
import { getProjectID } from '../../storage/projectIdStorage'

function QueryBuilderSelector({ reportType }: { reportType: ReportType }) {
  const {
    searchColumns,
    preserveState,
    queryBuilderRef,
    setPreserveState,
    setSelectedRowIds,
  } = useReportContext()
  const [query, setQuery] = useState(
    preserveState[reportType].queryBuilderState
  )
  const projectID = +(getProjectID() || 0)
  const reportTypes = ['characterization', 'quality', 'lanes']
  const excludedFields = [
    'GpsSpeed_Kph',
    'Geo_Altitude',
    'Geo_Country_CompoundLabel',
    'Drive Trial Identification_DTID',
    'Drive Trial Identification_ParentDTID',
    'Drive Trial Identification_IngestSource',
    'Drive Trial Identification_Description',
    'Drive Trial Identification_Version',
    'StartDate',
    'EndDate',
    'Vehicle',
    'DateProcessed',
    '(EndDate - StartDate)',
    'Uniqueness_Absolute_CompoundLabel',
    'Uniqueness_Incremental_CompoundLabel',
  ]

  useEffect(() => {
    setQuery(preserveState[reportType].queryBuilderState)
    //If querybuilder is open, we need to refresh the data inside the state when the reportType changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType])

  //expose handleReset function to main context so we can refresh the selection from other components
  useImperativeHandle(queryBuilderRef, () => ({ resetQuery: handleReset }), [])

  const isDisabled = query?.rules?.length === 0
  const options: Field[] | undefined =
    getDropdownOptions(searchColumns ?? [], reportType) ?? []

  const filteredOptions = options.filter(
    (obj) =>
      !(
        (projectID === PROJECT_IDS.VOLVO &&
          (obj['label'] === 'PARENTDTID' ||
            obj['label'] === 'DRIVE TRIAL IDENTIFICATION_PARENTDTID')) ||
        obj['label'].startsWith('LATERAL')
      )
  )

  const isExcluded = (field: string) =>
    excludedFields.some((excludedField) => excludedField.includes(field))

  const updateQuery: any = (query: RuleGroupType) => ({
    ...query,
    rules: query.rules.map((x) => {
      if (
        reportTypes.includes(reportType) &&
        'field' in x &&
        !isExcluded(x.field)
      ) {
        return { ...x, value: (x.value / 100).toFixed(3) }
      }
      if ('rules' in x) return updateQuery(x)
      if (
        'operator' in x &&
        (x.operator === 'between' || x.operator === 'notBetween')
      ) {
        const sorted = sortBetween(x)
        return {
          ...x,
          value: sorted,
        }
      }

      return x
    }),
  })

  const handleQuery = () => {
    const queryRulesModified = updateQuery(query)
    setPreserveState({
      ...preserveState,
      [reportType]: {
        ...preserveState[reportType],
        queryBuilderState: query,
        searchType: 'sql',
        clause: `WHERE ${formatQuerySql(queryRulesModified)}`,
        filterState: INIT_FILTER_STATE,
        filterToSql: {},
      },
    })
    setSelectedRowIds(new Set())
  }

  const handleReset = () => {
    setPreserveState({
      ...preserveState,
      [reportType]: {
        ...preserveState[reportType],
        queryBuilderState: INIT_QUERY_BUILDER_STATE,
        searchType: 'sql',
        clause: `WHERE ${formatQuerySql(INIT_QUERY_BUILDER_STATE)}`,
      },
    })
    setQuery(INIT_QUERY_BUILDER_STATE)
    setSelectedRowIds(new Set())
  }

  return (
    <Stack spacing={4} direction='column'>
      <QueryBuilder
        fields={filteredOptions}
        query={query!}
        setQuery={setQuery}
      />
      <StyledQueryStringWrapper>
        <p style={{ marginTop: 0 }}>Sql query:</p>
        {isDisabled ? <i>Empty</i> : formatQuerySql(query!)}
      </StyledQueryStringWrapper>
      <StyledBtnContainer spacing={1} direction='column'>
        <StyledApplyBtn
          onClick={handleQuery}
          variant='contained'
          fullWidth
          disabled={isDisabled}
        >
          Apply
        </StyledApplyBtn>
        <StyledResetBtn onClick={handleReset} variant='contained' fullWidth>
          Reset
        </StyledResetBtn>
      </StyledBtnContainer>
    </Stack>
  )
}

export default QueryBuilderSelector
