import { useState } from 'react'
import VideoViewportLoader from './VideoViewportLoader'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { LoadingProvider } from '../../details'
import { ObjectColor } from '../../details/types'
import { CanvasLayers } from '../CanvasLayers/CanvasLayers'
import VideoPlayerReact from '../VideoPlayerReact/VideoPlayerReact'
import { useActiveTrial } from '../../store/hooks/useActiveTrial'
import { createVideoPath, useVideoUrlQuery } from '../../api'

interface VideoWithCanvasProps {
  playerId: string
  colors: ObjectColor[]
  viewportId: number
  isFullscreen: boolean
  title: string
  synchronizer?: ISynchronizer
  videoName: string
}

export function VideoWithCanvas({
  playerId,
  colors,
  viewportId,
  isFullscreen,
  title,
  synchronizer,
  videoName,
}: VideoWithCanvasProps) {
  const { activeTrial } = useActiveTrial()

  const { data: urlData } = useVideoUrlQuery(
    createVideoPath(activeTrial.parentDTID, videoName ?? '')
  )

  const [canvasSize, setCanvasSize] = useState({
    width: 0,
    height: 0,
  })

  return (
    <LoadingProvider title={title}>
      <div
        style={{
          position: 'absolute',
          zIndex: 10,
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          whiteSpace: 'nowrap',
        }}
      >
        <VideoViewportLoader />
      </div>

      <VideoPlayerReact
        url={urlData?.url}
        videoId={activeTrial!.id}
        synchronizer={synchronizer}
        isFullscreen={isFullscreen}
        setCanvasSize={setCanvasSize}
        playerId={playerId}
        viewportId={viewportId}
      />
      {!title.includes('Basler') && (
        <CanvasLayers
          isFullscreen={isFullscreen}
          canvasSize={canvasSize}
          colors={colors}
          DTID={activeTrial.DTID}
          parentDTID={activeTrial.parentDTID!}
          viewportId={viewportId}
          synchronizer={synchronizer}
          playerId={playerId}
          videoId={activeTrial!.id}
        />
      )}
    </LoadingProvider>
  )
}
