import React from 'react'
import { Typography } from '@mui/material'
import { getClientID } from '../../storage/clientIdStorage'
import './style.scss'

interface IProps {
  children: React.ReactElement | React.ReactElement[]
  title?: React.ReactElement | string
  isFullscreen: boolean
  isViewportStyled?: boolean
  viewportContentTitle?: boolean
}

export const Viewport = ({
  title,
  children,
  isFullscreen,
  isViewportStyled = false,
  viewportContentTitle = false,
}: IProps) => {
  const clientID = getClientID()

  return (
    <div
      style={
        isViewportStyled
          ? {
              display: 'flex',
              justifyContent: 'right',
              paddingRight: 0,
            }
          : { gridColumnStart: clientID === '102' ? 2 : '' }
      }
      className={`viewport ${isViewportStyled ? '' : 'no-align'} ${isFullscreen ? 'isFullscreen' : ''}`}
    >
      {title && (
        <div
          className={
            viewportContentTitle ? 'viewport-content-title' : 'viewport-title'
          }
        >
          {typeof title === 'string' && !viewportContentTitle ? (
            <Typography>{title}</Typography>
          ) : (
            title
          )}
        </div>
      )}
      {children}
    </div>
  )
}
