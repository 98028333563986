import { Button, Divider, DividerOwnProps, styled } from '@mui/material'

export const StyledLoginBtn = styled(Button)(() => ({
  marginTop: '0px !important',
  height: '40px',
  backgroundColor: '#3C90FF',
  fontFamily: 'Inter',
  fontWeight: 500,
  fontSize: '14px',
  '&:hover': {
    backgroundColor: '#3366cc',
  },
  '&:active': {
    backgroundColor: '#00468B',
  },
}))

interface DividerProps extends DividerOwnProps {
  textcolor: string
  linecolor: string
}

export const StyledDivider = styled(Divider)(
  ({ textcolor, linecolor }: DividerProps) => ({
    margin: '10px 0 !important',
    fontFamily: "'Inter', sans-serif;",
    color: textcolor,
    '&:after': {
      borderTop: `thin solid ${linecolor}`,
    },
    '&:before': {
      borderTop: `thin solid ${linecolor}`,
    },
  })
)
