import { UseMutateAsyncFunction } from '@tanstack/react-query'
import { AxiosResponse } from 'axios'
import { IdType } from 'otto-vis-timeline/types'
import { commonTagStyle, commonNotationStyle, timelineTagId } from './common'
import { EditTagRequest, Tag } from '../../../api'
import { DriveTrial, HighlightMode, ITimelineContext } from '../../../details'
import { toMilliseconds } from '../../../utils'
import { SignDataResponse } from '../../TopDown3D_V1_Copy/types'
import { applyNonLinearTransformation } from '../TimelineHighlightMode/timelineHighlight'
import { JiraIssueType, TimelineItemData } from '../types'
import { calculateOriginalTime, jiraStatusUpdate } from '../utils'

export const hideShow = (timelineCtx: ITimelineContext, groupId: number) => {
  timelineCtx.items.forEach((x) => {
    if (x.group === groupId && x.id.toString().includes('tag')) {
      const commonStyle =
        x.end !== undefined ? commonNotationStyle : commonTagStyle
      if (!x.style?.includes('visibility')) {
        x.style = commonStyle + ' visibility: hidden;'
      } else {
        x.style = commonStyle
      }
    }
  })

  const updatedZIndexItems = updateZIndex(timelineCtx.items.get())
  timelineCtx.items.update(updatedZIndexItems)
}

const getTagNote = (
  tagData?: Tag,
  dtid?: number,
  signsData?: SignDataResponse
) => {
  let defaultNote = ''
  if (!tagData || !dtid) return defaultNote

  if (signsData && signsData[dtid]) {
    defaultNote = tagData?.note || defaultNote

    const matchingData = Object.values(signsData[dtid]).find(
      (ts) => ts.StartTimestamp === tagData.startTimestamp && ts.Note
    )

    if (matchingData) {
      defaultNote += '<br> [' + matchingData.Note + ']'
    }

    return defaultNote
  }
  return tagData.note ?? defaultNote
}

export const addTagToGroup = (
  time: number,
  selectedGroup: IdType,
  timelineCtx: ITimelineContext,
  highlightMode: HighlightMode,
  setHighlightMode: React.Dispatch<React.SetStateAction<HighlightMode>>,
  tagData?: Tag,
  dtid?: number,
  driveTrial?: DriveTrial,
  signsData?: SignDataResponse
) => {
  if (!driveTrial || !dtid || driveTrial.parentDTID !== dtid) {
    return
  }

  if (highlightMode.id === -1) {
    const offsetTime = toMilliseconds(driveTrial.previousDuration)

    let numberOfTags = 0
    timelineCtx.items.forEach((x) => {
      if ((x.id as string).includes(timelineTagId)) {
        ++numberOfTags
      }
    })

    const tagId = tagData?.id ? tagData.id : numberOfTags

    const timelineItem: TimelineItemData = {
      id: `${timelineTagId}-${tagId}`,
      content: '<div></div>',
      title: getTagNote(tagData, dtid, signsData),
      start: time + offsetTime,
      group: selectedGroup,
      isItem: false,
      className: 'testIdTag ' + commonTagStyle,
      type: 'box',
      style: 'z-index: 100000',
    }
    const itemsArr = timelineCtx.items.map((x) => x)
    itemsArr.push(timelineItem)
    timelineCtx.items.update(itemsArr)
    timelineCtx.initialItems.update(
      itemsArr.filter((i) => !i.id.toString().includes('loader'))
    )
    timelineCtx.originalItems.update(
      itemsArr.filter((i) => !i.id.toString().includes('loader'))
    )
  } else {
    let numberOfTags = 0
    timelineCtx.items.forEach((x) => {
      if ((x.id as string).includes(timelineTagId)) {
        ++numberOfTags
      }
    })

    const timelineItem: TimelineItemData = {
      id: `${timelineTagId}-${numberOfTags}`,
      content: '<div></div>',
      title: getTagNote(tagData, dtid, signsData),
      start: calculateOriginalTime(time, highlightMode),
      group: selectedGroup,
      isItem: false,
      className: 'testIdTag ' + commonTagStyle,
      type: 'box',
      style: 'z-index: 100000',
    }

    const itemsArr = timelineCtx.items.map((x) => x)
    itemsArr.push(timelineItem)
    timelineCtx.items.update(itemsArr)
    timelineCtx.initialItems.update(
      itemsArr.filter((i) => !i.id.toString().includes('loader'))
    )
    timelineCtx.originalItems.update(
      itemsArr.filter((i) => !i.id.toString().includes('loader'))
    )

    setHighlightMode({
      ...highlightMode,
      id: highlightMode.id,
      items: applyNonLinearTransformation(
        timelineCtx.originalItems,
        highlightMode.id,
        5,
        'time'
      ),
      sortBy: 'time',
      minLength: 5,
    })
  }
}

const updateZIndex = (array: TimelineItemData[]): TimelineItemData[] => {
  return array
    .map((item) => {
      let duration = 0

      switch (true) {
        case (item.id as string).endsWith('placeholder'):
        case item.className === 'background':
        case item.className === 'spinner':
        case item.className === 'exclusion-background':
          duration = Infinity
          break
        case (item.id as string).startsWith('tag') &&
          item.className !== 'notations':
        case item.className === 'spinner error':
          duration = 0
          break
        case item.isItem:
          duration = Math.floor(
            new Date(item.end!).getTime() / 1000 -
              new Date(item.start).getTime() / 1000
          )
          break
        case item.className === 'notations':
          duration = 1
          break
        default:
          duration = 0
          break
      }

      return { ...item, duration }
    })
    .sort((a, b) => b.duration - a.duration)
    .map((item, index) => {
      if (item.className === 'notations') {
        index = index + 100000
      }
      return {
        ...item,
        style: item.style
          ? item.style.replace(/z-index:\s*\d+/, `z-index: ${index}`)
          : `z-index: ${index}`,
      }
    })
}

const updateTimelineItems = (
  timelineCtx: ITimelineContext,
  timelineItem: TimelineItemData
) => {
  const itemsArr = timelineCtx.items.map((x) => x)
  itemsArr.push(timelineItem)

  const updatedZIndexItems = updateZIndex(itemsArr)
  timelineCtx.items.update(updatedZIndexItems)
  timelineCtx.initialItems.update(updatedZIndexItems)
  timelineCtx.originalItems.update(updatedZIndexItems)
}

export const addNotationToGroup = (
  startTime: number,
  endTime: number,
  selectedGroup: IdType,
  timelineCtx: ITimelineContext,
  highlightMode: HighlightMode,
  tagData?: Tag,
  dtid?: number,
  driveTrial?: DriveTrial,
  editTagMutation?: UseMutateAsyncFunction<
    AxiosResponse<any, any>,
    Error,
    EditTagRequest,
    unknown
  >
) => {
  if (!driveTrial || !dtid || driveTrial.DTID !== dtid) {
    return
  }

  if (startTime > endTime) {
    const s = endTime
    endTime = startTime
    startTime = s
  }

  const offsetTime = toMilliseconds(driveTrial.previousDuration)

  let numberOfTags = 0
  timelineCtx.items.forEach((x) => {
    if ((x.id as string).includes(timelineTagId)) {
      ++numberOfTags
    }
  })

  const tagId = tagData?.id ? tagData.id : numberOfTags

  if (highlightMode.id === -1) {
    const timelineItem: TimelineItemData = {
      id: `${timelineTagId}-${tagId}`,
      content: '<div><div/>',
      title: getTagNote(tagData, dtid),
      start: startTime + offsetTime,
      end: endTime + offsetTime,
      group: selectedGroup,
      className: 'notations',
      isItem: false,
      summary: tagData?.summary,
      issueType: tagData?.issueType as JiraIssueType,
      jiraID: tagData?.jiraID,
      team: tagData?.team,
      status: '',
      style:
        'height: 18px; background-color: #D3D3D3; border-color: black; z-index: 0;',
      type: 'range',
    }

    updateTimelineItems(timelineCtx, timelineItem)

    if (tagData?.jiraID) {
      jiraStatusUpdate(
        tagData.jiraID,
        timelineCtx,
        tagData?.id,
        editTagMutation!
      )
    }
  } else {
    highlightMode.items.forEach((item) => {
      if (
        item.originalStart &&
        startTime >= +item.start &&
        endTime <= +item.end!
      ) {
        const calculatedStart = item.originalStart + (startTime - +item.start)
        const calculatedEnd = calculatedStart + (endTime - startTime)
        const timelineItem: TimelineItemData = {
          id: `${timelineTagId}-${tagId}`,
          content: '<div><div/>',
          title: getTagNote(tagData, dtid),
          start: calculatedStart + offsetTime,
          end: calculatedEnd + offsetTime,
          group: selectedGroup,
          className: 'notations',
          isItem: false,
          summary: tagData?.summary,
          issueType: tagData?.issueType as JiraIssueType,
          jiraID: tagData?.jiraID,
          team: tagData?.team,
          status: '',
          style:
            'height: 18px; background-color: #D3D3D3; border-color: black; z-index: 0;',
          type: 'range',
        }

        updateTimelineItems(timelineCtx, timelineItem)
      }
    })

    const highlightTimelineItem: TimelineItemData = {
      id: `${timelineTagId}-${tagId}`,
      content: '<div><div/>',
      title: getTagNote(tagData, dtid),
      start: startTime,
      end: endTime,
      group: selectedGroup,
      className: 'notations',
      isItem: false,
      summary: tagData?.summary,
      issueType: tagData?.issueType as JiraIssueType,
      jiraID: tagData?.jiraID,
      team: tagData?.team,
      status: '',
      style:
        'height: 18px; background-color: #D3D3D3; border-color: black; z-index: 100000;',
      type: 'range',
    }

    highlightMode.items.update(highlightTimelineItem)
  }
}
