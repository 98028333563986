import {
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { enqueueSnackbar } from 'notistack'
import { useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { getDefaultGridOption } from './detailsUtils'
import { urls, useClientViewportMenuQuery } from '../../api'
import { DetailViewport } from '../../components/DetailViewport/DetailViewport'
import { GridLayout } from '../../components/GridLayout/GridLayout'
import Header from '../../components/Header/Header'
import { buildLayoutData } from '../../components/LayersPanel/utils'
import { TransportControls } from '../../components/TransportControls/TransportControls'
import { createUrl } from '../../components/VideoTimeline/utils'
import { enUS } from '../../constants'
import { layerColorsData } from '../../constants/layerColorsData'
import { Synchronizer } from '../../dataStructure/synchronizer/synchronizer'
import {
  DriveTrialDataProvider,
  TimelineContextProviderControlled,
  TopViewProvider,
  useDriveTrialContext,
} from '../../details'
import { MediaSyncContext, ViewportContent } from '../../details/types'
import { getClientID } from '../../storage/clientIdStorage'
import {
  viewportContentChange,
  viewportNameChange,
} from '../../store/details/viewport/viewportSlice'
import { getViewportLocalStorageState } from '../../store/details/viewportStateUtils'
import {
  GridOption,
  gridOptions,
} from '../../ui_toolkit/GridSelectors/GridSelectors'
import './style.scss'
import { useProjectData } from '../../api/table/projects'
import { viewportChange } from '../../store/details/viewportData/viewportDataSlice'
import { JUMP_FRAME_TIME_OFFSET } from '../../components/TransportControls/utils'

interface ExtendedMediaTrackConstraints extends MediaTrackConstraints {
  cursor?: 'always' | 'motion' | 'never'
}

const pauseVideoViewports = (synchronizer: Synchronizer | undefined) => {
  const viewports = getViewportLocalStorageState()
  const DTIDs: number[] = []
  Object.entries(viewports!).forEach(([DTID, value]) => {
    if (value.content === 1) {
      DTIDs.push(+DTID)
    }
  })
  DTIDs.forEach((viewport) =>
    synchronizer?.updateStatus(viewport, false, () => {})
  )
}

export const DetailsBase = () => {
  const { modeKey, synchronizerRef, redirectData } = useDriveTrialContext()
  const mediaSyncContext = useContext(MediaSyncContext)
  const screenshotRef = useRef<HTMLDivElement>(null)
  const dispatch = useDispatch()
  const clientID = getClientID()
  const [searchParams, setSearchParams] = useSearchParams()
  const time = searchParams.get('time')
  const frameId = searchParams.get('frameId')
  const initialLayer: GridOption = getDefaultGridOption(clientID)
  const [grid, setGrid] = useState<GridOption>(initialLayer)
  const [synchronizer, setSynchronizer] = useState<Synchronizer>()
  const layerPanelData = buildLayoutData(layerColorsData)
  const { data: menuOptions = [] } = useClientViewportMenuQuery()
  const [fullscreenId, setFullscreenId] = useState<number | null>(null)
  const { sectionLength, frameRate } = useProjectData()

  useImperativeHandle(
    synchronizerRef,
    () => ({
      pauseVideos: () => pauseVideoViewports(synchronizer),
    }),
    [synchronizer]
  )

  const handleCaptureScreenshot = async (jiraID: string) => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: { cursor: 'always' } as ExtendedMediaTrackConstraints,
      })

      enqueueSnackbar({
        message: enUS.CHOOSE_WINDOW,
        variant: 'info',
        autoHideDuration: 4000,
      })

      await new Promise((resolve) => setTimeout(resolve, 5000))

      const video = document.createElement('video')
      video.srcObject = stream

      await new Promise<void>((resolve) => {
        video.onloadedmetadata = () => {
          video.play()
          resolve()
        }
      })

      const canvas = document.createElement('canvas')
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      const context = canvas.getContext('2d')
      context!.drawImage(video, 0, 0, canvas.width, canvas.height)

      stream.getTracks().forEach((track) => track.stop())

      canvas.toBlob(async (blob) => {
        if (blob) {
          await uploadScreenshot(blob, jiraID)
        }
      }, 'image/png')
    } catch (error) {
      console.error('Screenshot capture failed:', error)
    }
  }

  const uploadScreenshot = async (blob: Blob, jiraID: string) => {
    const formData = new FormData()
    formData.append('file', blob, 'screenshot.png')

    try {
      const response = await fetch(
        process.env.REACT_APP_URL_BACKEND_WEB +
          createUrl(urls.captureScreenshot, jiraID),
        {
          method: 'POST',
          body: formData,
        }
      )
      await response.json().then(() =>
        enqueueSnackbar({
          message: enUS.UPLOAD_SUCCESSFULL,
          variant: 'info',
          autoHideDuration: 3000,
        })
      )
    } catch (error) {
      console.error('Upload failed:', error)
    }
  }

  useEffect(() => {
    let newTime = null

    if (frameId) {
      newTime = Number(frameId) / frameRate + JUMP_FRAME_TIME_OFFSET
    } else {
      const { sendToDetailsData } = redirectData.rows[0]
      newTime = time ? +time : 0

      if (sendToDetailsData && newTime === 0) {
        const { sectionId, detailId } = sendToDetailsData
        newTime += sectionId ? sectionId * sectionLength : 0
        newTime += detailId ? detailId * 5 : 0
      }
    }

    if (newTime && mediaSyncContext && mediaSyncContext.timingObj) {
      mediaSyncContext.isSeeking = true
      mediaSyncContext.timingObj.update({
        velocity: 0,
        position: newTime,
      })
    }
  }, [
    frameId,
    frameRate,
    mediaSyncContext,
    redirectData.rows,
    sectionLength,
    setSearchParams,
    time,
  ])

  useEffect(() => {
    setSynchronizer(new Synchronizer(grid.value, mediaSyncContext, 0))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const changeGrid = (option: GridOption) => {
    const viewportsLocalStorage = JSON.parse(
      localStorage.getItem('viewports') || '{}'
    )

    let foundSix = false

    const updatedData = { ...viewportsLocalStorage }

    for (const key in updatedData) {
      if (updatedData[key].content === ViewportContent.TIMELINE) {
        if (!foundSix) {
          foundSix = true
        } else {
          updatedData[key].content = ViewportContent.REAR_CAMERA
          dispatch(
            viewportContentChange({
              id: +key,
              content: updatedData[key].content,
            })
          )

          dispatch(
            viewportChange({
              id: +key,
              content: updatedData[key].content,
            })
          )
        }
      }
    }

    localStorage.setItem('viewports', JSON.stringify(updatedData))

    setGrid(option)
    dispatch(viewportNameChange(option.name))
  }

  return (
    <div ref={screenshotRef} className='dp-container'>
      <Header
        gridChangeHandler={changeGrid}
        fullscreen={fullscreenId !== null}
      />
      <TimelineContextProviderControlled>
        <TopViewProvider key={grid.name}>
          <GridLayout option={grid}>
            {gridOptions.map(({ name }, index) => (
              <DetailViewport
                key={name}
                colors={layerColorsData}
                layerPanelData={layerPanelData}
                id={index + 1}
                menuOptions={menuOptions}
                grid={grid}
                synchronizer={synchronizer}
                captureScreenshot={handleCaptureScreenshot}
                fullscreenId={fullscreenId}
                setFullscreenId={setFullscreenId}
              />
            ))}
          </GridLayout>
          <TransportControls key={modeKey} synchronizer={synchronizer} />
        </TopViewProvider>
      </TimelineContextProviderControlled>
    </div>
  )
}

export const Details = () => (
  <DriveTrialDataProvider>
    <DetailsBase />
  </DriveTrialDataProvider>
)
