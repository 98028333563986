import React, {
  useCallback,
  useContext,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import Box from '@mui/material/Box/Box'
import mapboxgl, { LngLatBounds } from 'mapbox-gl'
import { enqueueSnackbar } from 'notistack'
import Map, { MapMouseEvent } from 'react-map-gl'
import Button from '@mui/material/Button'
import GpsSource from './GpsSource'
import MarkerSource from './MarkerSource'
import PositionMarker from './PositionMarker'
import {
  DriveCoords,
  GpsMap,
  GpsMapData,
  GpsPoint,
  MapViewProps,
  StartEndMarker,
} from './types'
import {
  getNearestPoint,
  getNearestPointForDragging,
  generateHighlightCords,
  calculateBounds,
  drawStartEndMarkers,
  extractBackgroundColor,
  rgbaToHex,
} from './utils'
import { useMapQuery } from '../../api'
import { enUS } from '../../constants'
import { useDriveTrialContext, useTimelineContext } from '../../details'
import { MediaSyncContext } from '../../details/types'
import { getViewportLocalStorageState } from '../../store/details/viewportStateUtils'
import { Loader } from '../../ui_toolkit/Loader/Loader'
import { filterIndexes, isDevelopmentOrLocal, toSeconds } from '../../utils'
import NoData from '../NoData/NoData'
import './style.scss'
import { NavigationOutlined } from '@mui/icons-material'
import { Typography } from '@mui/material'
import { TimelineItemData } from '../VideoTimeline/types'
import { getMainItems } from '../VideoTimeline/TimelineHighlightMode/timelineHighlight'
import { useActiveTrial } from '../../store/hooks/useActiveTrial'

const initialZoom = 16

function MapView({
  mapRef,
  markerRef,
  synchronizer,
  viewportId,
  selectedDTID,
  mapOptionsRef,
  isFullscreen,
}: MapViewProps) {
  const timelineContext = useTimelineContext()
  const { timelineSettings, setShouldMoveMarker } = timelineContext
  const {
    highlightLength: timelineSettingsHighlightLength,
    sortBy: timelineSettingsSortBy,
  } = timelineSettings
  const [mainHighlightItems, setMainHighlightItems] =
    useState<TimelineItemData[]>()
  const coordinatesRef = useRef<HTMLSpanElement>(null)
  const mediaSyncContext = useContext(MediaSyncContext)
  const { getCurrentDriveTrial, getDriveTrialById, highlightMode, modeKey } =
    useDriveTrialContext()
  const [gpsCoordinates, setGpsCoordinates] = useState<GpsPoint[]>([])
  const [gpsHighCoordinates, setGpsHighCoordinates] = useState<GpsPoint[][]>([])
  const [gpsHighMapData, setGpsHighMapData] = useState<GpsMapData>({})
  const [gpsHighMap, setGpsHighMap] = useState<GpsMap>({})
  const [driveGps, setDriveGps] = useState<DriveCoords[]>([])
  const { data, isLoading, isError, isPending } = useMapQuery()
  const navigationArrow = process.env.PUBLIC_URL + '/nav-marker.svg'
  const [startEndMarkers, setStartEndMarkers] = useState<StartEndMarker[][]>([])
  const [mouseDown, setMouseDown] = useState(false)
  const [dragMap, setDragMap] = useState(true)
  const [mapBounds, setMapBounds] = useState<LngLatBounds>(
    new mapboxgl.LngLatBounds([0, 0], [0, 0])
  )
  const currentMapViewportState = getViewportLocalStorageState()
  const [showFollowButton, setShowFollowButton] = useState(false)
  const [followMode, setFollowMode] = useState(false)
  const [initialZoomFinished, setInitialZoomFinished] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isAlreadyPlayed, setIsAlreadyPlayed] = useState(false)
  const [, setActiveDriveID] = useState(0)

  const startMarkerPositionColor = 'rgba(0, 255, 0, 0.7)'
  const endMarkerPositionColor = 'rgba(255, 0, 0, 0.7)'
  const startEndMarkerDriveTrialBorder = '1px solid blue'
  const gpsPathActiveColor = {
    'line-color': `${
      highlightMode.id !== -1
        ? 'rgba(66, 133, 244, 0.5)'
        : 'rgba(66, 133, 244, 1)'
    }`,
    'line-width': 4,
  }
  const gpsPathColor = {
    'line-color': '#874EFE',
    'line-width': 2,
    'line-opacity': 0.9,
  }

  const gpsPathHighColor = {
    'line-color': '#FF0000',
    'line-width': 4,
  }
  const renderDevCoordinates = useCallback(
    (time: number) => {
      if (!coordinatesRef.current || !isDevelopmentOrLocal) return

      if (highlightMode.id !== -1) {
        const cords = gpsHighMap[Math.floor(time)]
        if (cords && markerRef.current && mapRef.current) {
          coordinatesRef.current.innerHTML = `${cords[1]}, ${cords[0]}`
        }
        return
      }

      const activeVideoDTID = mediaSyncContext.activeVideo
      const activeGps = driveGps.find((x) => x.key === activeVideoDTID)
      const activeVideoID = mediaSyncContext.activeVideoId
      const activeVideo = getDriveTrialById(activeVideoID!)

      if (activeGps && activeVideo) {
        const offset = activeVideo.previousDuration
        const seconds = Math.floor(time) - offset
        const [long, lat] = activeGps.coordinates[seconds]
        coordinatesRef.current.innerHTML = `${lat}, ${long}`
      }
    },
    [
      driveGps,
      getDriveTrialById,
      gpsHighMap,
      highlightMode.id,
      isDevelopmentOrLocal,
      mediaSyncContext.activeVideo,
      mediaSyncContext.activeVideoId,
    ]
  )

  const handlePosition = useCallback(
    (time: number) => {
      const roundPosition = Math.floor(time)
      const coords =
        highlightMode.id !== -1
          ? gpsHighMap[roundPosition]
          : gpsCoordinates[roundPosition]

      if (!gpsCoordinates.length || !mapRef.current || !coords) return

      const [longitude, latitude, speed, yaw] = coords

      if (highlightMode.id !== -1) {
        if (markerRef.current && mapRef.current) {
          markerRef.current.setPosition([longitude, latitude, speed, yaw])

          if (followMode) {
            mapRef.current.flyTo({
              center: [longitude, latitude],
              duration: 700 / mediaSyncContext.playbackSpeed,
            })
          }
        }
        return
      }

      const activeDrive = getCurrentDriveTrial(time)
      setActiveDriveID(activeDrive?.parentDTID || 0)
      const positionOffset = Math.floor(roundPosition - activeDrive!.startTime)

      const driveHasData = driveGps.find((x) => x.key === activeDrive?.DTID)
        ?.coordinates[positionOffset]

      if (driveHasData && markerRef.current) {
        markerRef.current.setPosition([longitude, latitude, speed, yaw])

        if (followMode) {
          mapRef.current.flyTo({
            center: [longitude, latitude],
            duration: 700 / mediaSyncContext.playbackSpeed,
          })
        }
      }
    },
    [
      driveGps,
      followMode,
      getCurrentDriveTrial,
      gpsCoordinates,
      gpsHighMap,
      highlightMode.id,
      mediaSyncContext.playbackSpeed,
    ]
  )

  const getCurrentCoordinate = useCallback(() => {
    if (!mediaSyncContext) {
      return [0, 0, 0, 0] as GpsPoint
    }

    const t = Math.floor(mediaSyncContext.timingObj!.pos as number)

    if (highlightMode.id !== -1) {
      const cords = gpsHighMap[Math.floor(t)]
      if (cords && markerRef.current && mapRef.current) {
        return [cords[0], cords[1], cords[2], cords[3]] as GpsPoint
      }
      return [0, 0, 0, 0] as GpsPoint
    }

    const lastElement =
      gpsCoordinates.length > 0
        ? gpsCoordinates[gpsCoordinates.length - 1]
        : [0, 0, 0, 0]

    const currentGPSCoordinate = gpsCoordinates[t]
    const curLongitude = currentGPSCoordinate
      ? currentGPSCoordinate[0]
      : lastElement[0]
    const curLatitude = currentGPSCoordinate
      ? currentGPSCoordinate[1]
      : lastElement[1]
    const curSpeed = currentGPSCoordinate
      ? currentGPSCoordinate[2]
      : lastElement[2]
    const curYaw = currentGPSCoordinate
      ? currentGPSCoordinate[3]
      : lastElement[3]

    return [curLongitude, curLatitude, curSpeed, curYaw] as GpsPoint
  }, [gpsCoordinates, gpsHighMap, highlightMode.id, mediaSyncContext])

  const calculateRotationAngle = useCallback(() => {
    const currentPosition = getCurrentCoordinate()
    const radians = currentPosition[3]
    return radians * (180 / Math.PI)
  }, [getCurrentCoordinate])

  useEffect(() => {
    const map = mapRef.current
    const img = new Image(20, 20)

    map?.on('styleimagemissing', (e) => {
      const eventWithId = e as typeof e & { id: string }
      if (eventWithId.id === 'navigationArrow') {
        map.addImage('navigationArrow', img)
      }
    })

    if (!map || map.hasImage('navigationArrow')) return

    img.onload = () => {
      if (map.hasImage('navigationArrow')) {
        map.removeImage('navigationArrow')
      } else {
        map.addImage('navigationArrow', img)
      }
    }
    img.src = navigationArrow

    if (!data?.map) return

    const cords = data.map.map(
      ({ longitude, latitude, speed, yaw }) =>
        [longitude, latitude, speed, yaw] as GpsPoint
    )
    if (gpsCoordinates.length > 0) {
      const gp = markerRef.current?.getPosition()
      gp && mapRef.current?.setCenter([gp[0], gp[1]])
      const bounds = calculateBounds(cords)
      setMapBounds(bounds)
      mapRef?.current?.getMap().fitBounds(bounds, {
        padding: { top: 60, bottom: 60, left: 60, right: 60 },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [gpsCoordinates])

  useImperativeHandle(
    mapOptionsRef,
    () => ({
      followMode,
      setFollowMode,
      showFollowButton,
      setShowFollowButton,
    }),
    [followMode, showFollowButton]
  )

  useEffect(() => {
    if (data?.map) {
      const cords = data.map.map(
        ({ longitude, latitude, speed, yaw }) =>
          [longitude, latitude, speed, yaw] as GpsPoint
      )

      if (highlightMode.id !== -1) {
        const highCords = highlightMode.items.map(
          ({ originalStart, originalEnd, start, dtid }) => ({
            originalStart: Math.floor(originalStart! / 1000),
            originalEnd: Math.floor(originalEnd! / 1000),
            start: Math.floor(toSeconds(start as number)),
            dtid: dtid!,
          })
        )
        const highCordsResult = generateHighlightCords(cords, highCords)
        setGpsHighCoordinates(highCordsResult.highlightCords)
        setGpsHighMap(highCordsResult.highMap)
        setGpsHighMapData(highCordsResult.highMapData)

        const items: TimelineItemData[] = []

        timelineContext.items.forEach((item) => {
          if (item.isItem) {
            items.push(item)
          }
        })

        const mainItems = getMainItems(
          items,
          highlightMode.id,
          timelineSettingsHighlightLength,
          timelineSettingsSortBy
        )

        setMainHighlightItems(mainItems)
      } else {
        setGpsHighCoordinates([])
        setGpsHighMap({})
      }

      const coordsPerDrive = data.map.reduce((acc, item) => {
        const existingDriveData = acc.find((x) => x.key === item.dtid)
        if (existingDriveData) {
          existingDriveData.coordinates?.push([
            item.longitude,
            item.latitude,
            item.speed,
            item.yaw,
          ])
        } else {
          acc.push({
            key: item.dtid,
            coordinates: [
              [item.longitude, item.latitude, item.speed, item.yaw],
            ],
            speeds: [item.speed],
            yaws: [item.yaw],
          })
        }
        return acc
      }, [] as DriveCoords[])

      setGpsCoordinates(cords)
      setDriveGps(coordsPerDrive)
      setStartEndMarkers(drawStartEndMarkers(coordsPerDrive))
    }
    // run on viewport change and on initial fetch, synchronizer is also
    // needed to prevent bloking video playing on `viewportId` change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.map, viewportId, synchronizer, highlightMode])

  const handleTimeChange = useCallback(
    (time: number) => {
      if (time !== undefined) {
        setIsPlaying(mediaSyncContext.isPlaying)
        renderDevCoordinates(time)
        handlePosition(time)
        markerRef.current?.setRotationAngle(calculateRotationAngle())
      }
    },
    [
      calculateRotationAngle,
      handlePosition,
      markerRef,
      mediaSyncContext.isPlaying,
      renderDevCoordinates,
    ]
  )

  useActiveTrial(handleTimeChange)

  useEffect(() => {
    if (isLoading || isPending) {
      synchronizer?.updateStatus(viewportId, false)
      return
    }

    synchronizer?.updateStatus(viewportId, true)
  }, [isLoading, isPending, synchronizer, viewportId])

  useEffect(() => {
    mapRef.current?.resize()
  }, [isFullscreen, mapRef])

  useEffect(() => {
    if (!mapRef.current) {
      return
    }

    mapRef.current.on('zoomend', () => {
      if (initialZoomFinished) {
        return
      }

      setFollowMode(false)
      setInitialZoomFinished(true)
    })
  }, [initialZoomFinished, mapRef])

  const onClick = (e: MapMouseEvent) => {
    const [nearestLongitude, nearestLatitude] = getNearestPoint(
      e,
      gpsCoordinates,
      Object.values(gpsHighMap)
    )
    if (nearestLatitude === null && nearestLongitude === null) return

    mediaSyncContext.isSeeking = true

    let gpsTime = 0
    if (highlightMode.id === -1) {
      const indexes = filterIndexes(
        gpsCoordinates,
        (point) => point[0] === nearestLongitude && point[1] === nearestLatitude
      )
      if (indexes.length > 0) {
        gpsTime = getPosition(indexes)
      }
    } else {
      const indexes = filterIndexes(
        Object.entries(gpsHighMap),
        (x) =>
          x[1] && x[1][0] === nearestLongitude && x[1][1] === nearestLatitude
      )
      if (indexes.length > 0) {
        gpsTime = getHighPosition(indexes)
      }
    }

    mediaSyncContext.timingObj?.update({
      position: +gpsTime,
      velocity: 0,
    })
  }

  const recenterMap = useCallback(() => {
    if (markerRef.current && mapRef.current) {
      const position = markerRef.current.getPosition()

      if (!position) {
        return
      }

      setFollowMode(false)
      setShowFollowButton(false)
      mapRef.current.stop()

      const handleMoveEnd = () => {
        setFollowMode(true)
        mapRef.current?.off('moveend', handleMoveEnd)
      }

      mapRef.current?.on('moveend', handleMoveEnd)

      mapRef.current.flyTo({
        center: [position[0], position[1]],
        zoom: initialZoom,
        bearing: 0,
        essential: true,
      })
    }
  }, [mapRef, markerRef])

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.stop()
    }

    mediaSyncContext.isPlaying = false
    setInitialZoomFinished(false)
    setShowFollowButton(false)
    setFollowMode(false)
    setIsAlreadyPlayed(false)
  }, [highlightMode])

  useEffect(() => {
    if (isAlreadyPlayed) {
      return
    }

    if (isPlaying) {
      if (mapRef.current?.isZooming) {
        mapRef.current.stop()
      }

      setIsAlreadyPlayed(true)
      recenterMap()
    }
  }, [isPlaying, isAlreadyPlayed, recenterMap, mapRef])

  const getPosition = (indexes: number[]) => {
    if (indexes.length === 1) return indexes[0]
    const gpsData = indexes.map((x) => ({ data: data?.map[x], index: x }))
    const selectedDT = gpsData.find((x) => x.data?.dtid === selectedDTID)
    return !selectedDT ? indexes[0] : selectedDT?.index
  }

  const getHighPosition = (indexes: number[]) => {
    if (indexes.length === 1) return indexes[0]
    const gpsData = indexes.map((x) => ({ data: gpsHighMapData[x], index: x }))
    const selectedDT = gpsData.find((x) => x.data?.dtid === selectedDTID)
    return !selectedDT ? indexes[0] : selectedDT?.index
  }

  if (isLoading) {
    return <Loader text='Loading map' center />
  }

  if (isError) {
    return <NoData languageCode='enUS' />
  }

  const handleMouseOver = (e: MapMouseEvent) => {
    const threshold = 10
    const markerPosition = markerRef?.current?.getPosition()
    const map = mapRef?.current?.getMap()

    if (!markerPosition || !map) return

    const markerPixel = map.project([markerPosition[0], markerPosition[1]])
    const mousePixel = [e.point.x, e.point.y]

    const distance = Math.sqrt(
      Math.pow(markerPixel.x - mousePixel[0], 2) +
        Math.pow(markerPixel.y - mousePixel[1], 2)
    )

    return distance < threshold
  }

  const handleMouseOverRoute = (e: MapMouseEvent) => {
    if (!gpsCoordinates.length) return
    const [lat, lng] = getNearestPoint(
      e,
      gpsCoordinates,
      Object.values(gpsHighMap)
    )
    const elementHtml = document.querySelector(
      '.mapboxgl-canvas-container.mapboxgl-interactive.mapboxgl-touch-drag-pan.mapboxgl-touch-zoom-rotate'
    ) as HTMLElement

    if (lat === null && lng === null && elementHtml) {
      elementHtml.style.cursor = 'grab'
      return
    }
    if (elementHtml) elementHtml.style.cursor = 'all-scroll'
  }

  const handleMouseDown = (e: MapMouseEvent) => {
    const isMouseOver = handleMouseOver(e) as boolean
    setFollowMode(false)
    setShowFollowButton(true)
    setDragMap(!isMouseOver)
    setMouseDown(isMouseOver)
  }

  const handleMouseUp = () => {
    setDragMap(true)
    setMouseDown(false)
    setShouldMoveMarker(true)
  }

  const handleDrag = (e: MapMouseEvent) => {
    if (dragMap || !mouseDown) return
    const [nearestLongitude, nearestLatitude] = getNearestPointForDragging(
      e,
      gpsCoordinates,
      Object.values(gpsHighMap)
    )
    if (nearestLatitude === null && nearestLongitude === null) return

    mediaSyncContext.isSeeking = true

    let gpsTime = 0
    if (highlightMode.id === -1) {
      gpsTime = gpsCoordinates.findIndex(
        (point) => point[0] === nearestLongitude && point[1] === nearestLatitude
      )
    } else {
      const key = Object.entries(gpsHighMap).find(
        (x) =>
          x[1] && x[1][0] === nearestLongitude && x[1][1] === nearestLatitude
      )

      if (key) gpsTime = key[0] as unknown as number
    }

    mediaSyncContext.timingObj?.update({
      position: +gpsTime,
      velocity: 0,
    })
  }

  const handleMapInteractions = (event: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalEvent: any
    type: string
  }) => {
    if (!event || !event.originalEvent) return

    const originalEvent = event.originalEvent

    if (
      (event.type === 'zoomstart' && originalEvent instanceof WheelEvent) ||
      (event.type === 'dragstart' && originalEvent instanceof MouseEvent) ||
      (event.type === 'rotatestart' && originalEvent instanceof MouseEvent)
    ) {
      setShowFollowButton(true)
      setFollowMode(false)
    }
  }

  const copyCoordinatesToClipboard = () => {
    if (coordinatesRef.current?.innerText) {
      navigator.clipboard
        .writeText(coordinatesRef.current?.innerText)
        .then(() => {
          coordinatesRef.current!.classList.add('copied')
          setTimeout(() => {
            if (coordinatesRef.current) {
              coordinatesRef.current.classList.remove('copied')
            }
          }, 1000)
          enqueueSnackbar({
            message: enUS.COORDINATES_COPIED_TO_CLIPBOARD,
            variant: 'info',
          })
        })
    }
  }

  return (
    <Box
      component='div'
      sx={{
        '& .mapboxgl-control-container': {
          display: 'none',
        },
        '& div[mapboxgl-children]': {
          display: 'none',
        },
        '& .mapboxgl-ctrl-attrib-button': {
          display: 'none',
        },
        position: 'relative',
        width: '100%',
      }}
    >
      {isDevelopmentOrLocal && (
        <span
          className='lat-lng-text'
          ref={coordinatesRef}
          onClick={copyCoordinatesToClipboard}
        />
      )}
      <Map
        key={modeKey}
        ref={mapRef}
        dragPan={dragMap}
        pitchWithRotate={false}
        onDragStart={(event) => {
          handleMapInteractions({ ...event })
        }}
        onRotateStart={(event) => {
          handleMapInteractions({ ...event })
        }}
        onZoomStart={(event) => {
          handleMapInteractions({ ...event })
        }}
        onClick={onClick}
        initialViewState={{
          longitude: (mapBounds._ne.lng + mapBounds._sw.lng) / 2,
          latitude: (mapBounds._ne.lat + mapBounds._sw.lat) / 2,
          pitch: 0,
        }}
        style={{
          position: 'absolute',
          width: '100%',
        }}
        keyboard={false}
        mapStyle={
          currentMapViewportState &&
          currentMapViewportState[viewportId].mapStyle === 'street'
            ? 'mapbox://styles/mapbox/streets-v11'
            : 'mapbox://styles/mapbox/satellite-v9'
        }
        mapboxAccessToken={process.env.REACT_APP_MAPBOX_TOKEN}
        onMouseMove={(e) => {
          handleMouseOver(e)
          handleMouseOverRoute(e)
          if (!dragMap && mouseDown) {
            handleDrag(e)
          }
        }}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
      >
        {driveGps.map((drive) => (
          <React.Fragment key={drive.key}>
            <GpsSource
              key={drive.key}
              driveKey={drive.key}
              paint={
                drive.key !==
                getDriveTrialById(mediaSyncContext.activeVideoId!)?.DTID
                  ? gpsPathColor
                  : gpsPathActiveColor
              }
              route={drive.coordinates}
            />
          </React.Fragment>
        ))}
        {gpsHighCoordinates.map((g, index) => {
          const highlightItem = mainHighlightItems![index]
          const paint = highlightItem
            ? {
                'line-color':
                  rgbaToHex(extractBackgroundColor(highlightItem.style)) ??
                  gpsPathHighColor['line-color'],
                'line-width': 4,
              }
            : gpsPathHighColor

          return (
            <GpsSource
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              driveKey={index}
              paint={paint}
              route={g}
            />
          )
        })}
        {startEndMarkers.map((markers) =>
          markers.map((marker, index) => (
            <PositionMarker
              key={marker.key}
              longitude={marker.lng}
              latitude={marker.lat}
              viewportId={viewportId}
              color={
                marker.position.split('-')[1] === 'end'
                  ? endMarkerPositionColor
                  : startMarkerPositionColor
              }
              anchor='bottom'
              title={
                marker.position.split('-')[1] === 'end'
                  ? enUS.END_DRIVE_TRAIL_ID
                  : enUS.START_DRIVE_TRAIL_ID
              }
              DTID={marker.key}
              offsetY={-25 * (index + 1)}
              activeBorder={
                +(
                  getDriveTrialById(mediaSyncContext.activeVideoId!)?.DTID || 0
                ) === marker.key
                  ? startEndMarkerDriveTrialBorder
                  : ''
              }
            />
          ))
        )}
        <MarkerSource
          icon={'navigationArrow'}
          initPosition={getCurrentCoordinate()}
          ref={markerRef}
        />
      </Map>
      {showFollowButton && (
        <Button
          startIcon={<NavigationOutlined />}
          onClick={recenterMap}
          variant='contained'
          size='small'
          sx={{
            textTransform: 'none',
            position: 'absolute',
            bottom: 30,
            right: 10,
          }}
        >
          <Typography fontSize={'0.9rem'}>Recenter</Typography>
        </Button>
      )}
    </Box>
  )
}

export default MapView
