import { useState } from 'react'
import AspectRatioIcon from '@mui/icons-material/AspectRatio'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import { Box, Button, ButtonGroup, Typography } from '@mui/material'
import { Column, Row } from '@tanstack/react-table'
import { useSnackbar } from 'notistack'
import { useLocation, useParams } from 'react-router-dom'
import { match } from 'ts-pattern'
import { VisibleColumns } from '../../../api/redirectionData'
import { CsvDownload } from '../../../components/CSVDownload/CSVDownload'
import { PdfDownload } from '../../../components/pdfDownload/pdfDownload'
import { TableRouteParams, enUS } from '../../../constants'
import { SendToDetailsData } from '../../../details/types'
import { Loader } from '../../../ui_toolkit/Loader/Loader'
import { StyledTooltip } from '../../../ui_toolkit/StyledTooltip/StyledTooltip'
import {
  ReportDepths,
  useReportContext,
} from '../providers/ReportContextProvider'
import { SelectedRow } from '../types'
import { sendToDetails, sendToDetailsKPI } from '../utils'

interface TopTableToolbarProps {
  selectedRowIds: Set<SelectedRow>
  visibleColumns: Column<any, any>[]
  bodyRows: Row<any>[]
  bodyData: any
}

export function TopTableToolbar({
  selectedRowIds,
  visibleColumns,
  bodyRows,
  bodyData,
}: TopTableToolbarProps) {
  const { reportType } = useParams<TableRouteParams>()
  const { pathname } = useLocation()
  const {
    sendToDetailsData,
    breadcrumbs,
    preserveState,
    setPage,
    setLimit,
    setBreadcrumbs,
    setSendToDetailsData,
  } = useReportContext()
  const [isLoading, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()
  const isKPI = pathname.includes('kpis')

  const isExpandingAvailable = () => {
    const reportExpandingDepth = ReportDepths[reportType!]
    return (
      selectedRowIds.size === 1 && breadcrumbs.length <= reportExpandingDepth
    )
  }

  const kpiVisibleColumns = visibleColumns
    .filter(
      (col) =>
        col.id !== 'select' &&
        col.id !== '1000' &&
        (!isKPI ? col.id !== '1001' : true) &&
        !col.columnDef.header?.toString().includes('Summary')
    )
    .filter((c) => !c.parent)
    .map(
      (parent) =>
        ({
          kpi: parent.columnDef.header?.toString(),
          items: parent.columns
            .filter(
              (leaf) =>
                !leaf.columnDef.header?.toString().includes('Recall') &&
                !leaf.columnDef.header?.toString().includes('Accuracy') &&
                leaf.getIsVisible()
            )
            .map((l) => l.columnDef.header?.toString()),
        }) as VisibleColumns
    )
    .filter((superHeader) => superHeader.items.length > 0)

  const handleRedirectionData = (
    minSectionId: number,
    detailsData: SendToDetailsData,
    selectedRowDTID: number
  ) => {
    match(breadcrumbs.length)
      .with(1, () => {
        detailsData.DTID = selectedRowDTID
        detailsData.sectionId = undefined
        detailsData.detailId = undefined
      })
      .with(2, () => {
        detailsData.sectionId = selectedRowDTID - minSectionId
        detailsData.detailId = undefined
      })
      .otherwise(() => {
        detailsData.detailId = selectedRowDTID
      })
  }

  const handleSendToDetails = async () => {
    const rows: Record<number, any>[] = Object.values(bodyData)
    const minSectionId = rows.reduce((prev, curr) =>
      prev[100000] < curr[100000] ? prev : curr
    )[100000]

    let result = true
    if (isKPI) {
      result = await sendToDetailsKPI(
        selectedRowIds,
        reportType!,
        setIsLoading,
        kpiVisibleColumns,
        preserveState
      )

      if (!result) {
        enqueueSnackbar({
          message: enUS.REDIRECTION_ERROR,
          variant: 'error',
        })
      }
      return
    }

    setIsLoading(true)

    const detailsData = {
      ...sendToDetailsData,
    }
    const selectedRowDTID = [...selectedRowIds][0].DTID

    handleRedirectionData(minSectionId, detailsData, selectedRowDTID)

    setSendToDetailsData(detailsData)

    result = await sendToDetails(
      detailsData,
      selectedRowIds,
      reportType!,
      setIsLoading,
      kpiVisibleColumns,
      breadcrumbs
    )

    if (!result) {
      enqueueSnackbar({
        message: enUS.REDIRECTION_ERROR,
        variant: 'error',
      })
    }
  }

  const handleDrillDown = () => {
    const selected = [...selectedRowIds][0].DTID
    const rows: Record<number, any>[] = Object.values(bodyData)
    const minSectionId = rows.reduce((prev, curr) =>
      prev[100000] < curr[100000] ? prev : curr
    )[100000]
    const data = rows.find((row: any) => row[100000] === selected) as object

    const detailsData = {
      ...sendToDetailsData,
    }
    const selectedRowDTID = [...selectedRowIds][0].DTID
    handleRedirectionData(minSectionId, detailsData, selectedRowDTID)

    setSendToDetailsData(detailsData)

    setPage(1)
    setLimit(50)
    setBreadcrumbs((prevState) => [
      ...prevState,
      {
        name: selected,
        data: {
          ...data,
          0: 'Total',
        },
      },
    ])
  }

  return (
    <Box display='flex' justifyContent='space-between'>
      <Box display='flex' justifyContent='space-between'>
        <div>
          <Typography
            variant='h6'
            sx={{ borderLeft: '2px solid white', color: 'white', pl: 1, mr: 4 }}
            textTransform='capitalize'
          >
            {reportType}
          </Typography>
        </div>
        <ButtonGroup size='medium' variant='text' sx={{ marginRight: '10px' }}>
          <StyledTooltip
            title={
              !reportType && kpiVisibleColumns.length === 0
                ? 'Choose KPI columns'
                : selectedRowIds.size === 0
                  ? 'Select rows'
                  : 'Send to details'
            }
            placement='top'
          >
            <span>
              <Button
                disabled={
                  (isKPI && kpiVisibleColumns.length === 0) ||
                  selectedRowIds.size === 0 ||
                  (breadcrumbs.length > 1 && selectedRowIds.size > 1) ||
                  reportType === 'completeness'
                }
                data-testid='sendToDetails'
                sx={{
                  textTransform: 'capitalize',
                  color: 'whitesmoke',
                  paddingRight: isLoading ? '48px' : '',
                }}
                onClick={handleSendToDetails}
              >
                Send to details
                {!isLoading ? (
                  <OpenInNewIcon sx={{ ml: 1 }} fontSize='small' />
                ) : (
                  <div style={{ position: 'absolute', right: 0 }}>
                    <Loader scale={0.25} />
                  </div>
                )}
              </Button>
            </span>
          </StyledTooltip>
          <StyledTooltip title='Drill down selected row' placement='top'>
            <span>
              <Button
                disabled={!isExpandingAvailable()}
                data-testid='openSelected'
                sx={{
                  textTransform: 'capitalize',
                  color: 'whitesmoke',
                }}
                onClick={handleDrillDown}
              >
                Open
                <AspectRatioIcon sx={{ ml: 1 }} fontSize='small' />
              </Button>
            </span>
          </StyledTooltip>
        </ButtonGroup>
      </Box>

      <Box>
        <CsvDownload visibleColumns={visibleColumns} bodyRows={bodyRows} />
        <PdfDownload visibleColumns={visibleColumns} bodyRows={bodyRows} />
      </Box>
    </Box>
  )
}
