import { forwardRef, useState } from 'react'
import { Button, Checkbox, Divider, FormControlLabel } from '@mui/material'
import camelCase from 'lodash/camelCase'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ILayerPanelItem } from './types'
import { isDisabledOption } from './utils'
import { ColorKeys, getColorName } from '../../constants'
import { EnabledLayers } from '../../details/types'
import { ReportType } from '../../pages/Report/types'
import {
  selectViewportsSynced,
  selectViewportState,
} from '../../store/details/viewportData/selectors'
import {
  layerDataChange,
  syncLayers,
} from '../../store/details/viewportData/viewportDataSlice'
import theme from '../../theme/theme'
import PopupOverlay from '../../ui_toolkit/PopupOverlay/PopupOverlay'
import './index.scss'

interface IProps {
  closeHandler: () => void
  blockStyle?: object
  data: ILayerPanelItem[]
  viewportId: number
}

const LayersPanel = forwardRef(function LayersPanel(props: IProps, ref) {
  const { reportType } = useParams()
  const { closeHandler, data, viewportId } = props
  const [activeTab, setActiveTab] = useState<number>(0)
  const activeTabData = data[activeTab] ?? {}
  const activeTabName = activeTab === 0 ? 'otto' : 'me'
  const enabledLayers = useSelector(
    selectViewportState(viewportId)
  ) as EnabledLayers
  const synced = useSelector(selectViewportsSynced())
  const dispatch = useDispatch()

  const handleChecboxClick = (optionName: string) => {
    const enabledLayersCopy = structuredClone(enabledLayers)

    const activeTabLayers = enabledLayersCopy[activeTabName]
    if (!activeTabLayers) {
      return
    }
    if (activeTabLayers.includes(optionName)) {
      const index = activeTabLayers.indexOf(optionName)
      enabledLayersCopy[activeTabName]?.splice(index, 1)
    } else {
      activeTabLayers.push(optionName)
    }

    dispatch(
      layerDataChange({
        data: enabledLayersCopy,
        id: viewportId,
      })
    )
  }

  const renderCategories = () => {
    const { categories } = activeTabData

    if (!enabledLayers) {
      return null
    }

    return (
      <div className={'layers-categories-container'}>
        {categories?.map((category, i) => {
          const { name, options } = category
          const isLastCategory = categories.length - 1 === i
          return (
            <div
              key={category.name}
              className={'layers-category-container'}
              style={{
                borderRight: !isLastCategory ? '1px white solid' : 'none',
              }}
            >
              <div className={'layers-category-name'}>{name}</div>
              <div className={'layers-options-container'}>
                {options?.map((option) => {
                  const isDisabled = isDisabledOption(
                    getColorName(option.name as ColorKeys),
                    option.color,
                    reportType as ReportType
                  )
                  const layerName = getColorName(option.name as ColorKeys)
                  const dataTestId = `layer-${activeTabName}-${camelCase(
                    layerName
                  )}-${viewportId}`
                  const isChecked = !isDisabled
                    ? enabledLayers[activeTabName]?.includes(option.name)
                    : false
                  return (
                    <div
                      key={option.name}
                      className={'layers-option-wrapper'}
                      data-testid={dataTestId}
                      style={{
                        cursor: !isDisabled ? 'pointer' : 'not-allowed',
                      }}
                      onClick={() =>
                        !isDisabled && handleChecboxClick(option.name)
                      }
                    >
                      <Checkbox
                        checked={isChecked}
                        disabled={isDisabled}
                        sx={{
                          padding: 0,
                          color: `#${option.color}`,
                          '&.Mui-checked': {
                            color: `#${option.color}`,
                          },
                          '&.Mui-disabled': {
                            color: theme.palette.primary.main,
                          },
                        }}
                      />
                      <div
                        className={'layers-option-name'}
                        style={{
                          color: isDisabled
                            ? theme.palette.primary.main
                            : 'white',
                        }}
                      >
                        {layerName}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>
    )
  }

  return (
    <PopupOverlay
      closeHandler={closeHandler}
      ref={ref}
      isDraggable={true}
      hasBoxShadow={false}
      closeButtonStyle={{ top: '20px' }}
      popupName={'layerPanel'}
      initWidth={737}
    >
      <div className='layers-container'>
        <div className={'layers-tabs-container'}>
          {data.map((panel, i) => {
            const panelColor =
              activeTab === i
                ? theme.palette.secondary.main
                : theme.palette.primary.main
            return (
              <Button
                key={panel.name}
                color='primary'
                variant='contained'
                disabled={isDisabledOption(
                  panel.name,
                  '#color',
                  reportType as ReportType
                )}
                onClick={() => setActiveTab(i)}
                sx={{
                  display: 'flex',
                  maxWidth: '360px',
                  minWidth: '90px',
                  fontSize: '0.75rem',
                  lineHeight: '1.25rem',
                  color: 'white',
                  padding: '2px 35px',
                  borderRadius: '4px 4px 0px 0px',
                  boxShadow: 'none',
                  backgroundColor: panelColor,
                  '&:hover': {
                    background: panelColor,
                  },
                  '&.MuiButton-root.Mui-disabled': {
                    cursor: 'not-allowed',
                    pointerEvents: 'all',
                    color: 'whitesmoke',
                    backgroundColor: theme.palette.grey[800],
                  },
                }}
              >
                {panel.name}
              </Button>
            )
          })}
        </div>
        {renderCategories()}
        <div className={'sync-container'}>
          <Divider variant='middle' />
          <FormControlLabel
            control={
              <Checkbox
                data-testid={`syncBox-${viewportId}`}
                checked={synced}
                sx={{
                  'MuiSvgIcon-root': {
                    color: 'white',
                  },
                }}
                onChange={(e) => {
                  dispatch(
                    syncLayers({
                      areSynced: e.target.checked,
                      id: viewportId,
                    })
                  )
                }}
              />
            }
            label='SYNC'
            sx={{
              marginLeft: '6px',
              width: 'fit-content',
              color: 'white',
            }}
          />
        </div>
      </div>
    </PopupOverlay>
  )
})

export default LayersPanel
