import {
  useCallback,
  MouseEvent,
  useRef,
  useContext,
  useState,
  useImperativeHandle,
} from 'react'
import './style.scss'
import { Box, BoxProps } from '@mui/material'
import { Slider } from '../../../ui_toolkit/Inputs/Slider/Slider'
import { secondsToTimeString } from '../../../tslib/secondsToTimeString'
import { calcSliderTime } from '../../VideoTimeline/utils'
import {
  MediaSyncContext,
  PlayControls as PlayControlsRef,
} from '../../../details/types'
import { useTimelineContext } from '../../../details'
import { HandleMouseDownChangeProps } from '../TransportControls'

interface IProps {
  min?: number
  max: number
  duration: number
  activeTrackRef: React.MutableRefObject<HTMLDivElement | null>
  onRender?: (node: HTMLInputElement) => void
  handleMouseDownRef: React.MutableRefObject<
    HandleMouseDownChangeProps | undefined
  >
  sliderRef: React.MutableRefObject<HTMLInputElement | null>
  playRef: React.MutableRefObject<PlayControlsRef | null>
}

export const ControlsSlider = ({
  max,
  min,
  onRender,
  duration,
  activeTrackRef,
  handleMouseDownRef,
  sliderRef,
  playRef,
}: IProps) => {
  const mediaSyncContext = useContext(MediaSyncContext)
  const { setShouldMoveMarker } = useTimelineContext()
  const [isMouseDown, setIsMouseDown] = useState(false)

  const wrapperRef = useRef<BoxProps | null>(null)
  const tooltipRef = useRef<HTMLDivElement | null>(null)
  const trackRef = useRef<HTMLDivElement | null>(null)
  const hoveredTrackRef = useRef<BoxProps | null>(null)

  useImperativeHandle(
    handleMouseDownRef,
    () => ({
      isMouseDown,
      setIsMouseDown,
    }),
    [isMouseDown]
  )

  const onMouseUp = (e: MouseEvent) => {
    if (!handleMouseDownRef.current) {
      return
    }
    mediaSyncContext.isSeeking = true

    const { position } = calcSliderTime(e, mediaSyncContext.totalDuration)

    handleMouseDownRef.current.setIsMouseDown(false)
    playRef.current?.setPlaying(false)
    mediaSyncContext.timingObj?.update({
      position,
    })
    setShouldMoveMarker(true)
  }

  const onMouseDown = () => {
    if (!handleMouseDownRef.current) {
      return
    }

    handleMouseDownRef.current.setIsMouseDown(true)
  }

  const onMouseMove = useCallback(
    (e: MouseEvent) => {
      if (!tooltipRef.current || !hoveredTrackRef.current?.style) {
        return
      }

      const { position, percent } = calcSliderTime(e, duration)

      if (percent < 0 || percent > 100) {
        return null
      }

      tooltipRef.current.innerHTML = secondsToTimeString(position)
      tooltipRef.current.style.left = `${percent}%`
      hoveredTrackRef.current.style.width = `${percent}%`
    },
    [duration]
  )

  const onMouseEnter = useCallback(() => {
    if (!tooltipRef.current || !hoveredTrackRef.current?.style) {
      return
    }

    tooltipRef.current.style.display = 'flex'
    hoveredTrackRef.current.style.display = 'flex'
  }, [])

  const onMouseLeave = useCallback(() => {
    if (!tooltipRef.current || !hoveredTrackRef.current?.style) {
      return
    }

    tooltipRef.current.style.display = 'none'
    hoveredTrackRef.current.style.display = 'none'
  }, [])

  return (
    <Box
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseDown={onMouseDown}
      ref={wrapperRef}
      data-testid='controlsSliderWrapper'
      id='slider-range-wrapper'
    >
      <Box ref={tooltipRef} id='slider-range-tooltip'></Box>
      <Box ref={trackRef} id='slider-range-track'></Box>
      <Box ref={hoveredTrackRef} id='slider-range-track-hovered'></Box>
      <Box ref={activeTrackRef} id='slider-range-active-track'></Box>
      <Slider sliderRef={sliderRef} min={min} max={max} onRender={onRender} />
    </Box>
  )
}
