import {
  MouseEvent,
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { match } from 'ts-pattern'
import { usePlyJsonQuery } from '../../api'
import { ISynchronizer } from '../../dataStructure/synchronizer/synchronizer'
import { useDriveTrialContext } from '../../details'
import { getCameraURL } from '../../details/config'
import {
  MediaSyncContext,
  ObjectColor,
  ViewportContent,
  ViewportMenuObject,
} from '../../details/types'
import { selectViewportLayout } from '../../store/details/viewport/selectors'
import { viewportContentChange } from '../../store/details/viewport/viewportSlice'
import { viewportChange } from '../../store/details/viewportData/viewportDataSlice'
import { GridOption } from '../../ui_toolkit/GridSelectors/GridSelectors'
import MediaWrapper from '../../ui_toolkit/MediaWrapper/MediaWrapper'
import { Viewport } from '../../ui_toolkit/Viewport/Viewport'
import { isTimelineDisabled, useDetectZoomLevel } from '../../utils'
import { ILayerPanelItem } from '../LayersPanel/types'
import MapView from '../Map/Map'
import { GpsPoint, MapOptionsRef } from '../Map/types'
import NoData from '../NoData/NoData'
import { View3D } from '../TopDown3D_V1_Copy/View3D'
import { TopViewMap } from '../TopViewMap/TopViewMap'
import { VideoTimeline } from '../VideoTimeline/VideoTimeline'
import { VideoWithCanvas } from '../VideoWithCanvas/VideoWithCanvas'
import { ViewportToolbar } from '../ViewportToolbarTools/ViewportToolbar'
import type { MapRef } from 'react-map-gl'

export interface MarkerSourceRef {
  setPosition: (point: GpsPoint) => void
  getPosition: () => GpsPoint | undefined
  setRotationAngle: (angle: number) => void
}

interface DetailViewportProps {
  id: number
  colors: ObjectColor[]
  layerPanelData: ILayerPanelItem[]
  menuOptions: ViewportMenuObject[]
  grid: GridOption
  synchronizer?: ISynchronizer
  fullscreenId: number | null
  setFullscreenId: React.Dispatch<React.SetStateAction<number | null>>
  captureScreenshot?: (jiraID: string) => void
}

export function DetailViewport({
  id,
  colors,
  layerPanelData,
  menuOptions,
  grid,
  synchronizer,
  captureScreenshot,
  fullscreenId,
  setFullscreenId,
}: DetailViewportProps) {
  const { driveTrials, modeKey } = useDriveTrialContext()
  const { activeVideo } = useContext(MediaSyncContext)
  const mapRef = useRef<MapRef>(null)
  const mapOptionsRef = useRef<MapOptionsRef>(null)
  const markerRef = useRef<MarkerSourceRef>(null)
  const [focusMap, setFocusMap] = useState(true)
  const [selectedDTID, setSelectedDTID] = useState<number>(activeVideo!)
  const viewportData = useSelector(selectViewportLayout(id))
  const dispatch = useDispatch()
  const { data: signsData } = usePlyJsonQuery()

  const isFullscreen = fullscreenId === id

  window.addEventListener('beforeunload', function () {
    localStorage.removeItem('jiraURL')
  })

  const getTitle = useCallback(
    () => menuOptions.find((option) => option.id === viewportData)?.name ?? '',
    [viewportData, menuOptions]
  )

  useDetectZoomLevel(0.75, 1.5)

  const changeViewport = (viewportId: number, content: ViewportContent) => {
    if (isTimelineDisabled() && content === ViewportContent.TIMELINE) return

    dispatch(
      viewportContentChange({
        id: viewportId,
        content,
      })
    )

    dispatch(
      viewportChange({
        id: viewportId,
        content,
      })
    )
  }

  const pickContent = (content: ViewportContent) => {
    changeViewport(id, content)
  }

  const goFullscreen = (e: MouseEvent<HTMLButtonElement>) => {
    setFullscreenId(id)
    e.currentTarget.blur()
  }

  const exitFullscreen = (e: MouseEvent<HTMLButtonElement>) => {
    setFullscreenId(null)
    e.currentTarget.blur()
  }

  useEffect(() => {
    !viewportData && synchronizer?.updateStatus(id, false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [viewportData])

  const renderVideo = useCallback(
    (videoSide: ViewportContent) => {
      let videoName = ''
      try {
        videoName = getCameraURL(videoSide)
      } catch (e) {
        if (e instanceof Error) {
          console.error(e.message)
        }
      }

      if (!videoName) {
        synchronizer?.updateStatus(id, true)
      }

      return !videoName || !driveTrials.length ? (
        <NoData languageCode='enUS' viewport={getTitle()} />
      ) : (
        <VideoWithCanvas
          key={modeKey}
          playerId={id.toString()}
          colors={colors}
          isFullscreen={isFullscreen}
          viewportId={id}
          title={getTitle()}
          synchronizer={synchronizer}
          videoName={videoName}
        />
      )
    },
    [
      colors,
      driveTrials.length,
      getTitle,
      id,
      isFullscreen,
      modeKey,
      synchronizer,
    ]
  )

  return (
    <Viewport
      isFullscreen={isFullscreen}
      isViewportStyled
      viewportContentTitle
      title={getTitle()}
    >
      {match(viewportData)
        .with(ViewportContent.FRONT_CAMERA, () =>
          renderVideo(ViewportContent.FRONT_CAMERA)
        )
        .with(ViewportContent.MAP, () => (
          <MapView
            mapOptionsRef={mapOptionsRef}
            isFullscreen={isFullscreen}
            focusMap={focusMap}
            mapRef={mapRef}
            markerRef={markerRef}
            synchronizer={synchronizer}
            viewportId={id}
            selectedDTID={selectedDTID}
          />
        ))
        .with(ViewportContent.TIMELINE, () => (
          <VideoTimeline
            synchronizer={synchronizer}
            viewportId={id}
            signsData={signsData}
            captureScreenshot={captureScreenshot}
          />
        ))
        .with(ViewportContent['3D_VIEW'], () => (
          <MediaWrapper>
            <View3D
              viewportId={id}
              isFullscreen={isFullscreen}
              synchronizer={synchronizer}
              grid={grid}
              signsData={signsData}
            />
          </MediaWrapper>
        ))
        .with(ViewportContent.LEFT_CAMERA, () =>
          renderVideo(ViewportContent.LEFT_CAMERA)
        )
        .with(ViewportContent.REAR_CAMERA, () =>
          renderVideo(ViewportContent.REAR_CAMERA)
        )
        .with(ViewportContent.RIGHT_CAMERA, () =>
          renderVideo(ViewportContent.RIGHT_CAMERA)
        )
        .with(ViewportContent.TOP_VIEW_MAP, () => (
          <TopViewMap viewportId={id} synchronizer={synchronizer} />
        ))
        .otherwise(() => (
          <NoData languageCode='enUS' />
        ))}

      <ViewportToolbar
        direction='column'
        focusMap={focusMap}
        mapOptionsRef={mapOptionsRef}
        setFocusMap={setFocusMap}
        setContent={pickContent}
        currentContent={viewportData}
        layerPanelData={layerPanelData}
        goFullscreen={goFullscreen}
        exitFullScreen={exitFullscreen}
        isFullscreen={isFullscreen}
        mapRef={mapRef}
        markerRef={markerRef}
        viewportId={id}
        colors={colors}
        menuOptions={menuOptions}
        selectedDTID={selectedDTID}
        setSelectedDTID={setSelectedDTID}
      />
    </Viewport>
  )
}
