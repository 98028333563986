import { PropsWithChildren, useEffect, useState } from 'react'
import axios from 'axios'
import { gapi } from 'gapi-script'
import { jwtDecode } from 'jwt-decode'
import { useAuthContext } from './AuthContext'
import { useRefreshTokenMutation } from '../../api'
import { DecodedToken, IDTokenResponse, gapiConfig } from '../login/loginGoogle'
import { useTokenExpiredEndSession } from '../logout'
import {
  getRefreshToken,
  getToken,
  setRefreshToken,
  setToken,
} from '../tokenStorage'

function AxiosInterceptor({ children }: PropsWithChildren) {
  const { email, source, setAuthContext, isAuthenticated } = useAuthContext()
  const [isMounted, setIsMounted] = useState(false)
  const { refreshToken } = useRefreshTokenMutation()
  const endSession = useTokenExpiredEndSession()

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      async (response) => {
        if (source === 'google') {
          const gapiLoad = () => {
            gapi.auth.authorize(gapiConfig(true), (t) => {
              try {
                const token = t as IDTokenResponse
                const { email: googleEmail }: DecodedToken = jwtDecode(
                  token.id_token
                )

                // it's essential to track the email from the browser
                // if it's different from email in context logout
                if (email !== googleEmail) {
                  throw new Error()
                }
              } catch (e) {
                endSession()
              }
            })
          }

          gapi.load('client:auth2', gapiLoad)
        }

        return response
      },
      async (error) => {
        const originalRequest = error.config

        // if the error status is 401 and there is no originalRequest._retry
        // flag, it means the token has expired and we need to refresh it
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true

          try {
            const t = getToken()
            const rt = getRefreshToken()

            const { data } = await refreshToken({
              accessToken: t,
              refreshToken: rt,
            })

            setToken(data.tokens.accessToken)
            setRefreshToken(data.tokens.refreshToken)

            // update auth context values
            setAuthContext({
              token: data.tokens.accessToken,
              source,
              email,
            })

            return axios(originalRequest)
          } catch (error) {
            endSession()
          }
        }

        return Promise.reject(error)
      }
    )

    setIsMounted(true)

    // we must remove interceptors because every execution of
    // `useEffect` adds a new interceptor to Axios instance
    return () => axios.interceptors.response.eject(interceptor)

    // interceptor should be added/removed only when login state changes
    // this will prevent mixing `source` state in our auth context as well
    // as checking email from the browser and email from context on google auth
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated])

  return <>{isMounted ? children : null}</>
}

export default AxiosInterceptor
