import {
  forwardRef,
  ReactNode,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import ClearIcon from '@mui/icons-material/Clear'
import { Fade, IconButton, Paper, Popper } from '@mui/material'
import Draggable from 'react-draggable'
import ResizableBox from '../../components/ResizableBox/ResizableBox'

export type OverlayPosition = 'left' | 'right'

interface iProps {
  closeHandler: () => void
  closeButtonStyle?: object
  isDraggable?: boolean
  hasBoxShadow?: boolean
  popupPosition?: string
  popupName?: string
  paperStyle?: object
  isResizable?: boolean
  children: ReactNode
  initWidth: number
  openRender?: React.Dispatch<React.SetStateAction<number>>
}

const PopupOverlay = forwardRef(function PopupOverlay(props: iProps, ref) {
  const {
    closeHandler,
    closeButtonStyle,
    isDraggable = false,
    hasBoxShadow = true,
    children,
    popupName,
    paperStyle,
    isResizable,
    initWidth,
    openRender,
  } = props
  const overlayRef = useRef<HTMLDivElement | null>(null)
  const [anchor, setAnchor] = useState<HTMLElement>()
  const [isOpen, setIsOpen] = useState(false)
  const anchorRect = anchor?.getBoundingClientRect()

  useImperativeHandle(ref, () => ({
    spawn: (target: HTMLElement) => {
      setAnchor(isOpen ? undefined : target)
      setIsOpen((prev) => !prev)

      if (openRender) {
        openRender((prev) => prev + 1)
      }
    },
    close: () => {
      setAnchor(undefined)
      setIsOpen(false)
    },
  }))

  const renderChildren = () => {
    if (!isDraggable) return children
    return <div className={isResizable ? '' : 'handle'}>{children}</div>
  }

  return (
    <Popper
      open={isOpen}
      anchorEl={undefined}
      transition
      placement={'right-start'}
      sx={{
        pointerEvents: 'none',
        position: 'absolute',
        zIndex: 1203,
      }}
      modifiers={[
        {
          name: 'preventOverflow',
          enabled: true,
          options: {
            rootBoundary: 'viewport',
          },
        },
      ]}
    >
      {({ TransitionProps }) => (
        <Draggable
          handle='.handle'
          bounds='body'
          nodeRef={overlayRef}
          defaultPosition={{
            x: anchorRect
              ? window.innerWidth - (anchorRect.x + initWidth) < 0
                ? anchorRect.x - initWidth
                : anchorRect.x + 40
              : 0,
            y: anchorRect ? anchorRect.y + 40 : 0,
          }}
        >
          <Fade
            {...TransitionProps}
            timeout={350}
            style={{
              backgroundColor: 'transparent',
              pointerEvents: 'all',
            }}
          >
            <Paper
              id={popupName}
              ref={overlayRef}
              style={{
                boxShadow: !hasBoxShadow ? 'none' : '',
                ...paperStyle,
              }}
            >
              <IconButton
                sx={{
                  position: 'absolute',
                  top: 0,
                  right: 2,
                  color: 'white',
                  zIndex: 4,
                  ...closeButtonStyle,
                }}
                onClick={closeHandler}
              >
                <ClearIcon fontSize='small' />
              </IconButton>
              {isResizable ? (
                <ResizableBox
                  initHeight={200}
                  initWidth={300}
                  minHeight={100}
                  minWidth={200}
                >
                  {renderChildren()}
                </ResizableBox>
              ) : (
                renderChildren()
              )}
            </Paper>
          </Fade>
        </Draggable>
      )}
    </Popper>
  )
})

export default PopupOverlay
