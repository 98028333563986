import DashboardTable from './DashboardTable'
import './style.scss'
/* import { useEffect, useState } from 'react'
import axios from 'axios'
import { urls } from '../../api' */
import { createToken } from './utils'

export default function Dashboard() {
  /*   const [token, setToken] = useState<string>()

  useEffect(() => {
    axios
      .get(urls.tableau)
      .then((resp) => setToken(resp.data))
      .catch((err) => console.error('Access to Tableau has failed: ', err))
  }, []) */

  const userid = process.env.REACT_APP_TABLEAU_CREATOR || ''
  const iss = process.env.REACT_APP_TABLEAU_ID || ''
  const kid = process.env.REACT_APP_TABLEAU_SECRET_ID || ''
  const secret = process.env.REACT_APP_TABLEAU_CREATOR_SECRET_VALUE || ''
  const wurl = process.env.REACT_APP_TABLEAU_URL || ''
  const token = createToken(userid, kid, secret, iss)

  return (
    <div className='dashboards'>
      <tableau-viz
        id='tableau-viz'
        //src={process.env.REACT_APP_TABLEAU_URL || ''}
        src={wurl}
        width='100%'
        height='calc(100vh - 52px)'
        hide-tabs
        toolbar='bottom'
        //token={token || ''}
        token={token || ''}
      />

      <div className='apex-chart-container'>
        <DashboardTable />
      </div>
    </div>
  )
}
