import { TrackParameters, trackHeightMap } from '../../details'
import { getDarkerShade } from '../../utils'

export interface Color {
  r: number
  g: number
  b: number
}

export interface GainColor {
  pct: number
  color: Color
}

export const getLightToDarkTeal = (
  percent: number
): [number, number, number] => {
  // Light teal (r: 222, g: 249, b: 196)
  // Dark teal (r: 70, g: 133, b: 133)
  const r = Math.floor(222 - percent * (222 - 70))
  const g = Math.floor(249 - percent * (249 - 133))
  const b = Math.floor(196 - percent * (196 - 133))

  return [r, g, b]
}

const createColorPalette = () => {
  const a: GainColor[] = []
  for (let i = 0; i <= 100; i = i + 2) {
    const per = i / 100
    const col = getLightToDarkTeal(per)
    a.push({ pct: per, color: { r: col[0], g: col[1], b: col[2] } })
  }

  return a
}

export const getColorForPercentage = (pct: number): Color | undefined => {
  if (pct < 0 || pct > 1) {
    return undefined
  }

  const percentColors = createColorPalette()

  const i = percentColors.findIndex((color) => color.pct && pct <= color.pct)
  const lower = percentColors[i - 1]
  const upper = percentColors[i]
  const range = upper.pct - lower.pct
  const rangePct = (pct - lower.pct) / range
  const pctLower = 1 - rangePct
  const pctUpper = rangePct

  return {
    r: Math.floor(lower.color.r * pctLower + upper.color.r * pctUpper),
    g: Math.floor(lower.color.g * pctLower + upper.color.g * pctUpper),
    b: Math.floor(lower.color.b * pctLower + upper.color.b * pctUpper),
  }
}

export const createHighlightGainStyle = (
  gain: number,
  trackParameters: TrackParameters | undefined,
  groupId: string | number,
  failureKPIItem?: boolean
): string => {
  const itemHeightDelta = 6
  const height = trackParameters?.[groupId as number]?.height || 'small'
  if (!gain) {
    return `height: ${
      height ? trackHeightMap[height] - itemHeightDelta : 18
    }px;`
  }
  const gainColors = getColorForPercentage(gain)

  if (!gainColors) {
    return `height: ${
      height ? trackHeightMap[height] - itemHeightDelta : 18
    }px;`
  }

  const values = [...Object.values(gainColors)]
  const backgroundRGBA = failureKPIItem
    ? 'rgb(211, 47, 47)'
    : `rgb(${values.join(',')})`

  const borderColor = getDarkerShade(backgroundRGBA, 30)

  return `height: ${
    height ? trackHeightMap[height] - itemHeightDelta : 18
  }px; background-color: ${backgroundRGBA}; border-color: ${borderColor};`
}
