import { forwardRef, useEffect, useState } from 'react'
import CheckIcon from '@mui/icons-material/Check'
import { ListItemIcon, ListItemText } from '@mui/material'
import { ViewportContent, ViewportMenuObject } from '../../../details/types'
import { DetailsPageState } from '../../../store/details/viewportStateUtils'
import { MenuItem } from '../../../ui_toolkit/PopupMenu/MenuItem/MenuItem'
import { PopupMenu } from '../../../ui_toolkit/PopupMenu/PopupMenu'
import { MenuProps, commonStyle } from '../types'

interface ViewportMenuProps extends MenuProps {
  setContent: (content: ViewportContent) => void
  menuOptions: ViewportMenuObject[]
  viewportId: number
  closeAllPopups: () => void
  isTimelineDisabled: boolean
}

const getViewport = (
  viewports: DetailsPageState,
  menuOptions: ViewportMenuObject[],
  viewportId: number
) => {
  let x = 0
  if (viewports) {
    menuOptions.forEach((o, index) => {
      if (o.id === viewports[viewportId]['content']) {
        x = index
      }
    })
  }
  return x
}

export const ViewportMenu = forwardRef(function ViewportMenu(
  {
    arrowPosition,
    closeHandler,
    setContent,
    menuOptions,
    viewportId,
    closeAllPopups,
    isTimelineDisabled,
  }: ViewportMenuProps,
  ref
) {
  const viewports = JSON.parse(localStorage.getItem('viewports') || '')
  const viewport = getViewport(viewports, menuOptions, viewportId)
  const [selectedViewport, setSelectedViewport] = useState<number>(viewport)

  const handleMenuItemClick = (index: number) => {
    setSelectedViewport(index)
  }

  useEffect(() => {
    setSelectedViewport(viewport)
  }, [viewport])

  return (
    <PopupMenu
      closeHandler={closeHandler}
      backgroundColor='#3f4c6b'
      arrowPosition={arrowPosition}
      ref={ref}
      isList={true}
    >
      {menuOptions.map((option, index) => {
        return (
          <MenuItem
            key={option.id}
            dataTestId={`viewportMenuItem-${option.name.replaceAll(
              ' ',
              ''
            )}-${viewportId}`}
            closeHandler={closeHandler}
            selected={index === selectedViewport}
            isDisabled={isTimelineDisabled && option.id === 6}
            onClick={() => {
              setContent(option.id)
              closeHandler()
              closeAllPopups()
              handleMenuItemClick(index)
            }}
          >
            {index === selectedViewport ? (
              <ListItemIcon>
                <CheckIcon sx={{ color: 'whitesmoke' }} fontSize='small' />
              </ListItemIcon>
            ) : (
              <ListItemIcon />
            )}
            <ListItemText sx={commonStyle}>{option.name}</ListItemText>
          </MenuItem>
        )
      })}
    </PopupMenu>
  )
})
