import { useMutation } from '@tanstack/react-query'
import axios, { AxiosError, AxiosResponse } from 'axios'
import { enqueueSnackbar } from 'notistack'
import { enUS } from '../../constants/messages'
import { urls } from '../urls'

interface CreateJiraRequest {
  projectKey: string
  summary: string
  description: string
  issueType: string
  team: string
}

export interface EditJiraRequest {
  issueKey: string
  summary: string
  team?: string
  description?: string
  issueType?: string
}

interface RemoveJiraRequest {
  issueKey: string
}

interface JiraResponse {
  data: {
    issue_key: string
    message: string
  }
  status: number
}

const isAxiosError = (error: any): error is AxiosError => {
  return error.isAxiosError === true
}

export const useCreateJiraMutation = () => {
  const {
    mutateAsync: createJiraMutation,
    data,
    isPending,
  } = useMutation({
    mutationKey: ['createJira'],
    mutationFn: (
      data: CreateJiraRequest
    ): Promise<AxiosResponse<JiraResponse | any>> =>
      axios.post(urls.jira, data),
  })

  return { createJiraMutation, data, isPending }
}

export const useRemoveJiraMutation = () => {
  const {
    mutateAsync: removeJiraMutation,
    data,
    isPending,
    error,
  } = useMutation({
    mutationKey: ['removeJira'],
    mutationFn: async (data: RemoveJiraRequest): Promise<AxiosResponse> => {
      try {
        const response = await axios.delete(urls.jira, { data })
        return response
      } catch (error) {
        if (isAxiosError(error)) {
          if (error.response && error.response.status === 500) {
            enqueueSnackbar(`${data.issueKey}: ${enUS.JIRA_NOT_FOUND}`)
            return Promise.resolve({
              status: 204,
              statusText: 'No Content',
              headers: {},
              config: {},
              data: null,
            } as AxiosResponse)
          } else {
            throw error
          }
        } else {
          return Promise.reject(error)
        }
      }
    },
  })

  return { removeJiraMutation, data, isPending, error }
}

export const useEditJiraMutation = () => {
  const {
    mutateAsync: editJiraMutation,
    data,
    isPending,
    error,
  } = useMutation({
    mutationKey: ['editJira'],
    mutationFn: async (data: EditJiraRequest): Promise<AxiosResponse> => {
      try {
        const response = await axios.patch(urls.jira, data)
        return response
      } catch (error: unknown) {
        if (isAxiosError(error)) {
          if (error.response && error.response.status === 500) {
            enqueueSnackbar(`${data.issueKey}: ${enUS.JIRA_NOT_FOUND}`)
            return Promise.resolve({
              status: 204,
              statusText: 'No Content',
              headers: {},
              config: {},
              data: null,
            } as AxiosResponse)
          } else {
            throw error
          }
        } else {
          return Promise.reject(error)
        }
      }
    },
  })

  return { editJiraMutation, data, isPending, error }
}
