import { RuleType } from 'react-querybuilder'

export const sortBetween = (x: RuleType<string, string, any, string>) => {
  const items = x.value.split(',').map((item: string) => item.trim())

  const num1 = parseFloat(items[0])
  const num2 = parseFloat(items[1])

  const areBothNumbers = !isNaN(num1) && !isNaN(num2)

  const sortedItems = areBothNumbers
    ? [num1, num2].sort((a, b) => a - b)
    : items.sort()

  return sortedItems.join(', ')
}
