import { ReactElement, useRef } from 'react'
import EmailIcon from '@mui/icons-material/Email'
import { Tooltip } from '@mui/material'
import { Link, useLocation } from 'react-router-dom'
import HeaderTitle from './HeaderTitle'
import { HilPicker } from './HilPicker'
import ProjectPicker from './ProjectPicker'
import { useHilVersion } from '../../api/table/hilVersion'
import { enUS, supportEmail } from '../../constants'
import { HilVersion } from '../../models/table'
import { getEmail } from '../../storage/emailStorage'
import { GridLogo } from '../../ui_toolkit/GridLogo/GridLogo'
import GridSelectors, {
  GridOption,
} from '../../ui_toolkit/GridSelectors/GridSelectors'
import { ProfileLogo } from '../../ui_toolkit/ProfileLogo/ProfileLogo'
import Logo from '../Logo/Logo'
import { AccountMenu } from '../Menus/AccountMenu/AccountMenu'
import { MenuRef } from '../Menus/types'
import ReportNavigation from '../ReportNavigation/ReportNavigation'
import './style.scss'

interface headerProps {
  children?: ReactElement
  fullscreen?: boolean
  gridChangeHandler?: (option: GridOption) => void
}

const Header = ({ gridChangeHandler, children, fullscreen }: headerProps) => {
  const { data } = useHilVersion()
  const programRef = useRef<HTMLSpanElement | null>(null)
  const location = useLocation()
  const isDetailsPage = location.pathname.includes('details')
  const spawner = useRef<MenuRef>()
  const viewportSpawner = useRef<MenuRef>()
  const closeMenuHandler = () => spawner.current?.spawn(undefined)
  const closeViewportMenuHandler = () =>
    viewportSpawner.current?.spawn(undefined)

  return (
    <div className='header-container'>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          gap: '16px',
          zIndex: 1,
        }}
      >
        <Link
          data-testid='returnToLandingPage'
          to={'/reporting/dashboards/Dashboard'}
          className='header-logo'
        >
          <Logo />
        </Link>

        {!isDetailsPage && (
          <>
            <ProjectPicker ref={programRef} />
            <ReportNavigation />
          </>
        )}
      </div>
      <HeaderTitle>{children}</HeaderTitle>
      <div className='right-section'>
        {isDetailsPage && gridChangeHandler && (
          <>
            <GridSelectors
              spawner={viewportSpawner}
              closeHandler={closeViewportMenuHandler}
              pickGrid={gridChangeHandler}
            />
            <GridLogo
              onClick={(e) => viewportSpawner.current?.spawn(e.currentTarget)}
              isDisabled={Boolean(fullscreen)}
            />
          </>
        )}
        {!isDetailsPage && (
          <span ref={programRef} style={{ marginRight: '20px' }} />
        )}
        {!isDetailsPage && <HilPicker data={data as HilVersion} />}
        <Tooltip title={enUS.SUPPORT_TOOLTIP} leaveDelay={200} arrow>
          <a data-testid='sendEmailToSupport' href={`mailto:${supportEmail}`}>
            <EmailIcon
              fontSize='small'
              className='account-icon'
              sx={{
                color: 'white',
              }}
            />
          </a>
        </Tooltip>
        <ProfileLogo
          email={getEmail()}
          onClick={(e) => spawner.current?.spawn(e.currentTarget)}
        />
        <AccountMenu
          closeHandler={closeMenuHandler}
          arrowPosition='right'
          ref={spawner}
        />
      </div>
    </div>
  )
}

export default Header
