import { useEffect, useState } from 'react'
import { FormHelperText, Stack } from '@mui/material'
import { EnvironmentSelectors } from './EnvironmentSelectors/EnvironmentSelectors'
import { GeneralSelectors } from './GeneralSelectors/GeneralSelectors'
import { ObjectSelectors } from './ObjectSelectors/ObjectSelectors'
import {
  INIT_FILTER_STATE,
  INIT_QUERY_BUILDER_STATE,
} from '../../pages/Report/initialStates'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { FiltersState, ReportType } from '../../pages/Report/types'
import { CustomAccordion } from '../../ui_toolkit/Accordion/Accordion'
import {
  StyledApplyBtn,
  StyledBtnContainer,
  StyledResetBtn,
} from '../StyledComponents/buttons'
import { createFilterPayload } from '../Toolbox/utils'

export function SideFilters(props: { reportType: ReportType }) {
  const { reportType } = props
  const { preserveState, setPreserveState, setPage, setSelectedRowIds } =
    useReportContext()
  const [expanded, setExpanded] = useState('')
  const [filterState, setFilterState] = useState<FiltersState>(
    preserveState[reportType].filterState!
  )

  useEffect(() => {
    setFilterState(preserveState[reportType].filterState!)
    //If filters are open, we need to refresh the data inside the state when the reportType changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportType])

  const handleExpand = (panel: string) => {
    setExpanded(panel)
  }

  const isApplyDisabled = () => {
    const isEveryEnv = Object.values(filterState.environmentFilters).every(
      (x) => !x
    )
    const { camera: cam, ...rest } = filterState.objectFilters
    const isSomeObj = Object.values(rest).some(
      (x) => x !== undefined && x !== ''
    )
    const isEveryObj = Object.values(rest).every((x) => !x)
    const isEveryGen = Object.values(filterState.generalFilters).every(
      (x) => x[0] === null && x[1] === null
    )

    if (isEveryEnv && isEveryObj && isEveryGen) {
      return true
    } else if (isSomeObj && !cam) {
      return true
    } else {
      return false
    }
  }

  const isHelperText =
    Object.values(filterState.objectFilters).some((x) => !x) &&
    !filterState.objectFilters.camera

  const doFilter = () => {
    setPage(1)
    const filterPayload = createFilterPayload(filterState, reportType, 1)

    setPreserveState({
      ...preserveState,
      [reportType]: {
        ...preserveState[reportType],
        filterState: filterState,
        filterToSql: filterPayload.filters!,
        searchType: 'filter',
        queryBuilderState: INIT_QUERY_BUILDER_STATE,
      },
    })
    setSelectedRowIds(new Set())
  }

  const resetFilter = () => {
    setPage(1)
    setPreserveState({
      ...preserveState,
      [reportType]: {
        ...preserveState[reportType],
        filterState: INIT_FILTER_STATE,
        filterToSql: {},
        searchType: 'filter',
      },
    })
    setFilterState(INIT_FILTER_STATE)
    setSelectedRowIds(new Set())
  }

  return (
    <Stack spacing={4} direction='column'>
      <div>
        <CustomAccordion
          title='Environment'
          expanded={expanded}
          onChange={handleExpand}
        >
          <EnvironmentSelectors
            filters={filterState}
            onChange={setFilterState}
          />
        </CustomAccordion>
        <CustomAccordion
          title='Objects'
          expanded={expanded}
          onChange={handleExpand}
        >
          <ObjectSelectors filters={filterState} onChange={setFilterState} />
        </CustomAccordion>
        <CustomAccordion
          title='General'
          expanded={expanded}
          onChange={handleExpand}
        >
          <GeneralSelectors filters={filterState} onChange={setFilterState} />
        </CustomAccordion>
      </div>
      <StyledBtnContainer spacing={1} direction='column'>
        {isHelperText && (
          <FormHelperText sx={{ color: '#ff9800' }}>
            * Please select camera
          </FormHelperText>
        )}
        <StyledApplyBtn
          onClick={doFilter}
          variant='contained'
          fullWidth
          disabled={isApplyDisabled()}
        >
          Apply
        </StyledApplyBtn>
        <StyledResetBtn onClick={resetFilter} variant='contained' fullWidth>
          Reset
        </StyledResetBtn>
      </StyledBtnContainer>
    </Stack>
  )
}
