import CheckIcon from '@mui/icons-material/Check'
import CloseIcon from '@mui/icons-material/Close'
import RemoveIcon from '@mui/icons-material/Remove'
import { CellContext } from '@tanstack/react-table'
import { useParams } from 'react-router-dom'
import { StyledDiv } from './StyledDiv'
import { TableRouteParams } from '../../../constants'
import { toDateTimeString, toTimeString } from '../../../utils'
import { ReportType } from '../types'

interface CellFormatterProps {
  cell: CellContext<any, any>
  column: string
}

interface CellProps {
  textAlign: 'left' | 'center' | 'right'
  content: React.ReactNode
  tooltip?: string
}

const handleObjectCases = (
  value: any,
  reportType: ReportType,
  cellProps: CellProps
): void => {
  if (['lanes', 'signs', 'lights', 'objects'].includes(reportType)) {
    handleKpiReport(value, cellProps)
  } else {
    handleDriveTrialsReport(value, cellProps)
  }
}

const handleKpiReport = (value: any, cellProps: CellProps): void => {
  if (typeof value.tableText === 'number') {
    cellProps.textAlign = 'right'
    cellProps.content = value.tableText
    cellProps.tooltip = value?.hoverText
  } else {
    cellProps.textAlign = 'center'
    cellProps.content = value.tableText
    cellProps.tooltip = value?.hoverText
  }
}

const handleDriveTrialsReport = (value: any, cellProps: CellProps): void => {
  if (value.avgFlag) {
    if (value.metricFloatAvg || value.metricFloatAvg === 0) {
      const type = typeof value.metricFloatAvg
      cellProps.textAlign = 'right'
      cellProps.content =
        type === 'number'
          ? formatNumber(value.metricFloatAvg)
          : value.metricFloatAvg
    } else {
      cellProps.textAlign = 'center'
      cellProps.content = '-'
    }
    return
  }

  if (value.compoundLabel) {
    cellProps.textAlign = 'left'
    cellProps.content = value.compoundLabel
  } else {
    cellProps.textAlign = 'center'
    cellProps.content = '-'
  }
}

const renderSignalIcon = (value: any): React.ReactNode => {
  const { metricFloatAvg } = value
  const icons = {
    1: <CheckIcon sx={{ fontSize: '1.120rem' }} />,
    0: <RemoveIcon sx={{ fontSize: '1.120rem', color: '#6779a5' }} />,
  }
  return (
    icons[metricFloatAvg as 0 | 1] || (
      <CloseIcon sx={{ fontSize: '1.120rem', color: '#a4aec9' }} />
    )
  )
}

export const formatNumber = (num: number): React.ReactNode => (
  <>
    {num.toString().includes('.') ? num.toFixed(2) : num}
    {!num.toString().includes('.') && <span>.00</span>}
  </>
)

export function CellFormatter({ cell, column }: CellFormatterProps) {
  const { reportType } = useParams<TableRouteParams>()
  const value = cell.getValue()
  const cellProps: CellProps = {
    textAlign: 'left',
    content: null,
  }

  try {
    switch (column) {
      case 'DTID':
        cellProps.textAlign = 'left'
        cellProps.content = value
        break

      case 'ParentDTID':
        cellProps.textAlign = 'left'
        cellProps.content = value
        break

      case 'Start Date-Time':
      case 'End Date-Time':
      case 'Date Processed':
        cellProps.textAlign = 'right'
        cellProps.content = toDateTimeString(value)
        break

      case 'Vehicle':
        cellProps.textAlign = 'center'
        cellProps.content = value
        break

      case 'Drive Time':
        cellProps.textAlign = 'right'
        cellProps.content = toTimeString(value)
        break

      case 'TRUE':
      case 'FALSE':
      case 'GT=10m':
      case 'GT=50m':
      case 'TP=10m':
      case 'TP=50m':
      case 'FP=10m':
      case 'FN=10m':
      case 'FP=50m':
      case 'FN=50m':
        cellProps.textAlign = 'right'
        if (!Object.keys(value).length) {
          cellProps.content = value
        } else {
          cellProps.content = value.tableText
          cellProps.tooltip = value.hoverText
        }
        break

      default:
        if (cell.column.parent?.columnDef.header === 'Signal') {
          cellProps.textAlign = 'center'
          cellProps.content = renderSignalIcon(value)
        } else if (value === null) {
          cellProps.textAlign = 'center'
          cellProps.content = '-'
        } else if (typeof value === 'object') {
          handleObjectCases(value, reportType!, cellProps)
        } else if (typeof value === 'number') {
          cellProps.textAlign = 'right'
          cellProps.content =
            reportType === 'lanes' ? (value * 100).toFixed(1) + ' %' : value
        } else if (value === '-') {
          cellProps.textAlign = 'center'
          cellProps.content = value
        } else if (typeof value === 'string') {
          cellProps.textAlign = 'left'
          cellProps.content = value
        }
    }
  } catch (err) {
    console.info('Missing cell data:', err)
  }

  if (cellProps.content === null) {
    cellProps.content = '-'
  }

  return (
    <StyledDiv textAlign={cellProps.textAlign} tooltip={cellProps.tooltip}>
      {cellProps.content}
    </StyledDiv>
  )
}
