import { Box, IconButton, Typography } from '@mui/material'
import { StyledTooltip } from '../../ui_toolkit/StyledTooltip/StyledTooltip'
import OpenInNewIcon from '@mui/icons-material/OpenInNew'
import CloseIcon from '@mui/icons-material/Close'
import { SprItem } from '../../api/sprGallery/spr-gallery'
import { NLGalleryItem } from '../../api/nlGallery/nl-gallery'
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'
import { useCallback, useEffect, useMemo } from 'react'
import { DEFAULT_SELECTED_COLUMNS } from '../../constants/gallery'
import axios from 'axios'
import { enqueueSnackbar } from 'notistack'
import { urls } from '../../api'
import { enUS } from '../../constants'
import { openNewTab } from '../../security'
import { useSearchParams } from 'react-router-dom'

interface ImageBoxProps {
  onClose: () => void
  data: SprItem[] | NLGalleryItem[]
}

export default function ImageBox({ onClose, data }: ImageBoxProps) {
  const [searchParams, setSearchParams] = useSearchParams()
  const selectedIndex = Number(searchParams.get('selectedIndex'))
  const selectedImage = useMemo(() => {
    if (selectedIndex === null || !data?.length) {
      return null
    }

    return data[selectedIndex]
  }, [data, selectedIndex])

  const handleSendToDetails = async () => {
    if (!selectedImage) {
      return
    }

    const redirectionData = {
      rows: [
        {
          DTID: selectedImage.dtid,
          parentDTID: selectedImage.dtid,
        },
      ],
      columns: DEFAULT_SELECTED_COLUMNS,
    }

    const guid: string = (
      await axios.post(urls.redirectionData, redirectionData)
    )?.data

    if (!guid) {
      enqueueSnackbar({
        message: enUS.REDIRECTION_ERROR,
        variant: 'error',
      })
    }

    openNewTab('characterization', `${guid}&frameId=${selectedImage.frameId}`)
  }

  const handlePrevImage = useCallback(() => {
    setSearchParams((sp) => {
      if (selectedIndex !== null && selectedIndex > 0) {
        sp.set('selectedIndex', String(selectedIndex - 1))
      }

      return sp
    })
  }, [selectedIndex, setSearchParams])

  const handleNextImage = useCallback(() => {
    setSearchParams((sp) => {
      if (selectedIndex !== null && selectedIndex < data.length - 1) {
        sp.set('selectedIndex', String(selectedIndex + 1))
      }

      return sp
    })
  }, [data.length, selectedIndex, setSearchParams])

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (!selectedImage) {
        return
      }

      switch (event.key) {
        case 'ArrowLeft':
          handlePrevImage()
          break
        case 'ArrowRight':
          handleNextImage()
          break
        default:
          break
      }
    }

    window.addEventListener('keydown', handleKeyDown)

    return () => {
      window.removeEventListener('keydown', handleKeyDown)
    }
  }, [handleNextImage, handlePrevImage, selectedImage])

  return (
    <Box sx={{ width: '100vw', height: '100vh' }}>
      <Box
        onClick={onClose}
        sx={{
          background: 'transparent',
          position: 'absolute',
          width: '100%',
          height: '100%',
          left: 0,
          bottom: 0,
          right: 0,
          top: 0,
        }}
      ></Box>

      <IconButton
        size='large'
        disabled={selectedIndex === 0}
        onClick={handlePrevImage}
        sx={{
          zIndex: 100,
          position: 'absolute',
          top: '50%',
          left: '10%',
          color: 'white',
        }}
      >
        <ArrowBackIosNew />
      </IconButton>

      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '55%',
          height: 'auto',
          textAlign: 'center',
        }}
      >
        <Box
          sx={{
            bgcolor: 'background.paper',
            boxShadow: 24,
          }}
        >
          <img
            className={'image-modal'}
            src={selectedImage?.url}
            alt={selectedImage?.dtid.toString()}
          />
        </Box>

        {selectedImage?.description ? (
          <Typography
            sx={{
              color: 'white',
              opacity: '0.9',
              fontSize: '14px',
              mt: 2,
            }}
          >
            {selectedImage.description}
          </Typography>
        ) : null}

        <Typography
          sx={{
            mt: 1,
            fontSize: '12px',
            color: 'white',
            opacity: 0.6,
          }}
        >
          Use keyboard arrows (← | →) to go through images
        </Typography>
      </Box>

      <IconButton
        size='large'
        disabled={selectedIndex === data.length - 1}
        onClick={handleNextImage}
        sx={{
          zIndex: 100,
          position: 'absolute',
          top: '50%',
          right: '10%',
          color: 'white',
        }}
      >
        <ArrowForwardIos />
      </IconButton>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          position: 'fixed',
          top: 0,
          width: '100%',
          height: '48px',
          backgroundColor: '#000000B3',
          px: 2,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: 'white',
            gap: 3,
          }}
        >
          <Box alignItems={'center'} display={'flex'}>
            <Typography fontSize={'0.8rem'} mr={1}>
              Drive trial id:{' '}
            </Typography>
            <Typography fontWeight={600}>{selectedImage?.dtid}</Typography>
          </Box>

          <Box alignItems={'center'} display={'flex'}>
            <Typography fontSize={'0.8rem'} mr={1}>
              Frame:{' '}
            </Typography>
            <Typography fontWeight={600}>{selectedImage?.frameId}</Typography>
          </Box>
        </Box>

        <Box>
          <StyledTooltip title='Send to details' placement='bottom'>
            <IconButton
              size='small'
              onClick={handleSendToDetails}
              sx={{ color: 'white', mr: 1 }}
            >
              <OpenInNewIcon />
            </IconButton>
          </StyledTooltip>

          <StyledTooltip title='Close' placement='bottom'>
            <IconButton size='small' onClick={onClose} sx={{ color: 'white' }}>
              <CloseIcon />
            </IconButton>
          </StyledTooltip>
        </Box>
      </Box>
    </Box>
  )
}
