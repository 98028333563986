import { Option } from 'react-querybuilder'

const dateTimeType = {
  inputType: 'datetime-local',
}

const booleanType = {
  valueEditorType: 'select',
  values: [
    { label: 'No data', name: '0' },
    { label: 'All data available', name: '1' },
  ] as Option[],
}

export const completenessTypeMap = new Map<string, object>([
  ['Start Date-Time', dateTimeType],
  ['End Date-Time', dateTimeType],
  ['Date Processed', dateTimeType],
  //magna
  ['TAPI', booleanType],
  ['name', booleanType],
  ['Vehicle BUS 1', booleanType],
  ['Vehicle BUS 2', booleanType],
  ['GPS/IMU', booleanType],
  ['Lidar', booleanType],
  ['Front Camera', booleanType],
  ['Ethernet', booleanType],
  ['Co-driver notes', booleanType],
  //valeo
  ['GPS', booleanType],
  ['FV', booleanType],
  ['RV', booleanType],
  ['MVL', booleanType],
  ['MVR', booleanType],
  ['LR_1', booleanType],
  ['LR_2', booleanType],
  ['LR_3', booleanType],
  ['LR_4', booleanType],
])

export const generalTypeMap = new Map<string, object>([
  ['Start Date-Time', dateTimeType],
  ['End Date-Time', dateTimeType],
  ['Date Processed', dateTimeType],
])

export const nameMap = new Map<string, string>([
  ['DriveTime', '(EndDate - StartDate)'],
])

const namesIncludingUnderscore = ['LR_1', 'LR_2', 'LR_3', 'LR_4']

export const getDuplicatePrefixes = (items: string[]) => {
  const allPrefixes = items.map((item) => item.split('|')[0].split('_')[0])
  const prefixMap = allPrefixes.reduce(
    (result: { [key: string]: number }, value) => ({
      ...result,
      [value]: result[value] + 1,
    }),
    {}
  )

  return Object.keys(prefixMap).filter((a) => prefixMap[a] > 1)
}

export const getOptionName = (val: string, duplicatePrefixes: string[]) => {
  const hasUnderscore = val.includes('_')

  if (!hasUnderscore || namesIncludingUnderscore.includes(val)) return val

  const isNonUnique = duplicatePrefixes.some((x) =>
    val.toLocaleLowerCase().includes(x.toLocaleLowerCase())
  )

  return isNonUnique
    ? val.replace('_', ' - ')
    : val.split('_').slice(1).join('_')
}

export const getOptionValue = (val: string) => {
  const mapValue = nameMap.get(val)
  return mapValue ? mapValue : val
}
