import { useQuery } from '@tanstack/react-query'
import axios from 'axios'
import { urls } from './urls'
import { ViewportMenuObject } from '../details/types'
import { getProjectID } from '../storage/projectIdStorage'

export const useClientViewportMenuQuery = () => {
  const projectID = getProjectID()
  return useQuery({
    queryKey: ['clientViewportMenu', projectID],
    staleTime: Infinity,
    enabled: !!projectID,
    refetchOnWindowFocus: false,
    queryFn: (): Promise<ViewportMenuObject[]> =>
      axios
        .get<ViewportMenuObject[]>(urls.clientViewportMenuOptions, {
          params: { projectID },
        })
        .then(({ data }) => data),
  })
}
