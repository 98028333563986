import CheckBoxIcon from '@mui/icons-material/CheckBox'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import ChevronRightTwoToneIcon from '@mui/icons-material/ChevronRightTwoTone'
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone'
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox'
import CheckboxTree, { OnCheckNode, OnExpandNode } from 'react-checkbox-tree'
import { useReportContext } from '../../pages/Report/providers/ReportContextProvider'
import { ReportType } from '../../pages/Report/types'
import { getProjectID } from '../../storage/projectIdStorage'
import { PROJECT_IDS } from '../../details/config'
import './style.scss'

export interface CheckboxNodeData extends OnCheckNode, OnExpandNode {}

interface CheckboxTreeBaseProps {
  expandedNodes: string[]
  checkboxes: CheckboxNodeData[] | undefined
  onCheck: (node: OnCheckNode) => void
  onExpand: (expandedNodes: string[]) => void
  reportType: ReportType
  checked?: string[]
  search?: boolean
}

export function CheckboxTreeBase({
  expandedNodes,
  checkboxes,
  onCheck,
  onExpand,
  reportType,
  checked,
  search,
}: CheckboxTreeBaseProps) {
  const { preserveState } = useReportContext()

  getProjectID() === PROJECT_IDS.VOLVO.toString() &&
    delete [...checkboxes!][0].children![1]

  const treeIcons = {
    check: (
      <CheckBoxIcon
        sx={{ color: '#e5e5e5' }}
        className='rct-icon rct-icon-check'
      />
    ),
    uncheck: (
      <CheckBoxOutlineBlankIcon
        sx={{ color: '#e5e5e5' }}
        className='rct-icon rct-icon-uncheck'
      />
    ),
    halfCheck: (
      <IndeterminateCheckBoxIcon
        sx={{ color: '#e5e5e5' }}
        className='rct-icon rct-icon-half-check'
      />
    ),
    expandClose: (
      <ChevronRightTwoToneIcon
        sx={{ color: '#e5e5e5', opacity: 'unset' }}
        className='rct-icon rct-icon-expand-close'
      />
    ),
    expandOpen: (
      <ExpandMoreTwoToneIcon
        sx={{ color: '#e5e5e5' }}
        className='rct-icon rct-icon-expand-open'
      />
    ),
    parentClose: (
      <ExpandMoreTwoToneIcon
        style={{ display: 'none' }}
        className='rct-icon rct-icon-parent-close'
      />
    ),
    parentOpen: (
      <ExpandMoreTwoToneIcon
        style={{ display: 'none' }}
        className='rct-icon rct-icon-parent-open'
      />
    ),
    leaf: (
      <ExpandMoreTwoToneIcon
        style={{ display: 'none' }}
        className='rct-icon rct-icon-leaf-close'
      />
    ),
  }

  const getCheckedNodeLabels = (data: CheckboxNodeData[]) => {
    const nodeLabels: string[] = []

    data?.forEach((x) => {
      if (
        preserveState[reportType].columnVisibility[x.value] === true ||
        preserveState[reportType].columnVisibility[x.value] === undefined
      ) {
        nodeLabels.push(x.value)
      }

      if (x.children && x.children.length > 0) {
        nodeLabels.push(
          ...getCheckedNodeLabels(x.children as CheckboxNodeData[])
        )
      }
    })

    return nodeLabels
  }

  return checkboxes ? (
    <CheckboxTree
      nodes={checkboxes}
      checked={search ? checked : getCheckedNodeLabels(checkboxes)}
      expanded={expandedNodes}
      onCheck={(_, node) => onCheck(node)}
      onExpand={(expanded) => onExpand(expanded)}
      icons={treeIcons}
    />
  ) : null
}
